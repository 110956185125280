import { Modal, Typography, Button, Box, CircularProgress, Backdrop } from "@mui/material";
import React, { useEffect } from "react";

type StandardModalProps = {
    openState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    loading?: number;
    onClose: () => void;
    children: React.ReactNode;
    sx?: any;
}

export default function StandardModal({
    openState,
    loading,
    onClose,
    children,
    sx
}: StandardModalProps) {

    return (        
        <Modal
            open={openState[0]}
            onClose={() => onClose()}
        >            
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '100%',
                bgcolor: 'background.paper',
                border: '2px solid #eee',
                boxShadow: 24,
                maxWidth: '540px',
                ...sx
            }}>
                <Backdrop
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={(loading ?? 0) > 0}
                >
                    <CircularProgress color="primary" />
                </Backdrop>
                {children}
            </Box>
        </Modal >
    )

}