import React, { useState } from 'react';
import { ArtistAvatar } from '../ArtistAvatar';
import * as constants from '../../utils/constants'
import { Link } from 'react-router-dom';
import MoreMenu, { MoreMenuOption } from '../MoreMenu';
import { Box, SxProps, Theme, Link as MuiLink, Typography, Button, Chip } from '@mui/material';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import { ProfileModel } from '../../types/ProfileModel';
import helpers from '../../utils/helpers';
import VerifiedBadge from '../VerifiedBadge';

type ArtistProfileCardProps = {
    artist: ProfileModel;
    moreMenu?: MoreMenuOption[];
    sx?: SxProps<Theme>;
    children?: React.ReactNode;
}

const ArtistProfileCard: React.FC<ArtistProfileCardProps> = ({
    artist,
    moreMenu,
    sx,
    children
}: ArtistProfileCardProps) => {
    return (
        <Box sx={{
            display: 'flex',
            width: '100%',
            overflow: 'hidden',
            borderRadius: '10px',
            position: 'relative',
            border: 'var(--border)',
            ...sx
        }}
        >
            <Link to={`/artist/${artist.accountId}`}>
                <Box sx={{
                    position: 'absolute',
                    width: '100%',
                    height: '100px',
                    zIndex: 100,
                    color: 'white',
                    top: 0,
                    backgroundColor: 'secondary.main',
                    backgroundImage: `url(${constants.BASE_IMAGE_URL}/${artist.banner?.standard?.path})`,
                    backgroundSize: 'cover',
                    backgroundObjectFit: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    overflow: 'hidden',
                }}>
                </Box>
            </Link>
            <Box sx={{
                zIndex: 200,
                mt: 7,
                mx: 2,
                width: '100%',
            }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: 2,
                }}>
                    <Link to={`/artist/${artist.accountId}`}>
                        <ArtistAvatar
                            user={artist} sx={{
                                width: '100px',
                                height: '100px',
                                boxShadow: "0 0 0 2px #fff"
                            }} />
                    </Link>
                    <Box sx={{ pt: 6 }}>
                        <Typography sx={{ color: 'primary' }}>
                            <VerifiedBadge user={artist}
                                accountIdSx={{
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    color: 'secondary.light',
                                }}
                            />
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                    <Link to={`/chats/${artist.id}`}>
                        <Chip label={
                            <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center', }}>
                                <MessageOutlinedIcon sx={{ color: 'secondary.light', fontSize: '18px', mt: 0.5 }} />
                                <Typography variant="body2">Mensagem</Typography>
                            </Box>
                        } />
                    </Link>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', my: 2 }}>
                    {children}
                </Box>
            </Box>
            {!!moreMenu?.length && <MoreMenu sx={{
                position: 'absolute',
                right: 4,
                top: 8,
                zIndex: 400,
                color: 'white',
            }}
                options={moreMenu}></MoreMenu>}
        </Box>
    );
};

export default ArtistProfileCard;
