import React from "react";
import { Link } from "@mui/material";
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import { Link as RouterLink } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';

type SideMenuProps = {
}

const SideMenu = ({ }: SideMenuProps) => {

    return (
        <List>
            <ListItem disablePadding>
                <ListItemButton>
                    <ListItemIcon>
                        <DashboardIcon />
                    </ListItemIcon>
                    <Link component={RouterLink} to="/main">Dashboard</Link>
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton>
                    <ListItemIcon>
                        <PersonSearchIcon />
                    </ListItemIcon>
                    <Link component={RouterLink} to="/main/verification">Users Verification</Link>
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton>
                    <ListItemIcon>
                        <ImageSearchIcon />
                    </ListItemIcon>
                    <Link component={RouterLink} to="/main/moderation">Content Moderation</Link>
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton>
                    <ListItemIcon>
                        <ContentPasteSearchIcon />
                    </ListItemIcon>
                    <Link component={RouterLink} to="/main/reporting">Reporting Center</Link>
                </ListItemButton>
            </ListItem>
        </List>
    );
}

export default SideMenu;