
import React, { useEffect } from "react";
import { Breadcrumb } from "../../components/Breadcrumb";
import Header from "../../components/Header";
import { Avatar, AvatarGroup, Box, List, ListItemAvatar, ListItemButton, ListItemText, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
	collectionListUsers,
	collectionBookmarks,
} from '../../services/collectionsService';
import * as constants from '../../utils/constants'
import PrivateTemplate from "../../components/PrivateTemplate";

import Bookmarks from "./bookmarks";
import ListPosts from "./listPosts";
import ListUsers from "./listUsers";

const Collections: React.FC = () => {
	const { collection, list, listType, filter } = useParams()
	const navigate = useNavigate()
	const upMd = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

	let defaultUserListsRoute = upMd ?
		'/collections/user-lists/following/users/all' :
		'/collections/user-lists'

	let defaultBookmarksRoute = upMd ?
		'/collections/bookmarks/all' :
		'/collections/bookmarks'

	const userLists = ['following', 'followers', 'blocked']
	const translatedUserLists = {
		following: 'Seguindo',
		followers: 'Seguidores',
		blocked: 'Bloqueados'
	} as { [key: string]: string };

	const userListsType = ['users', 'posts']
	const userStatus = ['all']
	const postsTypes = ['all', 'photo', 'video']

	useEffect(() => {

		if (collection === 'user-lists') {
			if (userLists.indexOf(list || '') === -1) {
				navigate(defaultUserListsRoute)
			}
			if (userListsType.indexOf(listType || '') === -1) {
				navigate(defaultUserListsRoute)
			}
			if (listType === 'users' && userStatus.indexOf(filter || '') === -1) {
				navigate(defaultUserListsRoute)
			}
			if (listType === 'posts' && postsTypes.indexOf(filter || '') === -1) {
				navigate(defaultUserListsRoute)
			}

		} else if (collection === 'bookmarks') {
			if (postsTypes.indexOf(filter || '') === -1) {
				navigate(defaultBookmarksRoute)
			}
		} else {
			navigate(defaultUserListsRoute)
		}

	}, [collection, list, listType, filter, upMd])


	const { data: lists } = useQuery({
		queryKey: ['userLists'],
		queryFn: () => collectionListUsers(),
		enabled: !!(collection === 'user-lists'),
		refetchOnWindowFocus: false,
	})

	const { data: bookmarks } = useQuery({
		queryKey: ['bookmarksList'],
		queryFn: () => collectionBookmarks(),
		enabled: !!(collection === 'bookmarks'),
		refetchOnWindowFocus: false,
	})

	useEffect(() => {
		window.scrollTo(0, 0);
	  }, []);

	return (
		<PrivateTemplate>
			<Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
				<Box sx={{
					display: !upMd && filter ? 'none' : 'flex',
					flexDirection: 'column',
					flex: '1 1 360px',
					borderRight: 'var(--border)'
				}}>
					<Header sx={{ flexDirection: 'column', borderBottom: 'none' }}>
						<Breadcrumb title="COLEÇÕES" url="/" />
						<Box sx={{
							display: 'flex',
							flex: 1,
							flexDirection: 'column',
						}}>
							<Tabs
								value={collection || 'user-lists'}
								indicatorColor="primary"
								textColor="inherit"
								variant="fullWidth"
								sx={{ borderBottom: 'var(--border)' }}>
								<Tab label={
									<Typography component="h1" variant="h6">LISTAS DE USUÁRIOS</Typography>
								} value='user-lists' onClick={() => navigate(defaultUserListsRoute)} />
								<Tab label={
									<Typography component="h1" variant="h6">FAVORITOS</Typography>
								} value='bookmarks' onClick={() => navigate(defaultBookmarksRoute)} />
							</Tabs>
							{collection === 'user-lists' && <Box>
								<List sx={{ width: '100%' }}>
									{userLists?.map((item: string) =>
										<ListItemButton key={item}
											onClick={() => navigate(`/collections/user-lists/${item}/users/all`)}
											alignItems="flex-start" sx={{ borderBottom: 'var(--border)' }}>
											<ListItemText
												primary={translatedUserLists[item]}
												secondary={lists?.[`${item}TotalCount`]}
											/>
											<ListItemAvatar>
												<AvatarGroup total={lists?.[`${item}TotalCount`]}>
													{lists?.[item].map((listItem: any) => <Avatar 
														key={listItem.id}
														alt={listItem.displayName} 
														src={`${constants.BASE_IMAGE_URL}/${listItem.avatar?.small?.path}`} />)}
												</AvatarGroup>
											</ListItemAvatar>
										</ListItemButton>
									)}
								</List>
							</Box>}
							{collection === 'bookmarks' && <Box>
								<List sx={{ width: '100%' }}>
									<ListItemButton onClick={() => navigate('/collections/bookmarks/all')}
										alignItems="flex-start" sx={{ borderBottom: 'var(--border)' }}>
										<ListItemText
											primary="Todos os favoritos"
											secondary={bookmarks?.bookmarksTotalCount}
										/>
										<ListItemAvatar>
											<AvatarGroup total={bookmarks?.bookmarksTotalCount}>
												{bookmarks?.posts.map((post: any) => <Avatar 
													key={post.createdAt}
													alt={post.content} src={
													post.assets.at(0)?.type === 'image' ?
														post.assets.at(0)?.small?.path :
														post.assets.at(0)?.poster?.small?.path} />)}
											</AvatarGroup>
										</ListItemAvatar>
									</ListItemButton>
								</List>
							</Box>}
						</Box>
					</Header>
				</Box>
				<Box sx={{
					display: filter ? 'flex' : 'none',
					flexDirection: 'column',
					flex: '1 1 640px',
					borderRight: 'var(--border)'
				}}>
					<Header sx={{ flexDirection: 'column', 
						backgroundColor: 'background.paper',
						zIndex: 255,
					 }}>
						<Breadcrumb title={collection === 'user-lists' ? translatedUserLists[list as string]?.toUpperCase() : 'TODOS OS FAVORITOS'} url={!upMd ? `/collections/${collection}` : ''} />
						{collection === 'user-lists' && listType && <Box>
							<Tabs
								value={listType}
								indicatorColor="primary"
								textColor="inherit"
								variant="fullWidth"
								sx={{ borderBottom: 'var(--border)' }}>
								<Tab label={
									<Typography component="h1" variant="h6">USUÁRIOS</Typography>
								} value="users" onClick={() => navigate(`/collections/user-lists/${list}/users/all`)} />
								{list === "following" && <Tab label={
									<Typography component="h1" variant="h6">PUBLICAÇÕES</Typography>
								} value="posts" onClick={() => navigate(`/collections/user-lists/${list}/posts/all`)} />}
							</Tabs>
						</Box>}
					</Header>
					{listType === 'users' && list && <ListUsers list={list} />}
					{listType === 'posts' && list && <ListPosts list={list} />}
					{collection === 'bookmarks' && <Bookmarks />}
				</Box>
			</Box>
		</PrivateTemplate>
	);
}

export default Collections;