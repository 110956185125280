import React, { useState } from "react";
import StandardModal from "../StandardModal";
import { Button, Typography, Box, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import helpers from "../../utils/helpers";

type ReportingModalProps = {
	openState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
	onConfirm: (result: any) => void;
}

export default function ReportingModal({
	openState,
	onConfirm,
}: ReportingModalProps) {

	const handleClose = () => {
		openState[1](false);
	}

	const handleReporting = () => {
		openState[1](false);
		onConfirm(reason);
	}

	const [reason, setReason] = useState('');

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setReason(event.target.value);
	}

	return (
		<StandardModal openState={openState} onClose={handleClose}>
			<Box sx={{ p: 2}}>
				<Typography variant="h5">Denunciar publicação</Typography>
				<RadioGroup
					aria-labelledby="demo-controlled-radio-buttons-group"
					name="controlled-radio-buttons-group"
					onChange={handleChange}
				>
					<FormControlLabel value="other" control={<Radio />} label="Eu não gosto dessa publicação" />
					<FormControlLabel value="hate_speech" control={<Radio />} label='Esta publicação é ofensiva ou viola os termos do site' />
					<FormControlLabel value="stolen_content" control={<Radio />} label='Esta publicação contém conteúdo roubado (DMCA)' />
					<FormControlLabel value="underage" control={<Radio />} label='Material de abuso sexual infantil (CSAM)' />
					<FormControlLabel value="spam" control={<Radio />} label='Este post é spam' />
				</RadioGroup>
			</Box>
			<Box sx={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				gap: 2,
				p: 2
			}}>
				<Button
					variant="outlined"
					onClick={handleClose}
					sx={{ minWidth: '33%' }}>
					Cancelar
				</Button>
				<Button variant="contained"
					disabled={!reason}
					onClick={handleReporting}
					sx={{ minWidth: '33%' }}>
					Denunciar
				</Button>
			</Box>
		</StandardModal>
	)
}