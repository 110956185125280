import { Breadcrumb } from "../../components/Breadcrumb";
import Header from "../../components/Header";
import PrivateTemplate from "../../components/PrivateTemplate";
import React, { useEffect } from "react";
import { Box, TableRow, Checkbox, TableCell, Avatar, Typography, Button, CardContent, CardActions, Card, Link } from '@mui/material';
import { setupSubscriptionProduct, getSubscriptionProduct, getSubscribers, getEarnings } from "../../services/settingsService";
import { toast } from 'react-toastify';
import EnhancedTable, { DataRow, HeadCell } from "../../components/EnhancedTable";
import { useQuery } from '@tanstack/react-query'
import * as constants from '../../utils/constants'
import Slider from '@mui/material/Slider';
import { SubscriptionModel } from "../../types/SubscriptionModel";
import { Link as RouterLink } from 'react-router-dom';
import dayjs from "dayjs";
import helpers from "../../utils/helpers";
import { useTranslation } from 'react-i18next';

interface Data extends SubscriptionModel, DataRow { }

const headCells: readonly HeadCell<Data>[] = [
    {
        id: 'avatar',
        numeric: false,
        disablePadding: false,
        label: '',
    },
    {
        id: 'accountId',
        numeric: false,
        disablePadding: false,
        label: 'Account',
    },
    {
        id: 'displayName',
        numeric: false,
        disablePadding: false,
        label: 'Name',
    },
    {
        id: 'createdAt',
        numeric: false,
        disablePadding: false,
        label: 'Started at',
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
    },
    {
        id: 'price',
        numeric: true,
        disablePadding: false,
        label: 'Price',
    },
];

function getTableRow(row: Data,
    isItemSelected: boolean,
    labelId: string,
    handleClick: (event: React.MouseEvent<unknown>, id: string) => void) {
    return (<TableRow
        hover
        onClick={(event) => handleClick(event, row.id)}
        // aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
        // selected={isItemSelected}
        sx={{ 
            cursor: 'pointer',
            display: {
                xs: 'flex',
                sm: 'table-row',
            },
            flexDirection: {
                xs: 'column',
                sm: 'row',
            },
            
         }}
    >
        <TableCell>
            <Avatar
                alt={row.displayName}
                src={`${constants.BASE_IMAGE_URL}/${row.avatar?.small?.path}`}
                sx={{ width: 40, height: 40 }}
            />
        </TableCell>
        <TableCell><HeadCell>Account</HeadCell>{row.accountId}</TableCell>
        <TableCell><HeadCell>Name</HeadCell>{row.displayName}</TableCell>
        <TableCell><HeadCell>Started at</HeadCell>{helpers.timeStampToFormatedDate(row.createdAt)}</TableCell>
        <TableCell><HeadCell>Status</HeadCell>{row.status.replace('_', ' ')}</TableCell>
        <TableCell align="right" ><HeadCell>Price</HeadCell>{helpers.toLocalMoney(row.price)}</TableCell>
    </TableRow>
    )
}

const Earnings: React.FC = () => {

    const { t } = useTranslation();

    const marks = [
        {
            value: 0.00,
        },
        {
            value: 19.90,
        },
        {
            value: 29.90,
        },
        {
            value: 39.90,
        },
        {
            value: 49.90,
        },
        {
            value: 59.90,
        },
        {
            value: 69.90,
        },
        {
            value: 79.90,
        },
        {
            value: 89.90,
        },
        {
            value: 99.90,
        },
        {
            value: 109.90,
        },
        {
            value: 119.90,
        },
        {
            value: 129.90,
        },
        {
            value: 139.90,
        },
        {
            value: 149.90,
        },
        {
            value: 159.90,
        },
        {
            value: 169.90,
        },
        {
            value: 179.90,
        },
        {
            value: 189.90,
        },
        {
            value: 199.90,
        },
    ];

    const [previousPrice, setPreviousPrice] = React.useState<number>(0);
    const [currentPrice, setCurrentPrice] = React.useState<number>(0);

    const { data: rows } = useQuery({
        queryKey: ['subscribers'],
        queryFn: async () => {
            const subscribers = await getSubscribers();
            return subscribers as Data[];
        },
    });

    const { data: earnings } = useQuery({
        queryKey: ['earnings'],
        queryFn: () => getEarnings(dayjs().subtract(90, 'day').format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")),
    });

    const { data: subscriptionProduct, refetch: refetchSubscriptionProduct } = useQuery({
        queryKey: ['subscriptionProduct'],
        queryFn: getSubscriptionProduct,
    });

    useEffect(() => {
        setCurrentPrice(subscriptionProduct?.price ?? 0);
        setPreviousPrice(subscriptionProduct?.price ?? 0);
    }, [subscriptionProduct]);

    const handleSetupProduct = () => {
        setupSubscriptionProduct({
            subscriptionPrice: currentPrice
        }).then((res) => {
            toast.success('Assinatura atualizada com sucesso!');
            refetchSubscriptionProduct();
        }).catch((e: any) => null);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return (
        <PrivateTemplate>
            <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, borderRight: 'var(--border)' }}>
                <Header sx={{ maxWidth: '100%' }}>
                    <Breadcrumb title={t('myEarnings.title')} url='/' />
                </Header>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Box sx={{ px: 4, py: 4 }}>

                        <Typography variant="h5" sx={{ textAlign: 'center' }}>Preço da assinatura</Typography>
                        <Slider
                            component="div"
                            value={currentPrice}
                            onChange={(event, value) => setCurrentPrice(value as number)}
                            valueLabelDisplay="auto"
                            step={null}
                            marks={marks}
                            min={0.00}
                            max={199.90}
                        />
                        <Typography variant="h5" sx={{ textAlign: 'center' }}>{helpers.toLocalMoney(currentPrice)}</Typography>
                        <Typography variant="body2" sx={{ mt: 2 }}>Obs.: Após alterado o novo preço é valido apenas para novas assinaturas. As assinaturas já existentes permanecerão com o preço anterior.</Typography>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            marginTop: 2

                        }}>
                            <Button 
                                size="small"
                                variant="contained"
                                disabled={previousPrice === currentPrice}
                                onClick={handleSetupProduct}>Alterar Preço</Button>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            gap: 2,
                            mt: 4
                        }}>
                            <Card variant="outlined" sx={{ flexGrow: 2 }}>
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Valor total
                                    </Typography>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        flexDirection: {
                                            xs: 'column',
                                            sm: 'column',
                                            md: 'row',
                                        }
                                    }}>
                                        <Typography variant="h5" component="div">
                                            Pendente
                                        </Typography>
                                        <Typography variant="h5">
                                            {helpers.toLocalMoney(earnings?.pending)}
                                        </Typography>
                                    </Box>
                                    <Typography variant="body2" sx={{ mt: 2, maxWidth: '300px' }}>
                                        Valor pendente referente aos últimos 90 dias
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button component={RouterLink} to="/settings/earnings/statement" size="small">Ver extrato</Button>
                                </CardActions>
                            </Card>
                            <Card variant="outlined" sx={{ flexGrow: 2 }}>
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Valor total
                                    </Typography>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        flexDirection: {
                                            xs: 'column',
                                            sm: 'column',
                                            md: 'row',

                                        }
                                    }}>
                                        <Typography variant="h5" component="div">
                                            Disponível
                                        </Typography>
                                        <Typography variant="h5">
                                            {helpers.toLocalMoney(earnings?.available)}
                                        </Typography>
                                    </Box>
                                    <Typography variant="body2" sx={{ mt: 2, maxWidth: '300px' }}>
                                        Valor disponível referente aos últimos 90 dias
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button component={RouterLink} to="/settings/earnings/statement" size="small">Ver extrato</Button>
                                </CardActions>
                            </Card>
                        </Box>
                    </Box>
                    {rows && 
                        <EnhancedTable
                            headCells={headCells}
                            getTableRow={getTableRow}
                            rows={rows}
                            title="Assinantes"
                            dense
                        />}
                </Box>
                <Box sx={{height: '100px' // botton navigator spacing           
                }} ></Box>
            </Box>
        </PrivateTemplate>
    );
}

export default Earnings;
