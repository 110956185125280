import { Box, Chip, List } from '@mui/material';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { listBookmarkPosts } from '../../services/collectionsService';
import Post from '../../components/Post';
import EmptyFeed from "../../components/EmptyFeed";
import React, { useEffect } from 'react';
import { PostModel } from '../../types/PostModel';
import { PaginatedList } from '../../types/PaginatedList';
import InfiniteScrollObserver from '../../components/InfiniteScrollObserver';

export default function Bookmarks() {

    const navigate = useNavigate();
    const queryClient = useQueryClient();

    useEffect(() => {
        return () => {
            queryClient.setQueryData(['bookmarks'], (data: any) => ({
            pages: data?.pages?.slice(0, 1) ?? [],
            pageParams: data?.pageParams.slice(0, 1) ?? [],
            }))
        }
    }, [])    

    const posts = useInfiniteQuery<PaginatedList<PostModel>, Error>({
		queryKey: ['bookmarks'],
        queryFn: (query) => listBookmarkPosts(query.pageParam),
		getNextPageParam: (lastPage) => lastPage?.lastEvalKey,
		refetchOnWindowFocus: false,
		defaultPageParam: 0,
	})

    const getChipStyle = (type: string): any => {
		return {
            color: 'secondary',
            disabled: true
        }
	}
    
    return (
        <Box>
            <Box sx={{ display: 'flex', flex: 'row', p: 2, gap: 2 }}>
                <Chip label={`Todos`} onClick={() => navigate(`/collections/bookmarks/all`)} {...getChipStyle('all')} />
                {/* <Chip label={`Fotos`} onClick={() => navigate(`/collections/bookmarks/photo`)} {...getChipStyle('photo')} />
                    <Chip label={`Videos`} onClick={() => navigate(`/collections/bookmarks/video`)} {...getChipStyle('video')} /> 
                */}
            </Box>
            {!!posts?.data?.pages?.some(p => p.items?.length) ? <List sx={{
                width: '100%',
            }}>
                {posts?.data?.pages?.map(page => page.items?.map((post: PostModel) => (
                    <Post key={post.createdAt} post={post} queryKey={['bookmarks']} />
                )))}
            </List> : <EmptyFeed />}
            {posts && <InfiniteScrollObserver fetchNextPage={posts.fetchNextPage} />}
        </Box>
    );
}