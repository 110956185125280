import { Box, SxProps, Theme } from "@mui/material";
import React from "react";

type HeaderProps = {
    sx?: SxProps<Theme> | undefined
    children: React.ReactNode;    
}
// style={{width: (document.querySelector('#main')?.clientWidth || 638) + 'px'}}
const Header = ({ sx, children }: HeaderProps) => {

    return (
        <Box sx={{ display: 'flex',
            position: 'sticky',
            top: 0,
            flexDirection: 'row',
            zIndex: 110,
            backgroundColor: 'background.paper',
            borderBottom: 'var(--border)',
            margin: '0 auto',
            width: '100%',
            justifyContent: 'center',
            ...sx}}>
            {children}
        </Box>
    )

}

export default Header;