import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    text: {
      primary: '#333333',
      secondary: '#999',
    },
    primary: {
      main: '#ff2faa',
      light: 'rgba(252, 215, 237, 0.5)',
    },
    secondary: {
      light: '#757575',
      main: 'rgba(252, 215, 237, 0.5)',
    },
  },

  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'rgba(252, 215, 237, 0.5)',
            color: '#ff2faa'
          },
          '&.Mui-disabled': {
            opacity: 1
          }
        },
      },
    },    
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          overflow: '-moz-scrollbars-vertical',
          overflowY: 'scroll',
          scrollbarWidth: "thin"
        }
      }
    },
    MuiTypography: {
      styleOverrides: {        
        body2: {
          display: 'flex',
          alignItems: 'center',
          '& svg':{
            marginRight: '0.5rem'
          }
        }
      }
    },    
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
            padding: '8px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {     
          transform: 'translate(8px, 8px) scale(1)',
            '&.MuiInputLabel-shrink': {
              transform: 'translate(14px, -9px) scale(0.75)'
            },
            '&.MuiFormLabel-filled': {
              transform: 'translate(14px, -9px) scale(0.75)'
            },
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-root': {          
            padding: 0
          }
        },
      },
    },
  },
  typography: {
    h1: {
      fontSize: '18px',
      fontWeight: 'bold',
    },
    h4: {
      fontSize: '26px',
      // fontWeight: 'bold',
    },
    h5: {
      fontSize: '16px',
      fontWeight: 'bold',
    },
    h6: {
      fontSize: '14px',
      fontWeight: 'bold',
    },
  },

});

export default theme;