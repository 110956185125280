import { UserModel } from "../types/UserModel";
import { ProfileModel } from "../types/ProfileModel";
import { api, unhandledApi } from "./api";
import { API_URL } from '../utils/constants';
import axios from 'axios';
import { MessageModel } from "../types/MessageModel";

export type SignupRequest = {
    email: string;
    fullName: string;
    password: string;
}

export type SigninRequest = {
    email: string;
    password: string;
}

export type RecoveryPasswordRequest = {
    email: string;
}

export type RecoveryChangePasswordRequest = {
    email: string;
    code: string;
    password: string;
}

export const signup = (request: SignupRequest): Promise<any> => {
  return api.post(`user/signup`, request).then((res) => res.data)
}

export const signin = (request: SigninRequest): Promise<any> => {
  return api.post(`user/signin`, request).then((res) => res.data)
}

export const recoveryPassword = (request: RecoveryPasswordRequest): Promise<any> => {
  return api.post(`user/recovery-password`, request).then((res) => res.data)
}

export const recoveryChangePassword = (request: RecoveryChangePasswordRequest): Promise<any> => {
  return api.post(`user/recovery-change-password`, request).then((res) => res.data)
}

export const checkAccountIdExists = (accountId?: string): Promise<any> => {
  return unhandledApi.post(`user/account-id-exists`, {accountId}).then((res) => res.data)
}

export const changeDarkMode = (darkMode: boolean): Promise<UserModel> => {
  return api.post(`user/dark-mode`, { darkMode }).then((res) => res.data)
}

type GetVerifiedResquest = { 
  idFront?: string;
  idBack?: string;
  holdingId?: string;
}

export const getVerified = (data: GetVerifiedResquest) => {
  return api.post(`user/verify`, data).then((res) => res.data)
}

export const verifyEmail = (token: string) => {  
  return axios.post(`${API_URL}/user/verify-email`, {},
    { headers: { Authorization: `Bearer ${token}` } }
  ).then((res) => res.data)
}

export const verifyVerifyEmail = (token: string, code: string) => {
  return axios.post(`${API_URL}/user/verify-verify-email`, { code },
    { headers: { Authorization: `Bearer ${token}` } }
  ).then((res) => res.data)
}

export const getSuggestions = () => {
  return unhandledApi.get(`user/suggestions`).then((res) => res.data)
}

export const getUserStats = (): Promise<ProfileModel> => {
  return unhandledApi.get(`user/stats`).then((res) => res.data)
}

export const deleteProfile = (): Promise<void> => {
  return api.delete(`user/profile`).then((res) => res.data)
}

export const updateProfile = (request: ProfileModel): Promise<UserModel> => {
  return api.post(`user/profile`, request).then((res) => res.data)
}

export const syncUserState = (setCurrentUser: (user: UserModel) => void) => {
  unhandledApi.get(`user/current`).then((res) => {
    setCurrentUser(res.data);
  }).catch(() => {
    localStorage.removeItem('current_user');
    setCurrentUser({ token: undefined, id: ""});
  })
}

export const currentApiEnv = () => {
  return axios.get(`${API_URL}/env`).then((res) => res.data)
}

export const getNotifications = (): Promise<Array<MessageModel>> => {
  return api.get<Array<MessageModel>>(`user/notifications`).then((res) => res.data);
  }

export const markNotificationAsRead = (notificationId: string): Promise<void> => {
  return unhandledApi.post(`user/notifications/${notificationId}/read`).then((res) => res.data)
}

export default {
    signup,
    signin,
    checkAccountIdExists,
    changeDarkMode,
    recoveryPassword,
    recoveryChangePassword,
    getVerified,
    verifyEmail,
    verifyVerifyEmail,
    getSuggestions,
}