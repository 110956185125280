import { Modal, Typography, Button, Box } from "@mui/material";
import React, { useEffect } from "react";
import { MuiOtpInput } from 'mui-one-time-password-input'
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import { useTranslation } from 'react-i18next';

type OtpModalProps = {
    title?: string;
    openState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    onConfirm: (code: string) => void;
}

const otpLength = 6;

export default function OtpModal({
    openState,
    onConfirm,
    title,
}: OtpModalProps) {

    const { t } = useTranslation();
    const [code, setCode] = React.useState('');

    useEffect(() => {
        setCode('')
    }, [openState[0]]);

    return (
        <Modal
            open={openState[0]}
            onClose={() => openState[1](false)}
        >
            <Box maxWidth="sm" sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '100%',
                bgcolor: 'background.paper',
                border: '2px solid #eee',
                boxShadow: 24,
                p: 4,
            }}
            >
                <Typography id="modal-modal-title" variant="h5" component="h2" sx={{
                    textAlign: 'center'
                }}>
                    {title || t('otpModal.title')}
                </Typography>
                <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        width: '100%'
                    }}>
                        <MailOutlinedIcon sx={{ fontSize: '100px', color: 'primary.main', opacity: 0.5 }} />
                        <Typography variant="body1">
                            {t('otpModal.description')}
                        </Typography>
                </Box>
                <Box sx={{ my: 2 }}>
                    <MuiOtpInput
                        className="MuiOtpCustom"
                        TextFieldsProps={(index) => ({ 
                            size: 'small',
                         })}
                        length={otpLength}
                        autoFocus
                        value={code}
                        onChange={(value) => setCode(value)} />
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    gap: 2,
                    pt: 2
                }}>
                    <Button
                        variant="outlined"
                        onClick={() => openState[1](false)}
                        sx={{ minWidth: '33%' }}>
                        {t('common.cancel')}
                    </Button>
                    <Button variant="contained"
                        disabled={code.length !== otpLength}
                        onClick={() => onConfirm(code)}
                        sx={{ minWidth: '33%' }}>
                        {t('common.confirm')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    )

}