import React, { useRef, useEffect } from 'react';

type InfiniteScrollObserverProps = {
    fetchNextPage: any
}

export default function InfiniteScrollObserver({ fetchNextPage }: InfiniteScrollObserverProps) {

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                fetchNextPage();
            }
        });        
    },
        { threshold: 0.1 }
    );

    const observerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {

        if (observerRef.current) {
            observer.observe(observerRef.current);
        }

        return () => {
            if (observerRef.current) {
                observer.disconnect();
            }
        };
    }, [fetchNextPage]);

    return <div ref={observerRef} style={{
        height: '77.7px',
        width: '100%',
    }}/>;
}

