import React, { createContext, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import light from './themes/light';
import dark from './themes/dark';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import { UserModel } from "./types/UserModel";
import ApiInterceptor from './middlewares/ApiInterceptor';
import Routes from './routes';
import 'photoswipe/style.css';
import WebSockets from './websockets';
require('@silvermine/videojs-quality-selector/dist/css/quality-selector.css');
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ptBr from 'dayjs/locale/pt-br';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.locale(ptBr);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
import './i18n/config';

const queryClient = new QueryClient()
const user = JSON.parse(localStorage.getItem('current_user') || '{}') as UserModel
export type UserState = {
  currentUser: UserModel,
  setCurrentUser: (user: UserModel) => void
}
export const UserContext = createContext<UserState>({
  currentUser: user,
  setCurrentUser: (prev: UserModel) => { }
});

const App: React.FC = () => {

  const [currentUser, setCurrentUser] = useState<UserModel>(user);

  useEffect(() => {
    localStorage.setItem('current_user', JSON.stringify(currentUser))
  }, [currentUser])

  return (
    <UserContext.Provider value={{ currentUser, setCurrentUser }}>
      <ThemeProvider theme={currentUser.darkMode ? dark : light}>
        <ToastContainer position="top-right" theme={currentUser.darkMode ? 'dark' : 'light'} />
        <ApiInterceptor />
        <QueryClientProvider client={queryClient}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-Br">
            <CssBaseline />
            <BrowserRouter>
              <Routes />
              {currentUser.token && <WebSockets />}
            </BrowserRouter>
          </LocalizationProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </UserContext.Provider>
  );
};

export default App;