import React, {useEffect, useState} from "react";
import { Breadcrumb } from "../../components/Breadcrumb";
import Header from "../../components/Header";
import PrivateTemplate from "../../components/PrivateTemplate";
import { Box, TableRow, Checkbox, TableCell, Avatar, Typography, Button, IconButton } from '@mui/material';
import { getSubscriptions } from "../../services/settingsService";
import { toast } from 'react-toastify';
import EnhancedTable, { DataRow, HeadCell } from "../../components/EnhancedTable";
import { useQuery } from '@tanstack/react-query'
import * as constants from '../../utils/constants'
import { SubscriptionModel } from "../../types/SubscriptionModel";
import helpers from "../../utils/helpers";
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import { ProfileModel } from "../../types/ProfileModel";
import SubscriptionInfoModal from "../../components/SubscriptionInfoModal";
import { useTranslation } from 'react-i18next';

interface Data extends SubscriptionModel, DataRow { }

const Subscriptions: React.FC = () => {

    const { t } = useTranslation();
    const [refetchSubscriptions, setRefetchSubscriptions] = useState(0);

    const { data: rows } = useQuery({
        queryKey: ['subscriptions', refetchSubscriptions],
        queryFn: async () => {
            const subscriptions = await getSubscriptions();
            return subscriptions as Data[];
        },
    });

    const [editOpen, setEditOpen] = useState(false);
    const [currentArtist, setCurrentArtist] = useState<ProfileModel | undefined>();

    function handleEdit(row: Data) {
        setCurrentArtist({
            id: row.artistId,
            displayName: row.displayName,
            avatar: row.avatar,
            banner: row.banner,
            accountId: row.accountId,
            subscription: row
        
        });
        setEditOpen(true);
    }

    const headCells: readonly HeadCell<Data>[] = [
        {
            id: 'avatar',
            numeric: false,
            disablePadding: true,
            label: '',
        },
        {
            id: 'accountId',
            numeric: false,
            disablePadding: true,
            label: 'Account',
        },
        {
            id: 'displayName',
            numeric: false,
            disablePadding: false,
            label: 'Name',
        },
        {
            id: 'createdAt',
            numeric: false,
            disablePadding: false,
            label: 'Started at',
        },
        {
            id: 'activeUntil',
            numeric: false,
            disablePadding: false,
            label: 'Next charge',
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: 'Status',
        },
        {
            id: 'price',
            numeric: true,
            disablePadding: false,
            label: 'Price',
        },
        {
            id: 'id',
            numeric: true,
            disablePadding: false,
            label: '',
        },
    ];

    function getTableRow(row: Data,
        isItemSelected: boolean,
        labelId: string,
        handleClick: (event: React.MouseEvent<unknown>, id: string) => void) {
        return (<TableRow
            hover
            onClick={(event) => handleClick(event, row.id)}
            // aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            // selected={isItemSelected}
            sx={{ 
                cursor: 'pointer',
                display: {
                    xs: 'flex',
                    sm: 'table-row',
                },
                flexDirection: {
                    xs: 'column',
                    sm: 'row',
                },
             }}
        >
            <TableCell>
                <Avatar
                    alt={row.displayName}
                    src={`${constants.BASE_IMAGE_URL}/${row.avatar?.small?.path}`}
                    sx={{ width: 40, height: 40 }}
                />
            </TableCell>
            <TableCell><HeadCell>Account</HeadCell>{row.accountId}</TableCell>
            <TableCell><HeadCell>Name</HeadCell>{row.displayName}</TableCell>
            <TableCell><HeadCell>Started at</HeadCell>{helpers.timeStampToFormatedDate(row.createdAt)}</TableCell>
            <TableCell><HeadCell>{row.status === 'active' ?  'Next charge' : 'Active until'}</HeadCell>{helpers.timeStampToFormatedDate(row.activeUntil)}</TableCell>
            <TableCell><HeadCell>Status</HeadCell>{row.status.replace('_', ' ')}</TableCell>
            <TableCell align="right" ><HeadCell>Price</HeadCell>{helpers.toLocalMoney(row.price)}</TableCell>
            <TableCell align="right" >
                <IconButton
                    aria-label="delete"
                    onClick={() => handleEdit(row)}
                >
                    <BorderColorRoundedIcon />
                </IconButton>
            </TableCell>
        </TableRow>
        )
    }

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return (
        <PrivateTemplate>
            { currentArtist && <SubscriptionInfoModal
                openState={[editOpen, setEditOpen]}
                artist={currentArtist}
                onConfirmCancel={() => setRefetchSubscriptions(refetchSubscriptions+1)}
                onConfirmSubscribe={() => setRefetchSubscriptions(refetchSubscriptions+1)}
            />}
            <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, borderRight: 'var(--border)' }}>
                <Header sx={{ maxWidth: '100%' }}>
                    <Breadcrumb title={t('mySubscriptions.title')} url='/' />
                </Header>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    {rows && <EnhancedTable
                        headCells={headCells}
                        getTableRow={getTableRow}
                        rows={rows}
                        title="Assinaturas"
                        dense
                    />}
                </Box>
                <Box sx={{height: '100px' // botton navigator spacing           
                }} ></Box>
            </Box>
        </PrivateTemplate>
    );
}

export default Subscriptions;