import React from 'react';
import { ArtistAvatar } from '../ArtistAvatar';
import * as constants from '../../utils/constants'
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import MoreMenu, { MoreMenuOption } from '../MoreMenu';
import { Box, SxProps, Theme, Link as MuiLink, Typography } from '@mui/material';
import VerifiedBadge from '../VerifiedBadge';
import { ProfileModel } from '../../types/ProfileModel';
import { fontGrid } from '@mui/material/styles/cssUtils';


export const ArtistContentWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    z-index: 105;
    align-content: center;
`;


type ArtistProfileRefProps = {
    artist: ProfileModel;
    moreMenu?: MoreMenuOption[];
    sx?: any;
}

const ArtistProfileRef: React.FC<ArtistProfileRefProps> = ({
    artist,
    moreMenu,
    sx
}: ArtistProfileRefProps) => {

    const avatarSize = `${parseInt(sx?.height?.split('px')[0] || '100') * 0.8}px`;

    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                overflow: 'hidden',
                margin: 0,
                borderRadius: '10px',
                position: 'relative',
                ...sx
            }}
        >
            <img style={{
                    width: '100%',
                    height: 'inherit',
                    objectFit: 'cover'
                }}
            onError={(event) => event.currentTarget.style.display='none'}
            src={`${constants.BASE_IMAGE_URL}/${artist.banner?.standard?.path}`} />
            <Box sx={{
                position: 'absolute',
                width: '100%',
                height: '70%',
                zIndex: '101',
                bottom: '0',
                left: '0',
                backgroundColor: 'rgba(0,0,0, 0.5)'
            }}>
            </Box>
            <ArtistContentWrapper>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'end',
                    ml: 2,
                }}>
                    <ArtistAvatar user={artist} sx={{
                        width: avatarSize,
                        height: avatarSize,
                        boxShadow: "0 0 0 2px #fff",
                    }} />
                    <VerifiedBadge user={artist}
                        sx={{
                            ml: 2,
                        }}
                        displayNameSx={{
                            fontSize: '20px',
                            color: '#fff',
                            lineHeight: '1',
                            fontWeight: 'bold',
                        }}
                        accountIdSx={{
                            fontSize: '14px',
                            color: '#fff',
                            lineHeight: '0.8',
                        }}/>         
                </Box>           
            </ArtistContentWrapper>
            {!!moreMenu?.length && <MoreMenu sx={{
                position: 'absolute',
                right: 8,
                top: 16,
                zIndex: 100,
                color: 'white',
            }}
                options={moreMenu}></MoreMenu>}
        </Box>
    );
};

export default ArtistProfileRef;
