import React from 'react';
import { Avatar, Box, Button, IconButton, TableCell, TableRow, Typography } from '@mui/material'
import AdminTemplate from '../components/AdminTemplate'
import EnhancedTable, { DataRow, HeadCell } from '../../../components/EnhancedTable';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import helpers from '../../../utils/helpers';
import { BASE_IMAGE_URL } from '../../../utils/constants';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { PostModel } from '../types/PostModel';
import StandardModal from '../../../components/StandardModal';
import { api } from '../../../services/api';

interface Data extends PostModel, DataRow {
  avatar: string;
  accountId: string;
}

export default function ContentModeration() {

  const [detailsOpen, setDetailsOpen] = useState(false);
  const [selected, setSelected] = useState<PostModel | undefined>();

  const { data: rows } = useQuery({
    queryKey: ['posts'],
    queryFn: async () => {
      const { data } = await api.get(`/main/posts`);
      return data as Data[];
    },
  });

  const headCells: readonly HeadCell<Data>[] = [
    {
      id: 'avatar',
      numeric: false,
      disablePadding: false,
      label: 'Avatar',
    },
    {
      id: 'artist',
      numeric: false,
      disablePadding: false,
      label: 'Artist',
    },
    {
      id: 'accountId',
      numeric: false,
      disablePadding: false,
      label: 'Account',
    },
    {
      id: 'content',
      numeric: false,
      disablePadding: false,
      label: 'Content',
    },
    {
      id: 'isPrivate',
      numeric: false,
      disablePadding: false,
      label: 'isPrivate',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'status',
    },
    {
      id: 'deleted',
      numeric: false,
      disablePadding: false,
      label: 'deleted',
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: false,
      label: 'Created at',
    },
    {
      id: 'id',
      numeric: true,
      disablePadding: false,
      label: '',
    },
  ];

  function getTableRow(row: Data,
    isItemSelected: boolean,
    labelId: string,
    handleClick: (event: React.MouseEvent<unknown>, id: string) => void) {
    return (<TableRow
      hover
      onClick={(event) => handleClick(event, row.id)}
      // aria-checked={isItemSelected}
      tabIndex={-1}
      key={`${row.id}-${row.createdAt}`}
      // selected={isItemSelected}
      sx={{ cursor: 'pointer' }}
    >
      <TableCell>
        <Avatar
          alt={row.artist?.displayName}
          src={`${BASE_IMAGE_URL}/${row.artist?.avatar?.small?.path}`}
          sx={{ width: 40, height: 40 }}
        />
      </TableCell>
      <TableCell>{row.artist?.displayName}</TableCell>
      <TableCell>{row.artist?.accountId}</TableCell>
      <TableCell sx={{ width: '200px' }}>{row.content}</TableCell>
      <TableCell>{row.isPrivate ? 'S' : 'N'}</TableCell>
      <TableCell>{row.status}</TableCell>
      <TableCell>{row.deleted ? 'S' : 'N'}</TableCell>
      <TableCell>{helpers.timeStampToFormatedDate(row.createdAt)}</TableCell>
      <TableCell align="right" >
        <IconButton
          aria-label="delete"
          onClick={() => {
            setSelected(row);
            setDetailsOpen(true);
          }}
        >
          <BorderColorRoundedIcon />
        </IconButton>
      </TableCell>
    </TableRow>
    )
  }

  const veredict = (params: { post?: PostModel, approved: boolean }) => {
    return api.put(`/main/posts/${params.post?.id}/${params.post?.createdAt}`, { status: params.approved ? 'approved' : 'rejected' })
  }

  const queryClient = useQueryClient();

  const veredictMutation = useMutation({
    mutationFn: veredict,
    onMutate: async ({ post, approved, }) => {

      await queryClient.cancelQueries({ queryKey: ['posts'] });
      const previousData = queryClient.getQueryData<Data[]>(['posts']);
      queryClient.setQueryData<Data[]>(['posts'], (old) => old?.filter((row) => row.id != post?.id && row.createdAt != post?.createdAt));

      return { previousData }
    },
    onError: (error, variables, context) => {
      console.log('error', error)
      queryClient.setQueryData<Data[]>(['posts'], context?.previousData);
    },
    onSuccess: (data, variables, context) => {
      toast.success('Veredict saved');
    },
  });


  return (
    <AdminTemplate>
      <StandardModal openState={[detailsOpen, setDetailsOpen]} onClose={() => setDetailsOpen(false)} sx={{
        maxWidth: 1200,
        p: 2,
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 50%',
            gap: 1,
          }}>
            <Avatar src={`${BASE_IMAGE_URL}/${selected?.artist?.avatar?.small?.path}`} sx={{ width: 80, height: 80 }} />
            <Typography variant="h5">{selected?.artist?.displayName} · {selected?.artist?.accountId}</Typography>
            <Typography variant="body1">{selected?.artist?.id}</Typography>
            <Typography variant="body1">{selected?.artist?.email}</Typography>
            <Typography variant="body1">Created at {helpers.timeStampToFormatedDate(selected?.artist?.createdAt)} ·
              Verified {selected?.artist?.verified}</Typography>
            <Typography variant="body2">{selected?.artist?.description}</Typography>
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 50%',
            gap: 1,
          }}>
            <Typography variant="h5">Deleted {selected?.deleted ? 'S' : 'N'} ·
              Private {selected?.isPrivate ? 'S' : 'N'} ·
              Status {selected?.status} ·
              Created at {helpers.timeStampToFormatedDate(selected?.createdAt)}</Typography>
            <Typography variant="body1">{selected?.id}</Typography>
            <Typography variant="body2">{selected?.content}</Typography>
            <img src={selected?.assets?.[0]?.type === 'image' ?
              selected?.assets?.[0]?.original?.path :
              selected?.assets?.[0]?.poster?.original?.path}
              alt={selected?.content} style={{ maxWidth: 320, maxHeight: 480 }} />
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              gap: 2,
            }}>
              <Button variant="outlined" color="primary" onClick={
                () => {
                  veredictMutation.mutate({ post: selected, approved: false });
                  setDetailsOpen(false);
                }}>Reject</Button>
              <Button variant="outlined" color="primary" onClick={
                () => {
                  veredictMutation.mutate({ post: selected, approved: true });
                  setDetailsOpen(false);
                }}>Approve</Button>
            </Box>
          </Box>
        </Box>
      </StandardModal>
      <Box sx={{
        p: 2,
      }}>
        <Typography variant="h1">Content Moderation</Typography>
        <Box sx={{ mt: 2 }}>
          {rows && <EnhancedTable
            headCells={headCells}
            getTableRow={getTableRow}
            rows={rows}
            title=""
            dense
          />}
        </Box>
      </Box>
    </AdminTemplate>
  )
}