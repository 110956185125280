import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, TextField, Typography } from "@mui/material";
import CountrySelect from '../CountrySelect';
import helpers from '../../utils/helpers';
import { PixPaymentRequest, addPaymentMethod } from '../../services/settingsService';
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PaymentMethodModel } from "../../types/PaymentMethodModel";

type PixPaymentMethodProps = {
    onCancel: () => void;
    onSuccess: (data: PaymentMethodModel) => void;
    paymentMethod?: PaymentMethodModel;
    setLoading: React.Dispatch<React.SetStateAction<number>>;
}

export default function PixPaymentMethod({ 
    onCancel, 
    onSuccess, 
    paymentMethod,
    setLoading
}: PixPaymentMethodProps) {

    const queryClient = useQueryClient();
    
    const defaultValues = {
        paymentMethodId: 'pix',
        fullName: paymentMethod?.fullName || '',
        countryCode: paymentMethod?.countryCode || 'BR',
        phone: paymentMethod?.phone || '',
        document: paymentMethod?.document || '',
    }

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors }
    } = useForm<PixPaymentRequest>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
        defaultValues: defaultValues
    });

    const addPaymentMethodMutation = useMutation({
		mutationFn: addPaymentMethod,
		onSuccess: (data) => {
            setLoading(prev => prev - 1);
			queryClient.invalidateQueries({ queryKey: ['paymentMethods'] });
            onSuccess(data);
		},
        onError: (error) => {
            setLoading(prev => prev - 1);
        }
	})

    const phoneValue = watch('phone');

    useEffect(() => {
        if (phoneValue) {
            setValue('phone', helpers.masks.phone(phoneValue));
        }
    }, [phoneValue])

    const handlePixPayment = (data: PixPaymentRequest) => {
        const payload = {
            ...data,
            phone: data.phone?.replace(/\D/g, ''),
            document: data.document?.replace(/\D/g, ''),
        }
        setLoading(prev => prev + 1);
        addPaymentMethodMutation.mutate(payload);
    }

    return (
        <Box>
            <Typography variant="h5">Pagar com PIX</Typography>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                mt: 2,

            }} component="form" onSubmit={handleSubmit(values => handlePixPayment(values))}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <TextField
                        label="Nome do pagador"
                        variant="outlined"
                        fullWidth
                        {...register('fullName', {
                            required: true,
                            minLength: 3,
                            maxLength: 100,
                            pattern: /^[a-zA-Z\s]*$/

                        })}
                    />
                </Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Box sx={{
                        flex: '1 0'
                    }}>
                        <CountrySelect defaultValue={'BR'} onChange={
                            (e, newValue) => setValue('countryCode', newValue?.value || 'BR')
                        } />
                    </Box>
                    <TextField
                        label="Telefone"
                        variant="outlined"
                        sx={{ flex: '1 0' }}
                        {...register('phone', {
                            required: true,
                            validate: {
                                validatePhone: (value) => {
                                    if (helpers.isValidPhone(value)) {
                                        return true;
                                    }
                                    return false;
                                }
                            }

                        })}
                    />
                </Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <TextField
                        label="CPF"
                        fullWidth
                        variant="outlined"
                        sx={{ flex: '1 0' }}
                        {...register('document', {
                            required: true,
                            validate: {
                                validateCPF: (value) => {
                                    if (helpers.isValidCpf(value)) {
                                        return true;
                                    }
                                    return false;
                                }
                            }

                        })}
                    />
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                    <Button size="small" autoFocus onClick={() => onCancel()}>
                        VOLTAR
                    </Button>
                    <Button
                        size="small"
                        type="submit"
                        color="primary" variant="contained">
                        PAGAR
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}