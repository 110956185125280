import React from 'react';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import { Box, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

type VerifiedBadgeProps = {
  user: any;
  hideAccountId?: boolean;
  sx?: any;
  displayNameSx?: any;
  badgeSx?: any;
  accountIdSx?: any;
}

export default function VerifiedBadge({ 
  user, 
  hideAccountId,
  sx, 
  displayNameSx,
  badgeSx,
  accountIdSx 
}: VerifiedBadgeProps) {
  return (
    <Box
      sx={{      
        width: '100%',
        gap: 0.5,
        ...sx
      }}>
      <Link component={RouterLink} to={`/artist/${user?.accountId}`}
        sx={{
          color: 'inherit',
          fontSize: 'inherit',
          fontWeight: 'inherit',
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'none'
          },
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          userSelect: 'text',
          display: 'flex',
          alignItems: 'center',
          gap: 0.5,
          ...displayNameSx
        }}
      ><Typography sx={{
        color: 'inherit',
        fontSize: 'inherit',
        fontWeight: 'bold',
        textDecoration: 'none',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        userSelect: 'text',
        ...displayNameSx
      }}>{user?.displayName}</Typography>
      <VerifiedOutlinedIcon sx={{
        fontSize: '1rem',
        display: user?.verified === 'approved' ? 'inline' : 'none',
        ...badgeSx
      }}/>
      </Link>
      {!hideAccountId && <Link component={RouterLink} 
        sx={{
          color: 'inherit',
          fontSize: 'inherit',
          fontWeight: 'inherit',
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'none'
          },
          ...accountIdSx
        }} 
        to={`/artist/${user?.accountId}`}>@{user?.accountId}</Link>}
    </Box>
  )
}
