import { PaginatedList } from "../types/PaginatedList";
import { PostModel } from "../types/PostModel";
import { ProfileModel } from "../types/ProfileModel";
import { unhandledApi, api } from "./api";
import axios from 'axios';
import { API_URL } from '../utils/constants';

export type UserList = {
  following: Array<ProfileModel>;
  followers: Array<ProfileModel>;
  blocked: Array<ProfileModel>;
}

export const collectionListUsers = (): Promise<any> => {
  return api.get<any>(`collections/user-lists`).then((res) => res.data);
}

export const collectionBookmarks = (): Promise<any> => {
  return api.get<any>(`collections/bookmarks`).then((res) => res.data);
}

export const listUsers = (list?: string, lastEvalKey?: any): Promise<PaginatedList<ProfileModel>> => {
  return api.get<PaginatedList<ProfileModel>>(`collections/user-lists/${list}/users/${lastEvalKey}`).then((res) => res.data);
}

export const listPosts = (list?: string, lastEvalKey?: any): Promise<PaginatedList<PostModel>> => {
  return api.get<PaginatedList<PostModel>>(`collections/user-lists/${list}/posts/${lastEvalKey}`).then((res) => res.data);
}

export const listBookmarkPosts = (lastEvalKey?: any): Promise<PaginatedList<PostModel>> => {
  return api.get<PaginatedList<PostModel>>(`collections/bookmarks/posts/${lastEvalKey}`).then((res) => res.data);
}

export const follow = (artistId?: string): Promise<any> => {
  return unhandledApi.put(`collections/artist/${artistId}/follow`).then((res) => res.data);
}

export const block = (artistId?: string): Promise<any> => {
  return unhandledApi.put(`collections/artist/${artistId}/block`).then((res) => res.data);
}

export const getPosts = (lastEvalKey?: any): Promise<PaginatedList<PostModel>> => {
  return api.get(`collections/feed/${lastEvalKey}`).then((res) => res.data);
}

export const changeBookmark = (post: PostModel): Promise<void> => {
  return unhandledApi.put(`collections/artist/${post.id}/posts/${post.createdAt}/bookmark`).then((res) => res.data);
}

export const getPost = (accountId?: string, id?: string): Promise<PostModel[]> => {
  return unhandledApi.get(`collections/artist/${accountId}/post/${id}`).then((res) => res.data);
}


export const getArtistByAccountId = (accountId?: string): Promise<ProfileModel> => {
  return unhandledApi.get(`collections/artist/by/${accountId}`).then((res) => res.data);
};

export const getArtistById = (id?: string): Promise<ProfileModel> => {
  return api.get<ProfileModel>(`collections/artist/${id}`).then((res) => res.data);
};

export const getArtistPosts = (artistId?: string, lastEvalKey?: any): Promise<PaginatedList<PostModel>> => {
  return api.get<PaginatedList<PostModel>>(`collections/artist/${artistId}/posts/${lastEvalKey}`).then((res) => res.data);
};


export type ReportRequest = {
  reason?: string;
  reportedId?: string;
  reportedPostId?: number;
}

export const report = (data: ReportRequest) => {
  return api.post(`collections/report`, data).then((res) => res.data)
}

export type CreatePostRequest = {
  content: string;
  assets: Array<string>;
}

export const getUserPosts = (lastEvalKey?: any): Promise<PaginatedList<PostModel>> => {
  return api.get(`collections/user/posts/${lastEvalKey}`).then((res) => res.data)
}

export const createPost = (request: CreatePostRequest): Promise<any> => {
  return api.post(`collections/user/posts`, request).then((res) => res.data)
}

export const deletePost = (postId?: number): Promise<void> => {
  return unhandledApi.delete(`collections/user/posts/${postId}`).then((res) => res.data);
}

export const changePrivacy = (postId?: number): Promise<void> => {
  return unhandledApi.put(`collections/user/posts/${postId}/change-privacy`, {}).then((res) => res.data);
}

export const getLastPosts = (): Promise<Array<PostModel>> => {
  return axios.get(`${API_URL}/collections/last`).then((res) => res.data);
}