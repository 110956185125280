import { useContext, useEffect } from "react";
import useWebSocket from "react-use-websocket";
import { markAsRead } from "../services/conversationService";
import { SignalType } from "../types/SignalType";
import { ConversationModel } from "../types/ConversationModel";
import { MessageModel, PaymentEventModel } from "../types/MessageModel";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as constants from '../utils/constants'
import helpers from "../utils/helpers";
import { UserContext } from "../App";

const WebSockets: React.FC = () => {

    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { currentUser, setCurrentUser } = useContext(UserContext);
    const { lastJsonMessage } = useWebSocket(`${constants.WSS_URL}?token=${currentUser.token}`, {
        share: true,        
        shouldReconnect: () => true,
        reconnectAttempts: 10,
        reconnectInterval: (attemptNumber) => Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
      });

    const markAsReadMutation = useMutation({
        mutationFn: markAsRead,
        onSuccess: (data) => {
          queryClient.invalidateQueries({ queryKey: ['unreads']});
        }
    })

    useEffect(() => {

      if(!lastJsonMessage) return;

      let message = lastJsonMessage as unknown as any;

      if(message.type === SignalType.MESSAGE){
        message = message as MessageModel;
        const path = window.location.pathname
        const isChatScreen = !!path.match(/^\/chats/)
        const artistId = path.match(/^\/chats\/\w+$/) ? path.split('/')[2] : null

        if(isChatScreen){
          queryClient.setQueryData<Array<ConversationModel>>(['conversations'],
            (oldData) => {
                let newData = [...(oldData?.filter( conver => conver.conversationId !== message.id) || [])]
                newData.unshift({
                  conversationId: message.id,
                  receiverId: message.sender?.id,
                  receiver: message.sender,
                  lastMessage: message.body,
                  unread: artistId !== message?.senderId,
                  id: '',
                  updatedAt: message.createdAt
                })
                return newData
              }
            )
          
          // if the current chat artist mark as read
          if(artistId === message?.senderId){
            queryClient.setQueryData<Array<MessageModel>>(['messages', artistId],
              (oldData) => {
                  return  [...(oldData || []), message]
              }
            )
            markAsReadMutation.mutate(message.id)
          }

        } else {
          
          queryClient.invalidateQueries({ queryKey: ['unreads']});
        
          toast.info(
            <div style={{paddingLeft: '16px'}}>
              <b>{message.sender?.displayName}</b> enviou uma mensagem!
            </div>, 
          {
            onClick: () => {
              markAsRead(message.id)
              navigate(`/chats/${message.senderId}`)
            },
            icon: <img 
            onError={(event) => event.currentTarget.style.display='none'}
            src={`${constants.BASE_IMAGE_URL}/${message.sender?.avatar?.small?.path}`}
                        style={{ width: 28, height: 28, borderRadius: '50%'}}/>
          })
        }
      
      } else if (message.type === SignalType.PAYMENT_EVENT){
        message = message as PaymentEventModel;
        if(message.status === 'succeeded'){
          dispatchEvent(new Event("subscriptionInfoConfirmPayment"));
        } else if(message.status === 'failed'){
          toast.error('Pagamento falhou!');
        }
      }
    }, [lastJsonMessage])

    return <></>
}

export default WebSockets;