import React, { useEffect } from "react";
import { Link, useTheme } from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import MessageRoundedIcon from '@mui/icons-material/MessageRounded';
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import { Avatar, Badge, Box, Button, Typography } from "@mui/material";
import { useContext } from "react";
import { UserContext } from "../../App";
import * as constants from '../../utils/constants'
import FolderSpecialOutlinedIcon from '@mui/icons-material/FolderSpecialOutlined';
import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useTranslation } from 'react-i18next';

type SideMenuProps = {
    showSettingsHandler: (show: boolean) => void;
}

const SideMenu = ({ showSettingsHandler }: SideMenuProps) => {
    const { t } = useTranslation();
    const location = useLocation();
    const theme = useTheme();

    const isActive = (path: string) =>
        (path === '/' && location.pathname === path) || (path !== '/' && location.pathname.startsWith(path)) ? 'active' : '';

    const { currentUser, setCurrentUser } = useContext(UserContext);

    const handleSettings = (e: any) => {
        e.preventDefault();
        showSettingsHandler(true)
    }

    const liStyles = {
        display: 'flex',
        transition: 'all 0.3s',
        color: '#888',
        width: '100%',
    }

    const linkStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        padding: '10px',
        margin: '10px',
        width: '100%',
        textDecoration: 'none',
        color: 'inherit',
        borderRadius: '10px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.main,
        },
        '&.active': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.main,
        },
    }

    const textStyles = { 
        display: { 
            sm: 'none', 
            md: 'none', 
            lg: 'block'
        }, 
        fontWeight: 500, 
        fontSize: '18px', 
        marginLeft: '10px' 
    }


    return (
        <Box sx={{
            display: { xs: 'none', sm: 'block' },
            width: { sm: '60px', md: '60px', lg: '280px' },
            borderRight: 'var(--border)',
        }}>
            <Box sx={{
                top: 0,
                position: 'sticky',
                paddingTop: 4
            }}>
                <Avatar
                    alt={currentUser.displayName}
                    src={`${constants.BASE_IMAGE_URL}/${currentUser.avatar?.small?.path}`}
                    sx={{ width: 48, height: 48, margin: '0 auto 10px auto' }} />
                <ul style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    justifyContent: 'center',
                    listStyle: 'none',
                    padding: 0,
                    margin: 0
                }}>
                    <li style={liStyles}>
                        <Link component={RouterLink} to="/" sx={linkStyles} className={isActive('/')}>
                            <HomeRoundedIcon />
                            <Typography sx={textStyles} variant="h3">{t('sideMenu.home')}</Typography>
                        </Link>
                    </li>
                    <li style={liStyles}>
                        <Link component={RouterLink} to="/chats" sx={linkStyles} className={isActive('/chats')}>
                            <Badge sx={{
                                '& .MuiBadge-badge': {
                                    boxShadow: `0 0 0 2px #fff`,
                                    minWidth: '12px',
                                    height: '12px',
                                    borderRadius: '50%',
                                    backgroundColor: 'red',
                                    visibility: currentUser.unreads?.length ? 'visible' : 'hidden'
                                },
                            }}
                                overlap="circular"
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                variant="dot">
                                <MessageRoundedIcon />
                            </Badge>
                            <Typography sx={textStyles} variant="h3">{t('sideMenu.messages')}</Typography>
                        </Link>
                    </li>
                    <li style={liStyles}>
                        <Link component={RouterLink} to="/collections" sx={linkStyles} className={isActive('/collections')}>
                            <FolderSpecialOutlinedIcon />
                            <Typography sx={textStyles} variant="h3">{t('sideMenu.collections')}</Typography>
                        </Link>
                    </li>
                    <li style={liStyles}>
                        <Link component={RouterLink} to="/profile" sx={linkStyles} className={isActive('/profile')}>
                            <AccountBoxRoundedIcon />
                            <Typography sx={textStyles} variant="h3">{t('sideMenu.myProfile')}</Typography>
                        </Link>
                    </li>
                    <li style={liStyles}>
                        <Link component={RouterLink} to="/settings" onClick={handleSettings} sx={linkStyles}>
                            <MoreHorizIcon />
                            <Typography sx={textStyles} variant="h3">{t('sideMenu.settings')}</Typography>
                        </Link>
                    </li>
                </ul>
                <Box sx={{ padding: '6px' }}>
                    <Button component={RouterLink} to="/create"
                        variant="contained" sx={{
                            minWidth: '48px',
                            width: { sm: '48px', md: '48px', lg: '100%' },
                            height: '48px',
                            borderRadius: '45px',
                            display: 'flex',
                        }}>
                        <AddIcon />
                        <Typography sx={{
                            display: { sm: 'none', md: 'none', lg: 'block' },
                        }}>{t('sideMenu.newPost')}</Typography>
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

export default SideMenu;
