import { ConversationModel } from "../types/ConversationModel";
import { MessageModel } from "../types/MessageModel";
import { unhandledApi, api } from "./api";

export type SendMessageRequest = {
    receivers: string[];
    body: string;
}

export const sendMessage = (request: SendMessageRequest): Promise<ConversationModel[]> => {
    return unhandledApi.post<ConversationModel[]>(`conversation/chats/messages`, request).then((res) => res.data);
    }

export const getMessages = (artistId?: string): Promise<Array<MessageModel>> => {
    return api.get<Array<MessageModel>>(`conversation/chats/messages/${artistId}`).then((res) => res.data);
    }   

export const getConversations = (): Promise<Array<ConversationModel>> => {
    return api.get<Array<ConversationModel>>(`conversation/chats`).then((res) => res.data);
    
    };

export const getUnreads = (): Promise<Array<string>> => {
    return unhandledApi.get<any>(`conversation/chats/unreads`).then((res) => res.data);
    }

export const markAsRead = (conversationId: string): Promise<void> => {
    return unhandledApi.post(`conversation/chats/${conversationId}/read`).then((res) => res.data)
}