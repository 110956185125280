import React from "react";
import { Grid } from "@mui/material";
import SideMenu from "../SideMenu";

type AdminTemplateProps = {
	children: React.ReactNode;
}

export default function AdminTemplate({ children }: AdminTemplateProps) {

	return (
		<Grid container spacing={2} sx={{
			width: '100%',
		}}>
			<Grid item sx={{
				justifyContent: 'right',
				display: 'flex',
				maxWidth: '250px',
				borderRight: '1px solid #ccc',
			}}>
				<SideMenu />
			</Grid>
			<Grid item sx={{
				width: 'calc(100% - 250px)',
			}}>
				{children}
			</Grid>
		</Grid>
	)
}