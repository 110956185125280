import { Box, Chip, List, Tab, Tabs, Typography } from '@mui/material';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { getArtistPosts } from '../../services/collectionsService';
import Post from '../../components/Post';
import EmptyFeed from "../../components/EmptyFeed";
import React, { useEffect, useState } from 'react';
import CustomGallery from '../../components/CustomGallery';
import TabPanel from '../../components/TabPanel';
import { PostModel } from '../../types/PostModel';
import { PaginatedList } from '../../types/PaginatedList';
import InfiniteScrollObserver from '../../components/InfiniteScrollObserver';

type ArtistTabsProps = {
	artistId: string,
	imagesCount: number,
	videosCount: number
}

export default function ArtistTabs({ 
	artistId,
	imagesCount,
	videosCount
 }: ArtistTabsProps) {

	const queryClient = useQueryClient();

	useEffect(() => {
		return () => {
			queryClient.setQueryData(['artistPosts', artistId], (data: any) => ({
				pages: data?.pages?.slice(0, 1) ?? [],
				pageParams: data?.pageParams.slice(0, 1) ?? [],
			}))
		}
	}, [])

	const posts = useInfiniteQuery<PaginatedList<PostModel>, Error>({
		queryKey: ['artistPosts', artistId],
		queryFn: (query) => getArtistPosts(artistId, query.pageParam),
		getNextPageParam: (lastPage) => lastPage?.lastEvalKey,
		defaultPageParam: 0,
	})

	const [currentTab, setCurrentTab] = useState<number>(0)
	const [mediaFilter, setMediaFilter] = useState<'all' | 'image' | 'video'>('all')
	const getChipStyle = (type: 'all' | 'image' | 'video'): any => {
		if (mediaFilter == type) {
			return {
				color: 'secondary',
				disabled: true
			}
		}
		return {}
	}

	const refetchPosts = () => {
		queryClient.invalidateQueries({ queryKey: ['artistPosts', artistId] })
	}

	useEffect(() => {
			addEventListener('refetchArtistPosts', refetchPosts);
			return () => removeEventListener('refetchArtistPosts', refetchPosts);
	}, []);

	return (
		<>
			<Tabs
				value={currentTab}
				onChange={(_, newTab: number) => {
					setCurrentTab(newTab);
				}}
				indicatorColor="primary"
				textColor="inherit"
				variant="fullWidth"
				sx={{ borderBottom: 'var(--border)' }}>
				<Tab label={
					<Typography component="h1" variant="h6">PUBLICAÇÕES</Typography>
				} id='full-width-tab-0' aria-controls='full-width-tabpanel-0' />
				<Tab label={
					<Typography component="h1" variant="h6">MÍDIAS</Typography>
				} id='full-width-tab-1' aria-controls='full-width-tabpanel-1' />
			</Tabs>
			<TabPanel value={currentTab} index={0}>
				<Box sx={{ display: 'flex', flex: 'row', p: 2, gap: 2 }}>
					<Chip label={`Todos ${imagesCount + videosCount}`}
						onClick={() => setMediaFilter('all')} {...getChipStyle('all')} />
				</Box>
				{!!posts?.data?.pages?.some(p => p.items?.length) ? <List sx={{ 
					width: '100%',
					}}>
					{posts?.data?.pages?.map(page => page.items?.map((post: PostModel) => (
						<Post key={post.createdAt} post={post} queryKey={['artistPosts', artistId]} />
					)))}
				</List> : <EmptyFeed />}
			</TabPanel>
			<TabPanel value={currentTab} index={1}>
				<Box sx={{ display: 'flex', flex: 'row', p: 2, gap: 2 }}>
					<Chip label={`Todos ${imagesCount + videosCount}`}
						onClick={() => setMediaFilter('all')} {...getChipStyle('all')} />
					<Chip label={`Fotos ${imagesCount}`}
						onClick={() => setMediaFilter('image')} {...getChipStyle('image')} />
					<Chip label={`Videos ${videosCount}`}
						onClick={() => setMediaFilter('video')} {...getChipStyle('video')} />
				</Box>
				{!!posts?.data?.pages?.some(p => p.items?.length) ?
					<CustomGallery posts={posts?.data?.pages?.flatMap(page => page.items || [])} mediaFilter={mediaFilter} /> :
					<EmptyFeed />}
			</TabPanel>
			{posts && <InfiniteScrollObserver fetchNextPage={posts.fetchNextPage} />}
		</>
	)
}