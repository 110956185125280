import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Box, BoxProps } from '@mui/material';

const ITEM_HEIGHT = 48;

export type MoreMenuXOption = {
  name: string;
  label: string;
  onClick: () => void;
}

interface MoreMenuXProps extends BoxProps {
  options?: MoreMenuXOption[];
}

export default function MoreMenuX({ options, ...props }: MoreMenuXProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box {...props}>

      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          color: 'secondary.light',
          fontSize: '14px',
          py: 0,
        }}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => { setAnchorEl(null) }}
        slotProps={{
          paper: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch',
            },
          },
        }}
      >
        {options?.map((option) => (
          <MenuItem key={option.name} onClick={(evt) => {
            option.onClick();
            setAnchorEl(null);
          }}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}