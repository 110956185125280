import { Box, Grid } from "@mui/material"
import React, { useState } from "react"
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from "@mui/material";
import logoSvg from "../../../assets/logo-meuencontro.svg"
import LastPosts from "../../../components/LastPosts";
import Footer from "../../../components/PublicTemplate/footer";
import OAuthButton from '../../../components/OAuthButton';
import googleSvg from "../../../assets/google.svg";
import { useTranslation } from 'react-i18next';

type LandingWrapperProps = {
  children?: React.ReactNode;
}

export default function LandingWrapper({ children }: LandingWrapperProps) {

  const [currentApiEnv, setCurrentApiEnv] = useState(localStorage.getItem('env') || 'prd');
  const { t } = useTranslation();

  const devWarning = (displayOptions: any) => {
    return (
      <Box sx={{
        display: displayOptions,
        flexDirection: 'column',
        gap: 2,
        width: '80%',
        mx: 'auto',
        mb: 2,
        p: 4,
        backgroundColor: 'background.paper',
        borderRadius: '6px',
        boxShadow: '0 3px 6px rgba(0, 0, 0, .16)',

      }}>
        <Typography variant="h5">{t('common.caution')}</Typography>
        <Typography variant="body1">
          {t('common.devWarning')}
        </Typography>
        <Button component={Link} href="https://meuencontro.com" variant="contained" fullWidth>
          {t('common.goToProduction')}
        </Button>
      </Box>
    )
  }

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item
        xs={false}
        sm={false}
        md={6}
        sx={{
          backgroundColor: "rgba(252, 215, 237, 0.5)",
        }}
      >
        <Box
          sx={{
            my: 2,
            mx: 'auto',
            display: {
              xs: 'none',
              sm: 'none',
              md: 'flex',
            },
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: '400px',
            p: 2
          }}
        >
          <img src={logoSvg} alt="Logo" style={{ width: "100%" }} onError={(event) => event.currentTarget.style.display = 'none'} />

          {devWarning({
            xs: 'none',
            sm: 'none',
            md: currentApiEnv === 'dev' ? 'flex' : 'none',
          })}
        </Box>
      </Grid>

      <Grid 
        item 
        xs={12} 
        sm={12} 
        md={6} 
        sx={{
          p: 2
        }}
        >
        <Box
          sx={{
            my: {
              xs: 2,
              sm: 2,
              md: 6,
            },
            mx: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: '400px',
          }}
        >
          <Box sx={{
            width: "100%",
            display: {
              xs: 'flex',
              sm: 'flex',
              md: 'none',
            },
          }}>
            <img src={logoSvg} alt="Logo" style={{
              width: "100%",
            }} onError={(event) => event.currentTarget.style.display = 'none'} />
          </Box>
          {devWarning({
            xs: currentApiEnv === 'dev' ? 'flex' : 'none',
            sm: currentApiEnv === 'dev' ? 'flex' : 'none',
            md: 'none',
          })}
          {children}
          <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mt: 2,
            gap: 2,
          }}>
            {/* <OAuthButton provider={"twitter"}>Login with Twitter</OAuthButton> #1DA1F2 */}
            <OAuthButton provider={"google"} sx={{
              backgroundColor: '#DB4437',
              color: 'white',
              '&:hover': {
                backgroundColor:'#DB4437',
              },
            }}
            startIcon={<Box sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '24px',
              height: '24px',
              borderRadius: '50%',
              backgroundColor: 'white',
              marginRight: '5px',
            
            }}><img src={googleSvg} alt="Google" style={{ width: '20px' }} /></Box>}
            >{t('auth.signInWithGoogle')}</OAuthButton>
          </Box>
        </Box>
      </Grid>
      <LastPosts />
      <Footer />
    </Grid>
  );
}