import {
    Avatar, Box,
    Typography,
    useMediaQuery
} from "@mui/material";
import { Breadcrumb } from "../../components/Breadcrumb";
import Header from "../../components/Header";
import React, { useContext, useEffect, useRef } from "react";
import { ArtistAvatar } from "../../components/ArtistAvatar";
import { UserContext } from "../../App";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getMessages, getConversations, markAsRead } from "../../services/conversationService";
import { ConversationModel } from "../../types/ConversationModel";
import { MessageModel } from "../../types/MessageModel";
import * as constants from '../../utils/constants'
import SendMessageForm from "../../components/SendMessageForm";
import { getArtistById } from "../../services/collectionsService";
import PrivateTemplate from "../../components/PrivateTemplate";
import VerifiedBadge from "../../components/VerifiedBadge";
import { ProfileModel } from "../../types/ProfileModel";

const Conversations: React.FC = () => {
    const { currentUser, setCurrentUser } = useContext(UserContext);
    const { id: artistId } = useParams()
    const bottomRef = useRef<any | undefined>();
    const navigate = useNavigate()
    const queryClient = useQueryClient();
    const upMd = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

    const { data: conversations } = useQuery({
        queryKey: ['conversations'],
        queryFn: () => getConversations(),
    })

    const { data: artist }: { data: ProfileModel | undefined } = useQuery({
        queryKey: ['artist', artistId],
        queryFn: () => getArtistById(artistId),
        enabled: !!artistId,
    })

    const { data: messages } = useQuery({
        queryKey: ['messages', artistId],
        queryFn: () => getMessages(artistId),
        enabled: !!artistId,
    })

    useEffect(() => {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages])

    useEffect(() => {
        bottomRef.current?.scrollIntoView();
    }, [artistId])


    const markAsReadMutation = useMutation({
        mutationFn: markAsRead,
        onMutate: async (conversationId: string) => {
            await queryClient.cancelQueries({ queryKey: ['unreads'] });
            const previousUnreads = queryClient.getQueryData<Array<string>>(['unreads']);
            const newUnreads = previousUnreads?.filter(id => id !== conversationId);
            queryClient.setQueryData(['unreads'], newUnreads);

            await queryClient.cancelQueries({ queryKey: ['conversations'] });
            const previousConversations = queryClient.getQueryData(['conversations']) as ConversationModel[];
            const conversation = previousConversations?.find(c => c.conversationId === conversationId);
            if (conversation) {
                conversation.unread = false;
            }
            queryClient.setQueryData(['conversations'], previousConversations);
        },
        onError: (error, variables, context) => {
            queryClient.invalidateQueries({ queryKey: ['conversations'] });
            queryClient.invalidateQueries({ queryKey: ['unreads'] });
        }
    });

    const handleConversationClick = (conversation: ConversationModel) => {
        if (conversation.unread) {
            markAsReadMutation.mutate(conversation.conversationId);
        }
        navigate(`/chats/${conversation.receiverId}`)
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <PrivateTemplate>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                flexGrow: 1
            }}>
                <Box sx={{
                    display: !upMd && artist ? 'none' : 'flex',
                    flexDirection: 'column',
                    flex: '1 1 360px',
                    borderRight: 'var(--border)',
                }}>
                    <Header>
                        <Breadcrumb title="MENSAGENS" url='/' />
                    </Header>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        maxHeight: !upMd && !artist ? 'auto' : 'calc(100vh - 60px)',
                        overflowY: !upMd && !artist ? 'none' : 'auto'
                    }}>
                        {conversations?.map((conversation: ConversationModel) =>
                            <Box key={conversation.conversationId}
                                onClick={() => handleConversationClick(conversation)}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: 'primary.light',
                                    },
                                }}>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: 2,
                                    pl: 2,
                                    mt: 2

                                }}>
                                    <ArtistAvatar user={conversation.receiver} sx={{
                                        width: '40px',
                                        height: '40px',
                                    }}/>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        flex: '1 1 100%',
                                        mt: 0.5,
                                        pb: 1,
                                        borderBottom: 'var(--border)',
                                    }}>
                                        <VerifiedBadge sx={{
                                            fontWeight: conversation.unread ? 700 : 400,
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }} user={conversation.receiver}
                                            accountIdSx={{
                                                color: 'secondary.light',
                                            }}
                                        />
                                        <Typography variant="body2" sx={{ 
                                            fontWeight: conversation.unread ? 700 : 400,
                                            textOverflow: 'ellipsis',
                                            width: '100%',                                            

                                            }}>
                                            {conversation.lastMessage}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
                <Box sx={{
                    display: !upMd && !artist ? 'none' : 'flex',
                    flexDirection: 'column',
                    flex: '1 1 640px',
                    borderRight: 'var(--border)'
                }}>
                    {artist && <Header>
                        <Breadcrumb title={<VerifiedBadge user={artist} hideAccountId />} url='/chats' />
                    </Header>}
                    {!artist && <Box sx={{
                        fontSize: '24px',
                        fontWeight: 500,
                        diplay: 'flex',
                        alignSelf: 'center',
                        marginTop: 'auto',
                        marginBottom: 'auto'
                    }}>Selecione uma conversa</Box>}
                    {artist && <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1, padding: 2, gap: 2,
                        maxHeight: !upMd && artist ? 'auto' : 'calc(100vh - 74px - 68px)',
                        overflowY: !upMd && artist ? 'none' : 'auto'
                    }}>
                        {messages?.map((message: MessageModel, index: number) => {
                            return message.senderId === currentUser.id ? (
                                <Box key={index} sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                    marginBottom: 2
                                }}>
                                    <Box sx={{
                                        maxWidth: '70%',
                                        borderRadius: 'var(--radius-sd)',
                                        backgroundColor: 'var(--grey-color)',
                                        padding: 'var(--spacing-hf)',
                                        marginBottom: 0
                                    }} ><p>{message.body}</p></Box>
                                </Box>
                            ) : (
                                <Box key={index} sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    marginBottom: 2,
                                    gap: 1
                                }}>
                                    <Link to={`/artist/${artist?.accountId}`}>
                                        <ArtistAvatar user={artist} sx={{
                                            width: '40px',
                                            height: '40px',
                                        }}/>
                                    </Link>
                                    <Box sx={{
                                        maxWidth: '70%',
                                        borderRadius: 'var(--radius-sd)',
                                        backgroundColor: 'var(--grey-color)',
                                        padding: 'var(--spacing-hf)',
                                        marginBottom: 0
                                    }} ><p>{message.body}</p></Box>
                                </Box>
                            )
                        })}
                        <div ref={bottomRef}></div>
                    </Box>}
                    {artist && <SendMessageForm artist={artist} />}
                </Box>
            </Box>
        </PrivateTemplate>
    )
}

export default Conversations;