import React, { useContext, useState } from 'react';
import { Alert, Box, IconButton, ListItem, ListItemAvatar, Tooltip, Typography, Link } from '@mui/material';
import { PostModel } from '../../types/PostModel';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { ArtistAvatar } from '../ArtistAvatar';
import VideoJS from '../VideoJS';
import PostMediaImage from '../PostMediaImage';
import { Gallery, Item } from 'react-photoswipe-gallery';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import noPicLight from '../../assets/no-pic.png';
import noPicDark from '../../assets/no-pic-dark.png';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { UserContext } from '../../App';
import { changeBookmark } from '../../services/collectionsService';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VerifiedBadge from '../VerifiedBadge';
import ArtistProfileRef from '../ArtistProfileRef';
import MoreMenuX from '../MoreMenuX';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import ReportingModal from '../ReportingModal';
import { report } from '../../services/collectionsService';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';


type PostProps = {
  post: PostModel;
  onDelete?: (post: PostModel) => void;
  onPrivacyChange?: (post: PostModel) => void;
  queryKey?: any;
  hideMenu?: boolean;
  hideCommands?: boolean;
  sx?: any;
}

const Post: React.FC<PostProps> = ({
  post,
  onDelete,
  onPrivacyChange,
  queryKey,
  hideMenu,
  hideCommands,
  sx
}: PostProps) => {

  const { currentUser, setCurrentUser } = useContext(UserContext);
  const noPic = currentUser.darkMode ? noPicDark : noPicLight;
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const mutateBookmark = useMutation({
    mutationFn: changeBookmark,
    onMutate: async (post: PostModel) => {
      await queryClient.cancelQueries({ queryKey });
      const previousPosts = queryClient.getQueryData<any>(queryKey)
      queryClient.setQueryData<any>(queryKey, (oldData: any) => {
        return {
          ...oldData,
          pages: oldData.pages.map((page: any) => {
            return {
              ...page,
              items: page.items.map((item: any) => {
                if (item.createdAt === post.createdAt) {
                  return {
                    ...item,
                    bookmarked: !item.bookmarked
                  }
                }
                return item;
              })
            }
          })
        }
      });
      return { previousPosts }
    },
    onError: (err, variables, context) => {
      console.log(err)
      queryClient.setQueryData<any>(queryKey, context?.previousPosts)
    }
  })

  const handlePostContent = (content?: string) => {
    if (!content) return null;
    return (
      content.split('\n').map((phrase, index) => (
        <span key={index}>
          {phrase.split(' ').map((word, index) => {
            if (word.startsWith('@')) {

              word = word.replace(/[^a-zA-Z0-9]/g, '');
              return <span><Link component={RouterLink} key={word + index} to={`/artist/${word}`}>@{word}</Link>&nbsp;</span>;

            } else if (word.startsWith('#')) {

              word = word.replace(/[^a-zA-Z0-9]/g, '');
              return <span><Link component={RouterLink} key={word + index} to={`/search/${word}`}>#{word}</Link>&nbsp;</span>;

              // only meuencontro.com or meuencontro.com.br with https:// or or without, or with www. or without
            } else if (word.match(/(https?:\/\/)?(www\.)?(meuencontro\.com(\.br)?)(\/\S*)?/)) {

              let url = word.replace(/(https?:\/\/)/, '');
              return <span><Link component={RouterLink} key={word + index} to={'https://' + url}>{word}</Link>&nbsp;</span>;

            } else {
              return word + ' ';
            }
          })}
          <br />
        </span>
      ))
    );
  };


  const mutateReporting = useMutation({
    mutationFn: report,
    onSuccess: (data, variables, context) => {
      toast.success('Pronto, recebemos o seu pedido e nossa equipe irá analisá-lo.');
      navigate('/');
    }
  })

  const [reportingModalOpen, setReportingModalOpen] = useState<boolean>(false);

  const handleConfirmReporting = (reason?: string) => {
    if (!reason) return;
    mutateReporting.mutate({
      reportedId: post.id,
      reportedPostId: post.createdAt,
      reason
    });
  }

  return (
    <ListItem alignItems="flex-start" sx={{
      flex: '1',
      flexWrap: 'wrap',
      mt: 1,
      p: 0,
      ...sx,
    }}>
      <Box sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: '1',
      }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1.5, flex: '2' }}>
          <Link component={RouterLink} to={`/artist/${post.artist?.accountId}`}>
            <ArtistAvatar
              user={post.artist} sx={{
                width: '40px',
                height: '40px',
              }} />
          </Link>
          <VerifiedBadge user={post.artist}
            accountIdSx={{
              fontSize: '14px',
              fontWeight: 'normal',
              color: 'secondary.light',
            }}
          />
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          flex: '1',
          gap: 1,
          justifyContent: 'flex-end',
        }}>
          <Link component={RouterLink}
            sx={{
              textDecoration: 'none',
              color: 'secondary.light',
              fontSize: '14px',
              pt: '2px',
            }}
            to={`/artist/${post.artist?.accountId}/posts/${post.createdAt}`}>
            {dayjs.unix((post.createdAt || 0)).fromNow()}
          </Link>
          {!hideMenu && <MoreMenuX
            options={[
              { name: 'copyLink', label: 'Copiar link do post', onClick: () => { navigator.clipboard.writeText(`${new URL(window.location.href).origin}/artist/${post.artist?.accountId}/posts/${post.id}`); toast.success('Link copiado!') } },
              { name: 'report', label: 'Denunciar', onClick: () => setReportingModalOpen(true) },
            ]} />}
        </Box>
      </Box>

      {reportingModalOpen && <ReportingModal
        openState={[reportingModalOpen, setReportingModalOpen]}
        onConfirm={handleConfirmReporting} />
      }
      <Typography variant="body1" sx={{
        px: 2,
        py: 1,
        width: '100%',
      }}>
        {handlePostContent(post?.content)}
      </Typography>

      <Box sx={{
        backgroundImage: `url(${noPic})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        width: '100%',
        height: post?.assets.at(0)?.type === 'video' &&
          !post?.assets.at(0)?.poster?.standard?.path ? '360px' :
          post?.assets.at(0)?.type === 'image' &&
            !post?.assets.at(0)?.standard?.path ? '360px' : 'auto',
      }}>

        {((post?.assets.at(0)?.type === 'video' && !post?.assets.at(0)?.poster?.standard?.path) ||
          (post?.assets.at(0)?.type === 'image' && !post?.assets.at(0)?.standard?.path)) && <LockOutlinedIcon sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'primary.main',
            opacity: 0.5,
            fontSize: '3rem'
          }} />}

        {post?.assets.at(0)?.type === 'video' && <VideoJS style={{ minHeight: "360px" }}
          className="vjs-layout-medium vjs-big-play-centered"
          options={{
            controlBar: {
              children: [
                'playToggle',
                'progressControl',
                'volumePanel',
                'qualitySelector',
                'fullscreenToggle',
              ],
            },
            autoplay: false,
            controls: true,
            responsive: true,
            fluid: true,
            preload: 'none',
            poster: post?.assets.at(0)?.poster?.standard?.path,
            sources: [{
              src: post.assets.at(0)?.original?.path,
              type: 'video/mp4',
              label: 'original',
              res: 1080
            },
            {
              src: post.assets.at(0)?.standard?.path,
              type: 'video/mp4',
              label: '720p',
              res: 720,
              selected: true
            },
            {
              src: post.assets.at(0)?.small?.path,
              type: 'video/mp4',
              label: '240p',
              res: 240
            }
            ],
            html5: {
              vhs: {
                withCredentials: true
              }
            }
          }} />}

        {post?.assets.at(0)?.type === 'image' && <Gallery options={{ showHideAnimationType: 'fade', zoom: false }}>
          <Item
            original={post?.assets.at(0)?.original?.path}
            thumbnail={post?.assets.at(0)?.standard?.path}
            width={post?.assets.at(0)?.original?.width}
            height={post?.assets.at(0)?.original?.height}>
            {({ ref, open }) => (
              <PostMediaImage
                imageUrl={post?.assets.at(0)?.standard?.path}
                onClick={open} />
            )}
          </Item>
        </Gallery>}
        {post?.assets.at(0)?.type === 'video' && !post?.assets.at(0)?.poster?.standard?.path && <Box sx={{
          position: 'absolute',
          bottom: 10,
          left: 10,
        }}>
          <VideocamOutlinedIcon sx={{
            color: 'primary.main',
            fontSize: '1.2rem',
            opacity: 0.5
          }} /></Box>}

        {post?.assets.at(0)?.type === 'image' && !post?.assets.at(0)?.standard?.path && <Box sx={{
          position: 'absolute',
          bottom: 10,
          left: 10,
        }}>
          <ImageOutlinedIcon sx={{
            color: 'primary.main',
            fontSize: '1rem',
            opacity: 0.5
          }} /></Box>}
      </Box>
      
      {!!post?.refs?.length && <Box sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          sm: 'row'
        },
        flexWrap: 'wrap',
        gap: 1,
        p: 1,
        width: '100%',
      }}>
        {
          post.refs.map((ref, index) => (
            <ArtistProfileRef key={index}
              sx={{
                flex: 1,
                height: '128px',
              }}
              artist={ref} />
          ))
        }
      </Box>}

      {!hideCommands && <Box sx={{
        display: 'flex',
        flex: '0 0 100%',
        justifyContent: 'space-between',
        borderBottom: 'var(--border)',
        p: 1,
      }}>
        <Box sx={{ 
          display: 'flex',
          alignItems: 'center',
        }}>
          {onPrivacyChange && !post.status && <Tooltip title={"Publicar para todos os usuários"}>
            <IconButton
              disabled={!!post.status}
              onClick={() => onPrivacyChange(post)}>
              <VisibilityOffIcon />
            </IconButton>
          </Tooltip>}
          {onPrivacyChange && <Typography variant="caption" sx={{ pr: 2 }}>{post.status === 'pending' ?
            <Alert sx={{ py: 0 }} severity="warning">Aguardando moderação de conteúdo.</Alert> : post.status === 'approved' ?
              <Alert sx={{ py: 0 }} severity="success">Todos os usuários.</Alert> :
              <Alert sx={{ py: 0 }} severity="info">Somente assinantes.</Alert>}</Typography>}
        </Box>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
        }}>
          {onDelete && <IconButton onClick={() => onDelete(post)} ><DeleteOutlineOutlinedIcon /></IconButton>}
          {queryKey && <IconButton color='primary' onClick={() => {
            mutateBookmark.mutate(post)
          }}>
            {post.bookmarked ? <BookmarkOutlinedIcon /> : <BookmarkBorderOutlinedIcon />}
          </IconButton>}
        </Box>
      </Box>}
    </ListItem>
  );
};

export default Post;
