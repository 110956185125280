import React from 'react';
import {  Box } from '@mui/material';
import emptyFeed from '../../assets/empty-feed.svg';

export default function EmptyFeed() {

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <img style={{
                margin: '30px auto',
                width: '64%',
                height: 'auto',
            }}
            onError={(event) => event.currentTarget.style.display='none'}
            src={emptyFeed} alt="Empty Feed" />
        </Box>
    )
}