import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Button, Box, useMediaQuery, CircularProgress, Backdrop } from '@mui/material';
import { getArtistByAccountId } from '../../services/collectionsService';
import { Breadcrumb } from '../../components/Breadcrumb';
import Header from '../../components/Header';
import { ProfileModel } from '../../types/ProfileModel';
import { toast } from 'react-toastify';
import MoreMenu from '../../components/MoreMenu';
import { IconButton, Tooltip, Typography } from "@mui/material";
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined'
import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined'
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import { follow, block } from "../../services/collectionsService";
import ArtistProfileTop from '../../components/ArtistProfileTop';
import PrivateTemplate from '../../components/PrivateTemplate';
import helpers from '../../utils/helpers';
import SubscriptionInfoModal from '../../components/SubscriptionInfoModal';
import VerifiedBadge from '../../components/VerifiedBadge';
import ArtistStats from '../../components/ArtistStats';
import ArtistExternalLinks from '../../components/ArtistExternalLinks';
import Suggestions from '../../components/Suggestions';
import ReportingModal from '../../components/ReportingModal';
import { report } from '../../services/collectionsService';
import ArtistTabs from './tabs';
import { useTranslation } from 'react-i18next';

const Artist: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: artist, isError, refetch } = useQuery({
    queryKey: ['artist', id],
    queryFn: () => getArtistByAccountId(id),
    enabled: !!id,
    retry: false
  });

  useEffect(() => {
    if (isError) {
      navigate('/404');
    }
  }, [isError]);

  const [topHeader, setTopHeader] = useState<boolean>(true)
  window.addEventListener('scroll', () => {
    if (window.scrollY > 200)
      setTopHeader(false)
    else if (window.scrollY <= 200)
      setTopHeader(true)
  })

  const followMutation = useMutation({
    mutationFn: follow,
    onMutate: async (payload: any) => {
      await queryClient.cancelQueries({ queryKey: ['artist', artist?.accountId] })
      const previousData = queryClient.getQueryData<ProfileModel>(['artist', artist?.accountId])
      queryClient.setQueryData<ProfileModel>(['artist', artist?.accountId], (oldData?: ProfileModel) => {
        if (oldData) {
          return { ...oldData, following: !artist?.following }
        }
      })
      return { previousData }
    },
    onError: (err: any, __, context: any) => {
      queryClient.setQueryData<ProfileModel>(['artist', artist?.accountId], context.previousData)
      if (err?.response?.data?.code === 'SUBSCRIPTION_ACTIVE') {
        toast.error('Você não pode deixar de seguir um perfil com assinatura ativa.\nCancele a assinatura primeiro.')
      }
    },
  })

  const handleBlock = async (artist?: ProfileModel) => {
    if (!artist) return;
    try {
      await block(artist.id)
      navigate('/')
    } catch (error: any) {
      if (error?.response?.data?.code === 'SUBSCRIPTION_ACTIVE') {
        toast.error('Você não pode bloquear um perfil com assinatura ativa.\nCancele a assinatura primeiro.')
      }
    }
  }

  const [subscriptionInfoStateModalOpen, setSubscriptionInfoModalOpen] = useState<boolean>(false)
  const handleSubscriptionInfo = (user: ProfileModel) => {
    setSubscriptionInfoModalOpen(true)
  }

  const handleConfirmSubscribe = () => {
    refetch();
    dispatchEvent(new Event("refetchArtistPosts"));
  }

  const handleConfirmCancelSubscription = () => {
    refetch();
  }

  const upMd = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  const [reportingModalOpen, setReportingModalOpen] = useState<boolean>(false);

  const handleConfirmReporting = async (reason?: string) => {
    if (!artist || !reason) return;
    try {
      await report({
        reason,
        reportedId: artist.id
      })
      toast.success('Pronto, recebemos o seu pedido e nossa equipe irá analisá-lo.');
      navigate('/')
    } catch (error: any) { }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PrivateTemplate>
      {artist && subscriptionInfoStateModalOpen && <SubscriptionInfoModal
        artist={artist}
        openState={[subscriptionInfoStateModalOpen, setSubscriptionInfoModalOpen]}
        onConfirmCancel={handleConfirmCancelSubscription}
        onConfirmSubscribe={handleConfirmSubscribe}
      />
      }
      {artist && reportingModalOpen && <ReportingModal
        openState={[reportingModalOpen, setReportingModalOpen]}
        onConfirm={handleConfirmReporting} />
      }
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!artist}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <Box sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          md: 'row'
        },
        flex: 1,
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1 0 640px',
          width: '100%',
          maxWidth: '640px',
          borderRight: 'var(--border)',
          visibility: !artist ? 'hidden' : 'visible'
        }}>
          <Header sx={(() => {
            return topHeader ? {
              top: '-56px',
              marginBottom: '-56px',
              color: 'white',
              background: 'transparent',
              borderBottom: 'solid 1px transparent'
            } : {
              top: 0,
              transition: 'top .2s'
            }
          })()}>
            <Breadcrumb title={
              <Box>
                <VerifiedBadge sx={{ m: 0, lineHeight: '19px' }} user={artist} hideAccountId />
                <ArtistStats user={artist} />
              </Box>
            } url='/'>
              <MoreMenu
                options={[
                  { name: 'copyLink', label: 'Copiar link do perfil', onClick: () => { navigator.clipboard.writeText(window.location.href); toast.success('Link copiado!') } },
                  { name: 'block', label: 'Bloquear', onClick: () => handleBlock(artist) },
                  { name: 'report', label: 'Denunciar', onClick: () => setReportingModalOpen(true) },
                ]}></MoreMenu>
            </Breadcrumb>
          </Header>
          <Box className='border-bottom-bold' >
            <Box sx={{ borderBottom: 'var(--border)' }}>
              <Box sx={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'center',
                flexDirection: 'column'
              }}>
                <ArtistProfileTop artist={artist} />
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', mt: -5, pr: 2, gap: 1 }}>
                  <Tooltip title="Message">
                    <IconButton sx={{ border: 'solid 1px', display: 'flex' }}
                      onClick={() => navigate(`/chats/${artist?.id}`)}
                      color='primary'
                    >
                      <MessageOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={artist?.following ? "Unfollow" : "Follow"}>
                    <IconButton sx={{ border: 'solid 1px', display: 'flex' }}
                      onClick={() => followMutation.mutate(artist?.id)}
                      color='primary'>
                      {artist?.following ? <PersonRemoveOutlinedIcon /> : <PersonAddOutlinedIcon />}
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box sx={{ padding: 2 }}>
                  <VerifiedBadge user={artist}
                    sx={{ fontWeight: '700', fontSize: '19px' }}
                    accountIdSx={{
                      fontSize: '16px',
                      fontWeight: 'normal',
                      color: 'secondary.light',
                    }}
                  />
                  <Typography variant="body1" sx={{ mt: 2 }}>{artist?.description}</Typography>
                  <ArtistExternalLinks artist={artist} />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className='border-bottom-bold' sx={{
            display: "flex",
            flexDirection: "column",
            padding: 2,
          }} >
            {!artist?.subscription && <Button size="large"
              variant="outlined"
              disabled
              sx={{
                borderRadius: '45px',
                width: '100%',
              }}>Indisponível</Button>}
            {artist?.subscription && <Button size="large"
              variant="outlined"
              sx={{
                borderRadius: '45px',
                width: '100%',
              }}
              onClick={() => handleSubscriptionInfo(artist)}>
              {artist.subscription?.type === 'subscription' ?
                `Assinar por ${helpers.toLocalMoney(artist.subscription?.price)}` :
                'Já sou assinante'}
            </Button>}
          </Box>
          {artist && <ArtistTabs artistId={artist?.id} imagesCount={artist?.imagesCount ?? 0} videosCount={artist?.videosCount ?? 0} />}
        </Box>
        {upMd && <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 320px',
        }}>
          <Box sx={{
            top: 0,
            position: 'sticky',
          }}>
            <Suggestions size={5} />
          </Box>
        </Box>}
      </Box>
    </PrivateTemplate>
  );
};

export default Artist;