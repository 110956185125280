import {
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Box,
    FormControlLabel,
    Switch,
    useMediaQuery
} from "@mui/material";
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../App";
import { changeDarkMode, syncUserState } from "../../services/userService";
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import FolderSpecialOutlinedIcon from '@mui/icons-material/FolderSpecialOutlined';
import { useMutation } from "@tanstack/react-query";
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import { useTranslation } from 'react-i18next';

type SettingsProps = {
    showSettings: boolean;
    showSettingsHandler: (showSettings: boolean) => void;
}

const Settings = ({ showSettings, showSettingsHandler }: SettingsProps) => {

    const navigate = useNavigate();
    const { currentUser, setCurrentUser } = useContext(UserContext);
    const upSm = useMediaQuery((theme: any) => theme.breakpoints.up('sm'));
    const { t } = useTranslation();

    const handleLogout = (e: any) => {
        e.preventDefault();
        localStorage.removeItem('current_user');
        setCurrentUser({ token: undefined, id: "" });
    }

    const changeDarkModeMutation = useMutation({
        mutationFn: changeDarkMode,
        onMutate: (darkMode: boolean) => {
            setCurrentUser({ ...currentUser, darkMode: darkMode });
            return { previousDarkModeValue: !darkMode };
        },
        onSuccess: (data) => {
            syncUserState(setCurrentUser);
        },
        onError: (error, variables, context) => {
            setCurrentUser({ ...currentUser, darkMode: context?.previousDarkModeValue });
        }
    });

    const handleDarkModeChange = (evt: React.MouseEvent<HTMLButtonElement>) => {
        evt.stopPropagation();
        changeDarkModeMutation.mutate(!currentUser.darkMode);
    }

    return (
        <Drawer
            anchor={upSm ? 'left' : 'right'}
            open={showSettings}
            onClose={() => showSettingsHandler(false)}
        >
            <Box
                sx={{ width: 250 }}
                role="presentation"
                onClick={() => showSettingsHandler(false)}
                onKeyDown={() => showSettingsHandler(false)}
            >
                <List>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/profile")} >
                            <ListItemIcon>
                                <AccountBoxRoundedIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('settings.myProfile')} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/settings/earnings")} >
                            <ListItemIcon>
                                <AccountBalanceRoundedIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('settings.myEarnings')} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/settings/subscriptions")} >
                            <ListItemIcon>
                                <FolderSpecialOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('settings.mySubscriptions')} />
                        </ListItemButton>
                    </ListItem>
                    {currentUser.verified !== 'approved' && <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/verify")} >
                            <ListItemIcon>
                                <VerifiedOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('settings.getVerified')} />
                        </ListItemButton>
                    </ListItem>}
                    <ListItem disablePadding>
                        <ListItemButton onClick={handleLogout}>
                            <ListItemIcon>
                                <LogoutOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('settings.logout')} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <FormControlLabel sx={{ gap: '8px' }} control={
                            <Switch checked={currentUser.darkMode} onClick={evt => handleDarkModeChange(evt)} />
                        } label={t('settings.darkMode')} />
                    </ListItem>
                </List>
            </Box>
        </Drawer>
    )
}

export default Settings;