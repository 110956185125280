import { Box, IconButton, SxProps, Theme } from "@mui/material";
import React from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";

export type BreadcrumbProps = {
    title?: string | React.ReactNode;
    children?: React.ReactNode;
    sx?: SxProps<Theme>;
    url?: string;
    hideButton?: boolean;
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ title, children, sx, url }: BreadcrumbProps) => {
    const navigate = useNavigate();

    return (
        <Box sx={{
         pr:2, pl:2,
         display: 'flex',
         flexGrow: 1,
         flexDirection: 'row',
         justifyContent: 'space-between',
         zIndex: 100, ...sx}}>
            {url && <Box sx={{ pt: 1, pb: 1, ml: -1, mr: 0.5, color: 'inherit'}}>
                <IconButton sx={{color: 'inherit'}} onClick={() => navigate(url)}>
                    <ArrowBackIcon />
                </IconButton>
            </Box>}
            <h1>{title}</h1>
            <Box sx={{gap: 2, 
                display: 'inline-flex', 
                alignItems: 'center',
                alignSelf: 'stretch',
                flexDirection: 'row',
                marginLeft: 'auto',
                whiteSpace: 'nowrap'}}>
                { children }
            </Box>
        </Box>
    )
}
