import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export type PixInfo = {
    qrCode: string;
    qrCodeUrl: string;
    expiresInSeconds: number;    
}

type QrCodeProps = {
    pixInfo: PixInfo;
    onCancel: () => void;
}

export default function QrCode({ 
    pixInfo, 
    onCancel }: QrCodeProps) {

    // a counter of 5 minutes to close the modal
	const [counter, setCounter] = useState(pixInfo.expiresInSeconds);

	// a function to decrease the counter every second
	useEffect(() => {
		const timer = setInterval(() => {
            if (counter <= 0) {
                clearInterval(timer);
                onCancel();
                return;
            }
			setCounter(counter - 1);
		}, 1000);
		return () => clearInterval(timer);
	}, [counter]);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: 2,
            gap: 2,
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Box>
                <Typography variant="h5">Escaneie o QR code</Typography>
                <Typography variant="body2">para pagar com PIX</Typography>
            </Box>
            <Box
                sx={{
                    width: '250px',
                    height: '250px',
                    objectFit: 'cover',
                    border: '2px solid #ff2faa',
                    backgroundImage: `url(${pixInfo?.qrCodeUrl})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                }}></Box>
            <Typography variant="body2">Tempo restante: {Math.floor(counter / 60)}:{(counter % 60).toString().padStart(2, '0')}</Typography>
            <Button variant="contained" 
                fullWidth
                startIcon={<ContentCopyIcon />}
                onClick={() => { navigator.clipboard.writeText(pixInfo?.qrCode); toast.success('Código copiado!') }}>
                PIX COPIA E COLA
            </Button>
            <Button variant="outlined" 
                fullWidth
                onClick={() => onCancel()}>
                CANCELAR
            </Button>
        </Box>
    )
}