import { Paper, BottomNavigation, BottomNavigationAction, Badge, Drawer } from "@mui/material"
import React, { useContext } from "react"
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import MessageRoundedIcon from '@mui/icons-material/MessageRounded';
import FolderSpecialOutlinedIcon from '@mui/icons-material/FolderSpecialOutlined';
import { useLocation, useNavigate } from "react-router-dom";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { UserContext } from "../../App";

const BottomNav = ({ showSettingsHandler }:
    { showSettingsHandler: (show: boolean) => void }) => {

    const navigate = useNavigate();
    const location = useLocation();

    const { currentUser, setCurrentUser } = useContext(UserContext);

    return (
        <Drawer
            anchor={'bottom'}
            variant="permanent"
            sx={{
            zIndex: 999,
            width: '100%',
            backgroundColor: 'background.default',
            display: { xs: !!location.pathname.match(/^\/chats\/\w+$/) ? 'none' : 'block', sm: 'none' },
        }} elevation={1}>
            <BottomNavigation showLabels>
                <BottomNavigationAction onClick={() => navigate('/')} label="Início" icon={<HomeRoundedIcon />} />
                <BottomNavigationAction onClick={() => navigate('/chats')} label="Mensagens" icon={
                    <Badge sx={{
                        '& .MuiBadge-badge': {
                            boxShadow: `0 0 0 2px #fff`,
                            minWidth: '12px',
                            height: '12px',
                            borderRadius: '50%',
                            backgroundColor: 'red',
                            visibility: currentUser.unreads?.length ? 'visible' : 'hidden'
                        },
                    }}
                        overlap="circular"
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        variant="dot">
                        <MessageRoundedIcon />
                    </Badge>
                } />
                <BottomNavigationAction onClick={() => navigate('/collections')} label="Coleções" icon={<FolderSpecialOutlinedIcon />} />
                <BottomNavigationAction onClick={() => showSettingsHandler(true)} label="Mais" icon={<MoreHorizIcon />} />
            </BottomNavigation>
        </Drawer>
    )
}

export default BottomNav