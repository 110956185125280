import React, { useEffect } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import CountrySelect from '../CountrySelect';
import helpers from '../../utils/helpers';
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PaymentMethodModel } from "../../types/PaymentMethodModel";
import { useForm } from 'react-hook-form';
import constants from '../../utils/constants';
import axios from 'axios';
import { CardRequest, PaymentRequest, CardForm, addPaymentMethod } from '../../services/settingsService';
import { toast } from 'react-toastify';

type PaymentMethodProps = {
    onCancel: () => void;
    onSuccess: (data: PaymentMethodModel) => void;
    paymentMethod?: PaymentMethodModel;
    setLoading: React.Dispatch<React.SetStateAction<number>>;
}

export default function PaymentMethod({ 
    onCancel, 
    onSuccess, 
    paymentMethod,
    setLoading
}: PaymentMethodProps) {
	const queryClient = useQueryClient();

    const defaultValues = {
		fullName: '',
		countryCode: 'BR',
		phone: '',
		billingAddress: {},
		card: {
			number: '',
			cvv: '',
			expMonth: '',
			expYear: '',
			holderName: '',
			holderDocument: '',
		},
	}

	const {
		register,
		handleSubmit,
		watch,
		setValue,
		formState: { errors }
	} = useForm<PaymentRequest>({
		mode: 'onSubmit',
		reValidateMode: 'onBlur',
		defaultValues: defaultValues
	});

	const addPaymentMethodMutation = useMutation({
		mutationFn: addPaymentMethod,
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ['paymentMethods'] });
            onSuccess(data);
            setLoading(prev => prev - 1);
		},
        onError: (error) => {
            setLoading(prev => prev - 1);
        }
	})

	const handleAddNewCard = async (data: PaymentRequest) => {

        setLoading(prev => prev + 1);

        try {
            const cardRequest = data.card as CardForm;
            const response = await axios.post(`${constants.PAGARME_API_URL}/tokens?appId=${constants.PAGARME_APP_ID}`,
                {
                    "type": "card",
                    "card": {
                        "number": cardRequest.number?.replace(/\D/g, ''),
                        "holder_name": cardRequest.holderName,
                        "holder_document": cardRequest.holderDocument.replace(/\D/g, ''),
                        "exp_month": parseInt(cardRequest.expMonth),
                        "exp_year": parseInt(cardRequest.expYear),
                        "cvv": cardRequest.cvv,
                    }
                });

            if (response.data.errors || response.status !== 200) {
                toast.error('Cartão inválido');
                return;
            }

            const card = response.data;

            const paymentMethod = {
                fullName: data.fullName,
                countryCode: data.countryCode,
                phone: data.phone?.replace(/\D/g, ''),
                billingAddress: {
                    zipCode: data.billingAddress?.zipCode?.replace(/\D/g, ''),
                    street: data.billingAddress?.street,
                    number: data.billingAddress?.number,
                    complement: data.billingAddress?.complement,
                    district: data.billingAddress?.district,
                    city: data.billingAddress?.city,
                    state: data.billingAddress?.state,
                    country: data.billingAddress?.country,
                },
                card: {
                    hash: card.id,
                    cvv: cardRequest.cvv,
                    holderName: cardRequest.holderName,
                    holderDocument: cardRequest.holderDocument.replace(/\D/g, ''),
                } as CardRequest
            }
            addPaymentMethodMutation.mutate(paymentMethod);
        } catch (error) {
            toast.error('Cartão inválido');
            setLoading(prev => prev - 1);
            return;
        }
	}

    const phoneValue = watch('phone');

	useEffect(() => {
		if (phoneValue) {
			setValue('phone', helpers.masks.phone(phoneValue));
		}
	}, [phoneValue])

	const cardNumberValue = watch('card.number');

	useEffect(() => {
		if (cardNumberValue) {
			setValue('card.number', helpers.masks.cardNumber(cardNumberValue));
		}
	}, [cardNumberValue])

	const cardExpMonthValue = watch('card.expMonth');

	useEffect(() => {
		if (cardExpMonthValue) {
			setValue('card.expMonth', helpers.masks.expMonth(cardExpMonthValue));
		}
	}, [cardExpMonthValue])

	const cardExpYearValue = watch('card.expYear');

	useEffect(() => {
		if (cardExpYearValue) {
			setValue('card.expYear', helpers.masks.expYear(cardExpYearValue));
		}
	}, [cardExpYearValue])

	const cardCvvValue = watch('card.cvv');

	useEffect(() => {
		if (cardCvvValue) {
			setValue('card.cvv', helpers.masks.cvv(cardCvvValue));
		}
	}, [cardCvvValue])


	const holderDocumentValue = watch('card.holderDocument');

	useEffect(() => {
		if (holderDocumentValue) {
			setValue('card.holderDocument', helpers.masks.cpf(holderDocumentValue));
		}
	}, [holderDocumentValue])


	const holderAddressValue = watch('billingAddress');

	useEffect(() => {
		if (holderAddressValue?.zipCode) {
			setValue('billingAddress', {
				...holderAddressValue,
				zipCode: helpers.masks.zipCode(holderAddressValue.zipCode),
			});
			if (holderAddressValue?.zipCode?.length === 9) {
				axios.get(`https://viacep.com.br/ws/${holderAddressValue?.zipCode.replace(/\D/g, '')}/json/`)
					.then((response) => {
						const data = response.data;
						if (data.erro) {
							setValue('billingAddress', {
								street: '',
								complement: '',
								district: '',
								city: '',
								state: '',
								country: '',
								zipCode: '',
								number: holderAddressValue.number
							});
							return;
						}
						setValue('billingAddress', {
							street: data.logradouro,
							complement: data.complemento,
							district: data.bairro,
							city: data.localidade,
							state: data.uf,
							country: 'BR',
							zipCode: data.cep,
							number: holderAddressValue.number
						})
					}).catch(() => {
						setValue('billingAddress', {
							street: '',
							complement: '',
							district: '',
							city: '',
							state: '',
							country: '',
							zipCode: '',
							number: holderAddressValue.number
						});
					});
			}
		}
	}, [holderAddressValue?.zipCode]);

	useEffect(() => {
		if (holderAddressValue?.number) {
			setValue('billingAddress', {
				...holderAddressValue,
				number: helpers.masks.addressNumber(holderAddressValue?.number),
			});
		}
	}, [holderAddressValue?.number]);

    return (
        <Box>
            <Typography variant="h5">Adicionar cartão</Typography>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                mt: 2,

            }} component="form" onSubmit={handleSubmit(values => handleAddNewCard(values))}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <TextField
                        label="Nome do pagador"
                        variant="outlined"
                        fullWidth
                        {...register('fullName', {
                            required: true,
                            minLength: 3,
                            maxLength: 100,
                            pattern: /^[a-zA-Z\s]*$/

                        })}
                    />
                </Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Box sx={{
                        flex: '1 0'
                    }}>
                        <CountrySelect defaultValue={'BR'} onChange={
                            (e, newValue) => setValue('countryCode', newValue?.value || 'BR')
                        } />
                    </Box>
                    <TextField
                        label="Telefone"
                        variant="outlined"
                        sx={{ flex: '1 0' }}
                        {...register('phone', {
                            required: true,
                            validate: {
                                validatePhone: (value) => {
                                    if (helpers.isValidPhone(value)) {
                                        return true;
                                    }
                                    return false;
                                }
                            }

                        })}
                    />
                </Box>
                <TextField
                    label="Número do cartão"
                    variant="outlined"
                    fullWidth
                    {...register('card.number', {
                        required: true,
                        minLength: 13,
                        maxLength: 19,
                        validate: {
                            validateCard: (value) => {
                                if (helpers.validateCard(value)) {
                                    return true
                                }
                                return false
                            }
                        }

                    })}
                />
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Typography
                        sx={{
                            flex: '1 1 2rem'
                        }}
                        variant="body1"><b>Validade</b></Typography>
                    <TextField
                        label="MM"
                        variant="outlined"
                        {...register('card.expMonth', {
                            required: true,
                            minLength: 2,
                            maxLength: 2,
                            pattern: /^[0-9]*$/

                        })}
                        sx={{
                            flex: '1 1 1rem'
                        }}
                    />
                    <TextField
                        label="AAAA"
                        variant="outlined"
                        {...register('card.expYear', {
                            required: true,
                            minLength: 4,
                            maxLength: 4,
                            pattern: /^[0-9]*$/,
                            validate: {
                                validateExpireDate: (value) => {
                                    if (helpers.validateExpireDate(cardExpMonthValue, value)) {
                                        return true;
                                    }
                                    return false;
                                }
                            }
                        })}
                        sx={{
                            flex: '1 1 1rem'
                        }}
                    />
                    <TextField
                        label="CVV"
                        variant="outlined"
                        {...register('card.cvv', {
                            required: true,
                            minLength: 3,
                            maxLength: 4,
                            pattern: /^[0-9]*$/

                        })}
                        sx={{
                            flex: '1 1 1rem'
                        }}
                    />
                </Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <TextField
                        label="Nome no cartão"
                        variant="outlined"
                        sx={{ flex: '1 0' }}
                        {...register('card.holderName', {
                            required: true,
                            minLength: 3,
                            maxLength: 100,
                            pattern: /^[a-zA-Z\s]*$/
                        })}
                    />
                    <TextField
                        label="CPF"
                        variant="outlined"
                        sx={{ flex: '1 0' }}
                        {...register('card.holderDocument', {
                            required: true,
                            validate: {
                                validateCPF: (value) => {
                                    if (helpers.isValidCpf(value)) {
                                        return true;
                                    }
                                    return false;
                                }
                            }

                        })}
                    />
                </Box>
                {!constants.IS_PRODUCTION && <Box sx={{ display: 'flex', gap: 2 }}>
                    <TextField
                        label="CEP"
                        variant="outlined"
                        sx={{ flex: '1 0' }}
                        {...register('billingAddress.zipCode', {
                            required: true

                        })}
                    />
                    <TextField
                        label="Número"
                        variant="outlined"
                        sx={{ flex: '1 0' }}
                        {...register('billingAddress.number', {
                            required: true

                        })}
                    />
                </Box>}
                {!constants.IS_PRODUCTION && <TextField
                    label="Address"
                    variant="outlined"
                    fullWidth
                    value={`${holderAddressValue?.street || ''} ${holderAddressValue?.district || ''} ${holderAddressValue?.city || ''} ${holderAddressValue?.state || ''}`}
                    disabled
                />}
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                    <Button size="small" autoFocus onClick={() => onCancel()}>
                        VOLTAR
                    </Button>
                    <Button
                        size="small"
                        type="submit"
                        color="primary" variant="contained">
                        PAGAR
                    </Button>
                </Box>
            </Box>
        </Box>
    );

}