import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { recoveryChangePassword, RecoveryChangePasswordRequest } from '../../../services/userService';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import Copyright from '../../../components/Copyright';
import { UserContext } from '../../../App';
import { useContext } from 'react';

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
    palette: {
      primary: {
        main: '#ff2faa',
        light: 'rgba(252, 215, 237, 0.5)',
      }
    },
    typography: {
      fontFamily: 'Roboto, sans-serif',
    },
    components: {
      MuiLink: {
        styleOverrides: {
          root: {
            color: 'inherit',
            textDecoration: 'none',
            fontSize: '1rem',
            '&:hover': {
              textDecoration: 'underline',
            },
          },
        },
      },
    },
  });

export default function ResetChangePassword() {

    const location = useLocation()
    const navigate = useNavigate()
    const { currentUser, setCurrentUser } = useContext(UserContext);
    const code = location.pathname.split('/').pop();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<RecoveryChangePasswordRequest>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
        defaultValues: {
            code: code || ''
        }
    });

    const { mutate: resetPasswordHandler } = useMutation({
        mutationFn: recoveryChangePassword,
        onSuccess: (data) => {
            setCurrentUser(data);
          }
    });

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                    <Box sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Redefinir Senha
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit(values => resetPasswordHandler(values))} sx={{ mt: 3 }}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <FormControlLabel sx={{ visibility: 'hidden' }}
                                        control={<Checkbox value="allowExtraEmails" color="primary" />}
                                        label="I want to receive inspiration, marketing promotions and updates via email."
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="email"
                                        label="E-mail"
                                        autoComplete="off"
                                        error={!!errors.email}
                                        helperText={errors.email?.message}
                                        {...register('email', {
                                            required: 'Digite seu e-mail',
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: 'E-mail inválido',
                                            },
                                        })}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                    required
                                    fullWidth
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="new-password"
                                        error={!!errors.password}
                                        helperText={errors.password?.message}                    
                                        {...register('password', {
                                            required: 'Digite sua senha',
                                            minLength: {
                                            value: 8,
                                            message: 'Senha deve ter no mínimo 8 caracteres',
                                            },
                                            pattern: {
                                            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\S]{8,}$/,
                                            message: 'Senha deve ter no mínimo 1 letra maiúscula, 1 minúscula e 1 número',
                                            },
                                        })}
                                    />
                                </Grid>
                            </Grid>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Alterar
                            </Button>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Link href="/login" variant="body2">
                                        Cancelar
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                <Copyright sx={{ mt: 5 }} />
            </Container>
        </ThemeProvider>
    );
}