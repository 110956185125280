import React, { useContext, useEffect, useState } from "react";
import Loading from "../components/Loading";
import { api, unhandledApi } from "../services/api";
import { toast } from "react-toastify";
import { UserContext } from "../App";
import helpers from "../utils/helpers";
import constants from "../utils/constants";
import { currentApiEnv } from "../services/userService";

const ApiInterceptor = () => {

    const [isLoading, setIsLoading] = useState(false);
    const { currentUser, setCurrentUser } = useContext(UserContext);

    useEffect(() => {
                
        api.interceptors.request.clear()
        api.interceptors.response.clear()

        unhandledApi.interceptors.request.clear()
        unhandledApi.interceptors.response.clear()

        unhandledApi.interceptors.request.use(
            r => {
              r.headers.Authorization = `Bearer ${currentUser.token}`;
              return r;
            }
            , e => e)

        unhandledApi.interceptors.response.use(
            r => r,
            e => {
                if(e.response?.status === 403 || e.response?.status === 401){
                    localStorage.removeItem('current_user');
                    setCurrentUser({ token: undefined, id: ""});
                    return;
                }            
                return Promise.reject(e);
            })

        api.interceptors.request.use(
            r => {
              r.headers.Authorization = `Bearer ${currentUser.token}`;
              setIsLoading(true)
              return r;
            }
            , e => e)

        api.interceptors.response.use(
            r => {
                setIsLoading(false)
                return r;
            }
            , e => {
                setIsLoading(false)
                if(e.response?.status === 403 || e.response?.status === 401){
                    localStorage.removeItem('current_user');
                    setCurrentUser({ token: undefined, id: ""});
                    return;
                }

                toast.error(constants.MESSAGES[e.response?.data?.code] || 'Ops...')              
                return Promise.reject(e);
            })

    }, [currentUser])

    useEffect(() => {
        currentApiEnv().then((data) => {
            localStorage.setItem('env', data?.environment);
        });
    }, []);    

    return (
        <Loading isLoading={isLoading}/>
    )

}

export default ApiInterceptor