import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../../App';
import { Button, FormHelperText, Box, TextField, FormControl, Typography, } from '@mui/material';
import { Breadcrumb } from '../../components/Breadcrumb';
import { useForm } from 'react-hook-form';
import { updateProfile, deleteProfile } from '../../services/userService';
import { useMutation } from '@tanstack/react-query';
import ArtistProfileTop from '../../components/ArtistProfileTop';
import { toast } from 'react-toastify';
import { checkAccountIdExists } from '../../services/userService';
import { UserModel } from "../../types/UserModel";
import Header from '../../components/Header';
import { ProfileModel } from '../../types/ProfileModel';
import PrivateTemplate from '../../components/PrivateTemplate';
import helpers from '../../utils/helpers';
import { Link as RouterLink } from 'react-router-dom';
import StandardModal from '../../components/StandardModal';
import AssetUploader from '../../components/AssetUploader';

const EditProfile: React.FC = () => {
    const { currentUser, setCurrentUser } = useContext(UserContext);
    const [artist, setArtist] = React.useState<UserModel>({ ...currentUser });
    const location = useLocation()
    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors }
    } = useForm<ProfileModel>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
        defaultValues: artist
    });

    const accountIdValue = watch('accountId');

    useEffect(() => {
        if (!accountIdValue) return;
        setValue('accountId', helpers.masks.accountId(accountIdValue));
    }, [accountIdValue]);

    const { mutate: onSubmit } = useMutation({
        mutationFn: (values: ProfileModel) => updateProfile({
            ...values,
            avatarId: artist.avatar?.assetId,
            bannerId: artist.banner?.assetId
        }),
        onSuccess: (user) => {
            setCurrentUser(user);
            toast.success('Perfil atualizado!');
            navigate('/profile');
        }
    })

    useEffect(() => {
        if (location.state?.hash) {
            console.log(location.state?.hash)
        } else {
            window.scrollTo(0, 0);
        }
    }, [location]);

    const [topHeader, setTopHeader] = useState<boolean>(true);

    window.addEventListener('scroll', () => {
        if (window.scrollY > 200)
            setTopHeader(false)
        else if (window.scrollY <= 200)
            setTopHeader(true)
    });

    const descriptionValue = watch('description');

    useEffect(() => {
        if (!descriptionValue) return;
        setValue('description', helpers.moderateContent(descriptionValue));
    }, [descriptionValue]);

    const [deleteProfileOpen, setDeleteProfileOpen] = useState<boolean>(false);

    const handleDeleteProfile = () => {
        deleteProfile().then(() => {
            localStorage.removeItem('current_user')
            setCurrentUser({ token: undefined, id: "" });
        }).catch(() => { });
    }

    const avatarClick = () => {
        dispatchEvent(new Event("avatar_selectMedia"))
    }

    const bannerClick = () => {
        dispatchEvent(new Event("banner_selectMedia"))
    }

    const onAvatarChange = (asset: any) => {
        if (asset) {
            setArtist({ ...artist, avatar: asset });
        }
    }

    const onBannerChange = (asset: any) => {
        if (asset) {
            setArtist({ ...artist, banner: asset });
        }
    }

    return (
        <PrivateTemplate>
            <StandardModal openState={[deleteProfileOpen, setDeleteProfileOpen]} onClose={() => setDeleteProfileOpen(false)}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    p: 2
                }}>
                    <Typography component="h1" variant="h5">Excluir minha conta</Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Você perderá todos os seus dados e não poderá recuperá-los.</Typography>
                    <Typography variant="body1"><b>Criadores:</b> Se você tiver assinantes ativos, eles poderão ver o seu perfil até o término da assinatura. Você irá receber seus pagamentos referente a esse período e então seus dados serão excluídos definitivamente.</Typography>
                    <Typography variant="body1"><b>Assinantes:</b> Se você tiver assinaturas ativas elas serão canceladas imediatamente e você não será reembolsado, seus dados serão excluídos definitivamente.</Typography>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        gap: 2,
                        pt: 2
                    }}>
                        <Button
                            variant="outlined"
                            onClick={() => setDeleteProfileOpen(false)}
                            sx={{ minWidth: '33%' }}>
                            Cancelar
                        </Button>
                        <Button variant="contained"
                            type='button'
                            onClick={handleDeleteProfile}
                            sx={{ minWidth: '33%' }}>
                            Excluir
                        </Button>
                    </Box>
                </Box>
            </StandardModal>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                maxWidth: '640px',
                borderRight: 'var(--border)'
            }}>
                <Header sx={(() => {
                    return topHeader ? {
                        top: '-56px',
                        marginBottom: '-56px',
                        color: 'white',
                        background: 'transparent',
                        borderBottom: 'solid 1px transparent'
                    } : {
                        top: 0,
                        transition: 'top .2s'
                    }
                })()}>
                    <Breadcrumb title="EDIT PROFILE" url='/profile' />
                </Header>                
                <ArtistProfileTop artist={artist}
                    onAvatarButtonClick={avatarClick}
                    onBannerButtonClick={bannerClick}
                />
                <Box sx={{
                    display: 'flex',
                    width: '100%',
                    position: 'relative',
                    justifyContent: 'flex-end', mt: -5, pr: 2, gap: 1
                }}>
                    <Button component={RouterLink} to="/profile"
                        variant="outlined"
                        sx={{
                            borderRadius: '20px',
                        }}
                        onClick={() => { }}
                        color='primary'
                    >
                        Cancelar
                    </Button>
                    <Button variant="contained"
                        sx={{
                            borderRadius: '20px',
                        }}
                        onClick={handleSubmit(values => onSubmit(values))}
                        color='primary'
                    >
                        SALVAR
                    </Button>
                </Box>
                <Box className='border-bottom-bold'
                    component="form"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        mt: 1
                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 2,
                        padding: 2,
                        borderBottom: 'var(--border)'
                    }}>
                        <FormControl fullWidth>
                            <TextField id="displayName" label="Display Name"
                                error={!!errors.displayName}
                                helperText={errors.displayName?.message}
                                {...register('displayName', { required: 'Digite seu nome artístico' })}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField id="accountId" label="Account ID"
                                error={!!errors.accountId}
                                helperText={errors.accountId?.message}
                                {...register('accountId', {
                                    required: 'Digite seu ID',
                                    minLength: {
                                        value: 5,
                                        message: 'Mínimo de 5 caracteres'
                                    },
                                    validate: {
                                        accountIdExists: async (value) => {
                                            const exists = await checkAccountIdExists(value)
                                            if (exists) {
                                                return 'Account ID já sendo utilizado'
                                            } else {
                                                return true
                                            }
                                        }
                                    }
                                })
                                }
                            />
                        </FormControl>
                        <FormControl sx={{ flexGrow: '4' }}>
                            <TextField id="instagramId" label="Instagram"
                                error={!!errors.instagramId}
                                helperText={errors.instagramId?.message}
                                {...register('instagramId', { required: false })}
                            />
                        </FormControl>
                        <FormControl sx={{ flexGrow: '4' }}>
                            <TextField id="twitterId" label="Twitter"
                                error={!!errors.twitterId}
                                helperText={errors.twitterId?.message}
                                {...register('twitterId', { required: false })}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField id="description" label="About You" multiline rows={5} maxLength={300}
                                error={!!errors.description}
                                helperText={errors.description?.message}
                                {...register('description', { required: false, maxLength: 300 })}
                            />
                            <FormHelperText style={{ alignSelf: 'end' }} >{descriptionValue?.length || 0} / 300</FormHelperText>
                        </FormControl>
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'end',
                    padding: 2,
                }}>
                    <Button variant="outlined" onClick={() => setDeleteProfileOpen(true)}>Excluir minha conta</Button>
                </Box>
                <AssetUploader name='avatar' accept='avatar' onChange={onAvatarChange} hidePreview hideLoading/>
                <AssetUploader name='banner' accept='banner' onChange={onBannerChange} hidePreview hideLoading/>
                <Box sx={{height: '100px' // botton navigator spacing           
                }} ></Box>
            </Box>
        </PrivateTemplate>
    );
};

export default EditProfile;
