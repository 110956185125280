import React from 'react';
import { Avatar, Box, Button, IconButton, Link, TableCell, TableRow, Typography } from '@mui/material'
import AdminTemplate from '../components/AdminTemplate'
import EnhancedTable, { DataRow, HeadCell } from '../../../components/EnhancedTable';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import { UserModel } from '../types/UserModel';
import helpers from '../../../utils/helpers';
import { BASE_IMAGE_URL } from '../../../utils/constants';
import axios from 'axios';
import StandardModal from '../../../components/StandardModal';
import { useState } from 'react';
import { ProfileModel } from '../types/ProfileModel';
import { toast } from 'react-toastify';
import { api } from '../../../services/api';

interface Data extends UserModel, DataRow { }

export default function UserVerification() {

    const [detailsOpen, setDetailsOpen] = useState(false);
    const [selected, setSelected] = useState<ProfileModel | undefined>();

    const { data: rows } = useQuery({
        queryKey: ['users'],
        queryFn: async () => {
            const { data } = await api.get<UserModel[]>(`/main/users`);
            return data as Data[];
        },
    });

    const headCells: readonly HeadCell<Data>[] = [
        {
            id: 'avatar',
            numeric: false,
            disablePadding: false,
            label: 'Avatar',
        },
        {
            id: 'accountId',
            numeric: false,
            disablePadding: false,
            label: 'Account',
        },
        {
            id: 'displayName',
            numeric: false,
            disablePadding: false,
            label: 'Name',
        },
        {
            id: 'verified',
            numeric: false,
            disablePadding: false,
            label: 'Verified',
        },
        {
            id: 'disabled',
            numeric: false,
            disablePadding: false,
            label: 'Disabled',
        },
        {
            id: 'createdAt',
            numeric: false,
            disablePadding: false,
            label: 'Created at',
        },
        {
            id: 'id',
            numeric: true,
            disablePadding: false,
            label: '',
        },
    ];

    function getTableRow(row: Data,
        isItemSelected: boolean,
        labelId: string,
        handleClick: (event: React.MouseEvent<unknown>, id: string) => void) {
        return (<TableRow
            hover
            onClick={(event) => handleClick(event, row.id)}
            // aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            // selected={isItemSelected}
            sx={{ cursor: 'pointer' }}
        >
            <TableCell>
                <Avatar
                    alt={row.displayName}
                    src={`${BASE_IMAGE_URL}/${row.avatar?.small?.path}`}
                    sx={{ width: 40, height: 40 }}
                />
            </TableCell>
            <TableCell>{row.accountId}</TableCell>
            <TableCell>{row.displayName}</TableCell>
            <TableCell>{row.verified}</TableCell>
            <TableCell>{row.disabled}</TableCell>
            <TableCell>{helpers.timeStampToFormatedDate(row.createdAt)}</TableCell>
            <TableCell align="right" >
                <IconButton
                    aria-label="delete"
                    onClick={() => {
                        setSelected(row);
                        setDetailsOpen(true);
                    }}
                >
                    <BorderColorRoundedIcon />
                </IconButton>
            </TableCell>
        </TableRow>
        )
    }

    const veredict = (params: { id?: string, approved: boolean }) => {
        return api.put(`/main/users/${params.id}`, { status: params.approved ? 'approved' : 'rejected' })
    }

    const queryClient = useQueryClient();

    const veredictMutation = useMutation({
        mutationFn: veredict,
        onMutate: async (variables: { id?: string; approved: boolean; }) => {
            const { id, approved } = variables;
            await queryClient.cancelQueries({ queryKey: ['users'] });
            const previousData = queryClient.getQueryData<Data[]>(['users']);
            queryClient.setQueryData<Data[]>(['users'], (old) => {
                return old?.filter((row) => row.id != id);
            });

            return { previousData }
        },
        onError: (error, variables, context) => {
            queryClient.setQueryData<Data[]>(['users'], context?.previousData);
        },
        onSuccess: (data, variables, context) => {
            toast.success('Veredict saved');
        },
    });

    return (
        <AdminTemplate>
            <StandardModal openState={[detailsOpen, setDetailsOpen]} onClose={() => setDetailsOpen(false)}
                sx={{
                    maxWidth: 800,
                    p: 2
                }}>
                <Box sx={{
                    px: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1
                }}>
                    <Avatar src={`${BASE_IMAGE_URL}/${selected?.avatar?.small?.path}`} sx={{ width: 80, height: 80 }} />
                    <Typography variant="h5">{selected?.displayName} · {selected?.accountId}</Typography>
                    <Typography variant="body1">{selected?.id}</Typography>
                    <Typography variant="body1">{selected?.email}</Typography>
                    <Typography variant="body1">Created at {helpers.timeStampToFormatedDate(selected?.createdAt)} ·
                        Verified {selected?.verified}</Typography>
                    <Typography variant="body2">{selected?.description}</Typography>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}>
                        <img src={selected?.idFront?.original?.path} style={{ maxWidth: 240, maxHeight: 360 }} />
                        <img src={selected?.idBack?.original?.path} style={{ maxWidth: 240, maxHeight: 360 }} />
                        <img src={selected?.holdingId?.poster?.original?.path} style={{ maxWidth: 240, maxHeight: 360 }} />
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        gap: 2,
                    }}>
                        <Button variant="outlined" color="primary" onClick={
                            () => {
                                veredictMutation.mutate({ id: selected?.id, approved: false });
                                setDetailsOpen(false);
                            }}>Reject</Button>
                        <Button variant="outlined" color="primary" onClick={
                            () => {
                                veredictMutation.mutate({ id: selected?.id, approved: true });
                                setDetailsOpen(false);
                            }}>Approve</Button>
                    </Box>
                </Box>
            </StandardModal>
            <Box sx={{
                p: 2,
            }}>
                <Typography variant="h1">Users Verification</Typography>
                <Box sx={{ mt: 2 }}>
                    {rows && <EnhancedTable
                        headCells={headCells}
                        getTableRow={getTableRow}
                        rows={rows}
                        title=""
                        dense
                    />}
                </Box>
            </Box>
        </AdminTemplate>
    )
}