import axios from 'axios';
import * as constants from '../utils/constants';

export const api = axios.create({
  baseURL: constants.API_URL,
});

export const unhandledApi = axios.create({
  baseURL: constants.API_URL,
});
