import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Box,
  Typography,
  Tooltip,
  Chip,
  Stepper,
  StepContent,
  Step,
  StepLabel
} from '@mui/material';
import { Breadcrumb } from '../../components/Breadcrumb';
import { useMutation } from '@tanstack/react-query';
import Header from '../../components/Header';
import PrivateTemplate from '../../components/PrivateTemplate';
import AssetUploader from '../../components/AssetUploader';
import { AssetModel } from '../../types/AssetModel';
import idFront from '../../assets/id-front.svg';
import idBack from '../../assets/id-back.svg';
import holdingId from '../../assets/holding-id.svg';
import { InsertPhotoOutlined } from '@mui/icons-material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { getVerified, syncUserState } from '../../services/userService';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../App';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useTranslation } from 'react-i18next';

const Verify: React.FC = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentUser, setCurrentUser } = useContext(UserContext);

  const [idFrontAsset, setIdFrontAsset] = useState<AssetModel | undefined>()
  const [idBackAsset, setIdBackAsset] = useState<AssetModel | undefined>()
  const [holdingIdAsset, setHoldingIdAsset] = useState<AssetModel | undefined>()

  const submitVerify = useMutation({
    mutationFn: () => {
      return getVerified({
        idFront: idFrontAsset?.assetId,
        idBack: idBackAsset?.assetId,
        holdingId: holdingIdAsset?.assetId

      })
    },
    onMutate: () => {
      setCurrentUser({ ...currentUser, verified: 'pending' });
    },
    onSuccess: (data) => {
      syncUserState(setCurrentUser);
    },
    onError: (error) => {
      setCurrentUser({ ...currentUser, verified: undefined });
    }
  });

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PrivateTemplate>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        borderRight: 'var(--border)',
      }}>
        <Header>
          <Breadcrumb title={t('getVerified.title')} url='/' />
        </Header>
        {!currentUser?.verified || currentUser?.verified === 'rejected' ? <Box sx={{ p: 2 }}>
          <Typography component="h1" variant="h6">Perfil Verificado</Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>Para receber o selo de "perfil verificado" você precisa nos enviar a foto do seu documento RG ou CNH (frente e verso) e um video segurando o documento.</Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>A equipe da MeuEncontro irá verificar criteriosamente os seus documentos e então irá aprovar ou recusar o seu pedido, faremos isso através do e-mail support@meuencontro.com. Em caso de dúvida você pode encontrar informações úteis a respeito das nossas <a href="https://meuencontro.com/privacy">Políticas de Privacidade</a> e na nossa <a href="https://meuencontro.com/transparency-center">Central de Transparência</a>.</Typography>
          <Box sx={{ mt: 2 }}>
            <Stepper activeStep={activeStep}>
              <Step key={0}>
                <StepLabel>ID frente</StepLabel>
              </Step>
              <Step key={1}>
                <StepLabel>ID verso</StepLabel>
              </Step>
              <Step key={2}>
                <StepLabel>Video</StepLabel>
              </Step>
            </Stepper>

            {activeStep == 0 && <Box sx={{
              my: 2,
              justifyContent: 'center',
              display: 'flex',
              flexWrap: 'wrap',
            }}>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}>
                <Box sx={{
                  overflow: 'hidden',
                  height: '160px',
                  width: '200px',
                  backgroundImage: `url(${idFront})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}>
                  <AssetUploader
                    name="idFrontUploader"
                    accept='image'
                    defaultValue={idFrontAsset}
                    onChange={(asset?: AssetModel) => setIdFrontAsset(asset)} />
                </Box>
                {!idFrontAsset && <Tooltip title="Selecione a imagem do seu documento de identidade">
                  <Chip onClick={() => dispatchEvent(new Event("idFrontUploader_selectMedia"))} label={
                    <Typography variant="body2">
                      <InsertPhotoOutlined /> Selecionar documento
                    </Typography>
                  } />
                </Tooltip>}
                {idFrontAsset && <Tooltip title="Remova a imagem do seu documento de identidade">
                  <Chip onClick={() => dispatchEvent(new Event("idFrontUploader_removeMedia"))} label={
                    <Typography variant="body2">
                      <HighlightOffIcon /> Remover documento
                    </Typography>
                  } />
                </Tooltip>}
              </Box>
              <Box sx={{ 
                display: 'flex', 
                flex: '1 1 100%', 
                justifyContent: 'space-between',
                mt: 2                
              }}>
                <Button
                  size='small'
                  disabled
                  onClick={handleBack}
                >
                  Voltar
                </Button>
                <Button
                  size='small'
                  disabled={!idFrontAsset}
                  variant="contained"
                  onClick={handleNext}
                >Próximo</Button>
              </Box>
            </Box>}

            {activeStep == 1 && <Box sx={{
              my: 2,
              justifyContent: 'center',
              display: 'flex',
              flexWrap: 'wrap',
            }}>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}>
                <Box sx={{
                  overflow: 'hidden',
                  height: '160px',
                  width: '200px',
                  backgroundImage: `url(${idBack})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}>
                  <AssetUploader
                    name="idBackUploader"
                    accept='image'
                    defaultValue={idBackAsset}
                    onChange={(asset?: AssetModel) => setIdBackAsset(asset)} />
                </Box>
                {!idBackAsset && <Tooltip title="Selecione a imagem do verso do seu documento de identidade">
                  <Chip onClick={() => dispatchEvent(new Event("idBackUploader_selectMedia"))} label={
                    <Typography variant="body2">
                      <InsertPhotoOutlined /> Selecionar documento
                    </Typography>
                  } />
                </Tooltip>}
                {idBackAsset && <Tooltip title="Remova a imagem do verso do seu documento de identidade">
                  <Chip onClick={() => dispatchEvent(new Event("idBackUploader_removeMedia"))} label={
                    <Typography variant="body2">
                      <HighlightOffIcon /> Remover documento
                    </Typography>
                  } />
                </Tooltip>}
              </Box>
              <Box sx={{ 
                display: 'flex', 
                flex: '1 1 100%', 
                justifyContent: 'space-between',
                mt: 2                
                }}>
                <Button
                  size='small'
                  onClick={handleBack}
                >
                  Voltar
                </Button>
                <Button
                  size='small'
                  disabled={!idFrontAsset || !idBackAsset}
                  variant="contained"
                  onClick={handleNext}
                >Próximo</Button>
              </Box>
            </Box>}

            {activeStep == 2 && <Box sx={{
              my: 2,
              justifyContent: 'center',
              display: 'flex',
              flexWrap: 'wrap',
            }}>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}>
                <Box sx={{
                  overflow: 'hidden',
                  height: '160px',
                  width: '200px',
                  backgroundImage: `url(${holdingId})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}>
                  <AssetUploader
                    name="holdingIdUploader"
                    accept='video'
                    defaultValue={holdingIdAsset}
                    onChange={(asset?: AssetModel) => setHoldingIdAsset(asset)} />
                </Box>
                {!holdingIdAsset && <Tooltip title="Selecione a imagem do vídeo segurando o seu documento de identidade">
                  <Chip onClick={() => dispatchEvent(new Event("holdingIdUploader_selectMedia"))} label={
                    <Typography variant="body2">
                      <InsertPhotoOutlined /> Selecionar video
                    </Typography>
                  } />
                </Tooltip>}
                {holdingIdAsset && <Tooltip title="Remova a imagem do vídeo segurando o seu documento de identidade">
                  <Chip onClick={() => dispatchEvent(new Event("holdingIdUploader_removeMedia"))} label={
                    <Typography variant="body2">
                      <HighlightOffIcon /> Remover video
                    </Typography>
                  } />
                </Tooltip>}
              </Box>
              <Box sx={{ 
                display: 'flex', 
                flex: '1 1 100%', 
                justifyContent: 'space-between',
                mt: 2,
              }}>
                <Button
                  size='small'
                  onClick={handleBack}
                >
                  Voltar
                </Button>
                <Button 
                  size='small'
                  variant="contained"
                  disabled={!idFrontAsset || !idBackAsset || !holdingIdAsset}
                  onClick={() => submitVerify.mutate()}>Concluir</Button>
              </Box>
            </Box>}

          </Box>
        </Box> :
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: '0 0 100%',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            px: '2em',
            mt: '4em',
            gap: '1em',
          }}>
            <Typography variant="h4">Aguardando aprovação</Typography>
            <TaskAltIcon sx={{ fontSize: '200px', color: 'primary.main', opacity: 0.5 }} />
            <Typography variant="body1" sx={{textAlign: 'center'}}>
              Parabéns, seus documentos foram enviados com sucesso e você está à apenas um passo de se tornar um criador de conteúdo verificado. Aguarde a análise da nossa equipe.
            </Typography>
            <Button 
              size='large'
              variant="contained" 
              onClick={() => navigate('/')}>Voltar para a página inicial</Button>
          </Box>}
          <Box sx={{height: '100px' // botton navigator spacing           
                }} ></Box>
      </Box>
    </PrivateTemplate>
  );
};

export default Verify;
