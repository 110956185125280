import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import { signin, SigninRequest, verifyEmail, verifyVerifyEmail } from '../../../services/userService';
import { UserContext } from '../../../App';
import { Grid, Link } from '@mui/material';
import OtpModal from '../../../components/OtpModal';
import { toast } from 'react-toastify';
import LandingWrapper from '../LandingWrapper';
import SignUp from './signUp';
import { useTranslation } from 'react-i18next';

export default function SignIn() {

  const [step, setStep] = React.useState(0);
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const [tempToken, setTempToken] = React.useState('' as string);
  const [pendingUserState, setPendingUserState] = React.useState(currentUser);
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SigninRequest>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
  });

  const { mutate } = useMutation({
    mutationFn: signin,
    onSuccess: (data) => {
      if (data.emailVerified) {
        setCurrentUser(data);
        return
      }
      setPendingUserState(data);
      verifyEmail(data.token).then(() => {
        setVerifyEmailOpen(true);
        setTempToken(data.token);
      })
        .catch((err) => {
          toast.error(err.response?.data?.code || t('auth.login.errorSendingVerificationEmail'))
        });
    }
  })

  const [verifyEmailOpen, setVerifyEmailOpen] = React.useState(false);

  const handleConfirmEmail = (code: string) => {
    verifyVerifyEmail(tempToken, code).then((data) => {
      setVerifyEmailOpen(false);
      setCurrentUser({ ...pendingUserState, emailVerified: true });
    }).catch((err) => {
      toast.error(err.response?.data?.code || t('auth.login.errorVerifyingEmail'))
    });
  }

  return (
    <LandingWrapper>
      {step === 0 && <Box>
        <OtpModal title={t('auth.login.verifyEmail')}
          openState={[verifyEmailOpen, setVerifyEmailOpen]}
          onConfirm={handleConfirmEmail}
        />
        <Typography component="h1" variant="h5">
          {t('auth.login.title')}
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit(data => mutate(data))} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            variant="outlined"
            label={t('auth.login.email')}
            id="email"
            autoComplete="off"
            error={!!errors.email}
            helperText={errors.email?.message}
            placeholder={t('auth.login.emailPlaceholder')}
            autoFocus
            {...register('email', {
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: t('auth.login.emailInvalid'),
              },
            })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label={t('auth.login.password')}
            type="password"
            id="password"
            autoComplete="current-password"
            error={!!errors.password}
            helperText={errors.password?.message}
            {...register('password', {
              required: true,
            })}
          />
          <Grid item xs={12}>
            <Typography component="span" sx={{ fontSize: "0.7rem" }}>{t('auth.login.youAgree')}
              <Link href="/terms" sx={{ fontSize: "0.7rem" }}>{t('auth.login.terms')}</Link>{t('common.and')}
              <Link href="/privacy" sx={{ fontSize: "0.7rem" }}>{t('auth.login.policyAndPrivacy')}</Link>{t('common.and')}
              {t('auth.login.confirmYouAreOlder')}</Typography>
          </Grid>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mt: 2,
            gap: 2,
          }}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
            >
              {t('auth.login.loginButton')}
            </Button>
            <Box sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}>
              <Link href="/reset-password">
                {t('auth.login.forgotPasswordButton')}
              </Link>
              <Link href="/signup" onClick={(e) => { e.preventDefault(); setStep(1) }}>
                {t('auth.login.signUpButton')}
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>}
      {step === 1 && <SignUp onCancel={() => setStep(0)} />}
    </LandingWrapper>
  );
}