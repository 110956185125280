import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  FormHelperText,
  Box,
  TextField,
  IconButton
} from '@mui/material';
import { Breadcrumb } from '../../components/Breadcrumb';
import Header from '../../components/Header';
import { createPost } from '../../services/collectionsService';
import { InsertPhotoOutlined } from '@mui/icons-material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PrivateTemplate from '../../components/PrivateTemplate';
import { AssetModel } from '../../types/AssetModel';
import AssetUploader from '../../components/AssetUploader';
import helpers from '../../utils/helpers';

const Create: React.FC = () => {
  const navigate = useNavigate();
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [processing, setProcessing] = useState<boolean>(false)
  const [content, setContent] = useState<string>()
  const [asset, setAsset] = useState<AssetModel | undefined>()

  const savePost = async () => {
    if (!content || !asset?.assetId)
      return;

    try {
      await createPost({
        content: content,
        assets: [asset.assetId]
      });
      navigate('/');
    } catch (error) { }

  }

  useEffect(() => {

    if (!content) return;
    setContent(helpers.moderateContent(content).substring(0, 300));

  }, [content]);


  const clear = () => {
    setContent('');
    dispatchEvent(new Event("assetUploader_removeMedia"));
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PrivateTemplate>
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, borderRight: 'var(--border)', maxWidth: '640px' }}>
        <Header>
          <Breadcrumb title="NOVA PUBLICAÇÃO" url='/'>
            {(content?.length || asset) && <Button variant="outlined" onClick={() => clear()}>Limpar</Button>}
            <Button variant="contained"
              onClick={() => savePost()}
              disabled={processing || !content || !asset} >Post</Button>
          </Breadcrumb>
        </Header>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <TextField id="new-post" multiline placeholder='Criar nova publicação...'
            variant="standard"
            InputProps={{
              disableUnderline: true
            }}
            inputRef={(input) => input && input.focus()}
            onFocus={(e) =>
              e.currentTarget.setSelectionRange(
                e.currentTarget.value.length,
                e.currentTarget.value.length
              )}
            sx={{ p: 2, mb: 0, mt: 0 }}
            value={content}
            onChange={(e) => setContent(e.target.value)} />
          <AssetUploader
            name="assetUploader"
            onUploadStarted={() => setProcessing(true)}
            onUploadProgress={(progress) => setUploadProgress(progress)}
            onUploadFinished={() => setProcessing(false)}
            onChange={(asset?: AssetModel) => setAsset(asset)}
          />
          {processing && <Box sx={{ padding: 2 }}>
            <h2>Uploading ({uploadProgress})%</h2>
            <p>Enviar videos longos pode demorar um pouco...</p>
          </Box>}
        </Box>
        <Box sx={{
          p: 1,
          display: 'flex',
          borderBottom: 'solid 1px #eee',
          justifyContent: 'space-between'
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <IconButton color='primary' disabled={!!asset} onClick={() => dispatchEvent(new Event("assetUploader_selectMedia"))}>
              <InsertPhotoOutlined />
            </IconButton>
            {asset && <IconButton color='primary' onClick={() => dispatchEvent(new Event("assetUploader_removeMedia"))}>
              <HighlightOffIcon />
            </IconButton>
            }
          </Box>
          <FormHelperText sx={{ alignSelf: 'center' }}>{content?.length || 0} / 300</FormHelperText>
        </Box>
      </Box>
    </PrivateTemplate>
  );
};

export default Create;
