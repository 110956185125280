
export const BASE_IMAGE_URL = process.env.REACT_APP_IMAGE_BASE_URL
export const API_URL = process.env.REACT_APP_API_URL
export const WSS_URL = process.env.REACT_APP_WSS_URL
export const PAGARME_APP_ID = process.env.REACT_APP_PAGARME_APP_ID
export const PAGARME_API_URL = process.env.REACT_APP_PAGARME_API_URL
export const IS_PRODUCTION = process.env.NODE_ENV === 'production'


export const MESSAGES: { [key: string]: string } = {
    ASSETS_NOT_FOUND: 'Recursos não encontrados',
    ARTIST_NOT_FOUND: 'Artista não encontrado',
    SUBSCRIPTION_ACTIVE: 'Você não pode denunciar um perfil com assinatura ativa.\nCancele a assinatura primeiro.',
    BLOCK_OK: 'Bloqueio bem-sucedido',
    ARTIST_ID_INVALID: 'ID do artista inválido',
    POST_NOT_FOUND: 'Postagem não encontrada',
    USER_LIST_INVALID: 'Lista de usuários inválida',
    POST_ID_INVALID: 'ID da postagem inválido',
    PAYMENT_METHOD_ID_INVALID: 'ID do método de pagamento inválido',
    ARTIST_NOT_VERIFIED: 'Artista não verificado',
    PRODUCT_NOT_FOUND: 'Produto não encontrado',
    SUBSCRIPTION_ALREADY_EXISTS: 'Assinatura já existe',
    PAYMENT_METHOD_NOT_FOUND: 'Método de pagamento não encontrado',
    PAYMENT_FAILED: 'Pagamento falhou',
    PAYMENT_PIX_FAILED: 'Pagamento com PIX falhou',
    SUBSCRIBE_OK: 'Assinatura bem-sucedida',
    USER_NOT_VERIFIED: 'Usuário não verificado',
    CONTENT_EMPTY: 'Conteúdo vazio',
    DESCRIPTION_TOO_LONG: 'Descrição muito longa',
    ASSETS_INVALID: 'Recursos inválidos',
    CREATE_POST_OK: 'Postagem criada com sucesso',
    DELETE_POST_OK: 'Postagem deletada com sucesso',
    POST_ALREADY_MODERATED: 'Postagem já moderada',
    POST_PRIVACY_CHANGE_OK: 'Privacidade da postagem alterada com sucesso',
    INVALID_REPORT: 'Relatório inválido',
    REPORTED_ID_INVALID: 'ID do reportado inválido',
    UI_ID_INVALID: 'ID da interface de usuário inválido',
    MESSAGE_INVALID: 'Mensagem inválida',
    CONVERSATION_ID_INVALID: 'ID da conversa inválido',
    MARK_CONVERSATION_ASREAD_OK: 'Conversa marcada como lida com sucesso',
    MARK_NOTIFICATION_ASREAD_OK: 'Notificação marcada como lida com sucesso',
    INVALID_PRICE: 'Preço inválido',
    SETUP_SUBSCRIPTION_OK: 'Assinatura configurada com sucesso',
    SUBSCRIPTION_NOT_FOUND: 'Assinatura não encontrada',
    CANCEL_SUBSCRIPTION_OK: 'Assinatura cancelada com sucesso',
    INVALID_DATE_FORMAT: 'Formato de data inválido',
    INVALID_DATE: 'Data inválida',
    INVALID_DATE_INTERVAL: 'Intervalo de datas inválido',
    INVALID_PIX_KEY: 'Chave PIX inválida',
    EDIT_ACCOUNT_OK: 'Conta editada com sucesso',
    OTP_INVALID: 'OTP inválido',
    VERIFY_EDIT_ACCOUNT_OK: 'Verificação de edição de conta bem-sucedida',
    NO_FUNDS: 'Sem fundos',
    REQUEST_PAYOUT_OK: 'Solicitação de pagamento bem-sucedida',
    ERROR_WITHDRAW_AMOUNT: 'Erro no valor do saque',
    VERIFY_REQUEST_PAYOUT_OK: 'Verificação de solicitação de pagamento bem-sucedida',
    PAYMENT_METHOD_ADDED: 'Método de pagamento adicionado',
    PAYMENT_METHOD_DEFAULT: 'Método de pagamento padrão',
    PAYMENT_METHOD_DELETED: 'Método de pagamento deletado',
    PASSWORD_TOO_SHORT: 'Senha muito curta',
    PASSWORD_NO_DIGIT: 'Senha sem dígito',
    PASSWORD_NO_UPPER: 'Senha sem letra maiúscula',
    PASSWORD_NO_LOWER: 'Senha sem letra minúscula',
    EMAIL_INVALID: 'E-mail inválido',
    NAME_EMPTY: 'Nome vazio',
    NAME_TOO_SHORT: 'Nome muito curto',
    USER_ALREADY_EXISTS: 'Usuário já existe',
    PASSWORD_INVALID: 'Senha inválida',
    USER_NOT_FOUND: 'Usuário não encontrado',
    RECOVERY_PASSWORD_OK: 'Recuperação de senha bem-sucedida',
    PASSWORD_TOO_LONG: 'Senha muito longa',
    REQUEST_VERIFY_EMAIL_OK: 'Solicitação de verificação de e-mail bem-sucedida',
    VERIFY_EMAIL_OK: 'E-mail verificado com sucesso',
    ACCOUNT_ID_INVALID: 'ID da conta inválido',
    ACCOUNT_ID_ALREADY_EXISTS: 'ID da conta já existe',
    DISPLAY_NAME_EMPTY: 'Nome de exibição vazio',
    DISPLAY_NAME_TOO_SHORT: 'Nome de exibição muito curto',
    DISPLAY_NAME_TOO_LONG: 'Nome de exibição muito longo',
    DISPLAY_NAME_INVALID: 'Nome de exibição inválido',
    INSTAGRAM_ID_INVALID: 'ID do Instagram inválido',
    TWITTER_ID_INVALID: 'ID do Twitter inválido',
    USER_ALREADY_VERIFYING: 'Usuário já está verificando',
    DOCUMENTS_MISSING: 'Documentos faltando',
    ID_FRONT_INVALID: 'Frente do documento inválida',
    ID_BACK_INVALID: 'Verso do documento inválido',
    HOLDING_ID_INVALID: 'Documento segurado inválido',
    VERIFY_PENDING: 'Verificação pendente',
    DARK_MODE_INVALID: 'Modo escuro inválido',
    DARK_MODE_UPDATED: 'Modo escuro atualizado',
    FULL_NAME_INVALID: 'Nome completo inválido',
    COUNTRY_CODE_INVALID: 'Código do país inválido',
    PHONE_INVALID: 'Telefone inválido',
    ZIP_CODE_INVALID: 'CEP inválido',
    ADDRESS_NUMBER_INVALID: 'Número do endereço inválido',
    CARD_INVALID: 'Cartão inválido',
    CARD_HASH_INVALID: 'Hash do cartão inválido',
    CARD_CVV_INVALID: 'CVV do cartão inválido',
    CARD_HOLDER_NAME_INVALID: 'Nome do titular do cartão inválido',
    CARD_HOLDER_DOCUMENT_INVALID: 'Documento do titular do cartão inválido',
    CUSTOMER_CREATION_FAILED: 'Falha na criação do cliente',
    CARD_CREATION_FAILED: 'Falha na criação do cartão',
    DOCUMENT_INVALID: 'Documento inválido',
    HOLDER_NAME_INVALID: 'Nome do titular inválido',
    TAX_ID_INVALID: 'ID fiscal inválido',
    SUBSCRIBER_ACTIVE: 'Você não pode denunciar um assinante ativo.',
  };
  
export const SUBSCRIPTION_STATUS_TRANS = {
    active: 'Ativo',
    waiting_payment: 'Aguardando pagamento',
    canceled: 'Cancelado',
} as any;
  
export default {
    BASE_IMAGE_URL,
    API_URL,
    WSS_URL,
    PAGARME_APP_ID,
    PAGARME_API_URL,
    IS_PRODUCTION,
    MESSAGES,
    SUBSCRIPTION_STATUS_TRANS
}