import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';

export default function Footer() {
    return (
        <Box
            component="footer"
            sx={{
                py: 3,
                px: 2,
                mt: 'auto',
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[800],
                width: "100%",
                // make links look like links
                a: {
                    color: "inherit",
                    textDecoration: "none",
                    fontSize: "0.8rem",
                    "&:hover": {
                        textDecoration: "underline",
                    },
                },

            }}
        >
            <Grid container spacing={2} maxWidth="md" sx={{ margin: "auto" }}>
                <Grid item xs={6} sm={6} md={4}><Typography variant="body1" fontSize="small">©2024 MeuEncontro</Typography>
                    <Typography variant="body1"><a href="mailto:support@meuencontro.com">Contact</a></Typography>
                    <Typography variant="body1"><a href="/about">About</a></Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <Typography variant="body1"><a href="/terms">Terms of Service</a></Typography>
                    <Typography variant="body1"><a href="/cookies">Cookies Notice</a></Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <Typography variant="body1"><a href="/privacy">Privacy Policy</a></Typography>
                    <Typography variant="body1"><a href="/transparency-center">Safety & Transparency Center</a></Typography>
                </Grid>
            </Grid>
        </Box>
    );
}