import React from 'react';
import { Box, Link, Tooltip } from '@mui/material';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import { ProfileModel } from '../../types/ProfileModel';

type ArtistStatsProps = {
	user?: ProfileModel;
}

export default function ArtistStats({ user }: ArtistStatsProps) {

	return (
		<Box sx={{ display: 'flex', alignItems: 'center', gap: 1, lineHeight: '19px', fontSize: 'small' }}>
			<Tooltip title="Fotos">
				<Link href="#" sx={{ display: 'flex', alignItems: 'center', gap: 0.5, color: 'inherit', textDecoration: 'none' }}>
					<ImageOutlinedIcon sx={{ fontSize: '16px', mt: '2px' }} />{user?.imagesCount}
				</Link>
			</Tooltip>·
			<Tooltip title="Videos">
				<Link href="#" sx={{ display: 'flex', alignItems: 'center', gap: 0.5, color: 'inherit', textDecoration: 'none' }}>
					<VideocamOutlinedIcon sx={{ fontSize: '22px' }} />{user?.videosCount}
				</Link>
			</Tooltip>·
			<Tooltip title="Seguidores">
				<Link href="#" sx={{ display: 'flex', alignItems: 'center', gap: 0.5, color: 'inherit', textDecoration: 'none' }}>
					<GroupAddOutlinedIcon sx={{ fontSize: '18px', mt: '1px' }} />{user?.followersCount}
				</Link>
			</Tooltip>
		</Box>
	);
}