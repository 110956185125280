import React, { useContext, useEffect, useState } from "react";
import ArtistProfileRef from "../ArtistProfileRef";
import StandardModal from "../StandardModal";
import { cancelSubscription } from "../../services/settingsService";
import { Button, Typography, Box, Link } from "@mui/material";
import { toast } from "react-toastify";
import helpers from "../../utils/helpers";
import { ProfileModel } from "../../types/ProfileModel";
import PaymentMethod from "../PaymentMethod";
import { subscribe } from "../../services/settingsService";
import CheckIcon from '@mui/icons-material/Check';
import constants from "../../utils/constants";
import QrCode, { PixInfo } from "../QrCode";


type SubscriptionInfoModalProps = {
	artist: ProfileModel;
	openState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
	onConfirmCancel: () => void;
	onConfirmSubscribe: () => void;
}

export default function SubscriptionInfoModal({
	artist,
	openState,
	onConfirmCancel,
	onConfirmSubscribe
}: SubscriptionInfoModalProps) {

	const [step, setStep] = useState(0);
	const [pixInfo, setPixInfo] = useState<PixInfo>();
	const [loading, setLoading] = useState(0);

	const onConfimPayment = () => {
		toast.success('Assinatura realizada com sucesso.');
		onConfirmSubscribe();
		setStep(0);
		openState[1](false);
	}

	useEffect(() => {
		addEventListener('subscriptionInfoConfirmPayment', onConfimPayment);
		return () => removeEventListener('subscriptionInfoConfirmPayment', onConfimPayment);
	}, []);

	const handleClose = () => {
		setStep(0);
		openState[1](false);
	}

	const handleCancelSubscriptionConfirm = () => {
		openState[1](false);
		cancelSubscription({ artistId: artist?.id }).then(() => {
			onConfirmCancel();
			toast.success('Assinatura cancelada.');
			setStep(0);
		}).catch(() => { });
	}

	const handleSubscribing = (paymentMethodId: string) => {
		setLoading(prev => prev + 1);
		subscribe({
			artistId: artist?.id,
			paymentMethodId
		}).then((data: any) => {
			setLoading(prev => prev - 1);
			if (data.method === 'credit_card') {
				openState[1](false);
				toast.success('Assinatura realizada com sucesso');
				onConfirmSubscribe();
				setStep(0);
			} else if (data.method === 'pix') {
				setPixInfo({
					qrCode: data.qrCode,
					qrCodeUrl: data.qrCodeUrl,
					expiresInSeconds: data.qrCodeExpiresInSeconds
				});
				setStep(3);
			}

		}).catch(() => {
			setLoading(prev => prev - 1);
		});
	}

	return (
		<StandardModal openState={openState} onClose={handleClose} loading={loading}
			sx={{ maxWidth: (step === 0 && artist.subscription?.type === 'subscription') || step === 3 ? '300px' : '540px' }}>
			{step === 0 && <>
				{artist.subscription?.type === 'subscription' ? <Box sx={{ display: 'flex', flexDirection: 'column' }}>
					{artist && <ArtistProfileRef artist={artist} sx={{ borderRadius: 0, height: '128px' }} />}
					<Box sx={{
						display: 'flex',
						flexDirection: 'column',
						p: 2,
						gap: 2
					}}>
						<Typography variant="h5">ASSINE PARA TER OS BENEFÍCIOS:</Typography>
						<ul style={{
							marginTop: '1rem',
							lineHeight: '2',
						}}>
							<li style={{
								display: "flex",
								alignItems: "center",
								gap: 1.5
							}}><CheckIcon color="primary" />Acesso completo ao conteúdo</li>
							<li style={{
								display: "flex",
								alignItems: "center",
								gap: 1.5
							}}><CheckIcon color="primary" />Mensagens ilimitadas</li>
							<li style={{
								display: "flex",
								alignItems: "center",
								gap: 1.5
							}}><CheckIcon color="primary" />Cancele a qualquer momento</li>
						</ul>
						<Box>{helpers.toLocalMoney(artist.subscription?.price)} / mês</Box>
						<Box sx={{
							display: 'flex',
							justifyContent: 'space-between',
						}}>
							<Button size="small" autoFocus onClick={() => openState[1](false)}>
								FECHAR
							</Button>
							<Button
								size="small"
								disabled={!artist.subscription || artist.subscription?.status === 'active'}
								onClick={() => setStep(2)} color="primary" variant="contained">
								ASSINAR
							</Button>
						</Box>
					</Box>
				</Box> :
					<Box sx={{ display: 'flex', flexDirection: 'column' }}>
						{artist && <ArtistProfileRef artist={artist} sx={{ height: '128px' }} />}
						<Box sx={{
							display: 'flex',
							flexDirection: 'column',
							p: 2,
							gap: 2,
						}}>
							<Typography variant="h5">{artist?.subscription?.status !== 'canceled' ? 'Assinante desde' : 'Cancelado em'}</Typography>
							<Typography variant="body1">{
								artist?.subscription?.status !== 'canceled' ?
									helpers.timeStampToFormatedDate(artist?.subscription?.createdAt) :
									helpers.timeStampToFormatedDate(artist?.subscription?.canceledAt)} · {constants.SUBSCRIPTION_STATUS_TRANS[artist?.subscription?.status || '']}</Typography>
							{artist?.subscription?.status === 'waiting_payment' && <Link href="#cancel-subscription"
								onClick={() => setStep(1)}>
								cancelar assinatura
							</Link>}
							{artist?.subscription?.status === 'waiting_payment' && <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
								<Typography variant="body2">
									Se você já realizou o pagamento aguarde até que o mesmo seja processado, geralmente isso ocorre em poucos minutos.
								</Typography>
								<Typography variant="body2">
									Caso o pagamento tenha falhado e foi exibido uma mensagem de erro, verifique o extrato bancário.
								</Typography>
								<Typography variant="body2">
									Caso o valor não tenha sido debitado ou foi estornado, você pode tentar novamente.
								</Typography>
								<Typography variant="body2">
									Caso o valor tenha sido debitado e esteja demorando muito, por favor entre em contato através do e-mail support@meuencontro.com.
								</Typography>
							</Box>}
							{artist?.subscription?.status === 'active' && <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
								<Typography variant="body2">
									Parabéns, você está patrocinando um artista e possui acesso ao conteúdo exclusivo.
								</Typography>
							</Box>}
							{artist?.subscription?.status === 'canceled' && <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
								<Typography variant="body2">
									Você cancelou a assinatura e não possui mais acesso ao conteúdo exclusivo.
								</Typography>
								<Typography variant="body2">
									Visite o perfil do artista para assinar novamente.
								</Typography>
							</Box>}
							<Box sx={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
							}}>
								<Button size="small" variant="outlined"
									onClick={handleClose}
									sx={{ minWidth: '33%' }}>
									Fechar
								</Button>
								{artist?.subscription?.status === 'active' && <Button
									size="small"
									variant="contained"
									onClick={() => setStep(1)}
									sx={{ minWidth: '33%' }}>
									Cancelar assinatura
								</Button>}
								{artist?.subscription?.status === 'waiting_payment' && <Button
									size="small"
									variant="contained"
									onClick={() => setStep(2)}
									sx={{ minWidth: '33%' }}>
									Verificar pagamento
								</Button>}
							</Box>
						</Box>
					</Box>}
			</>}
			{step === 1 && <Box sx={{
				display: 'flex',
				flexDirection: 'column',
				padding: 2,
				gap: 2,
			}}>
				<Typography variant="h5">Confirmar cancelamento de asssinatura</Typography>
				<Typography variant="body1">Ao confirmar você perderá todos os benefícios no término do período corrente. Você poderá assinar novamente, porém, o valor da assinatura poderá ser diferente do atual.</Typography>
				<Box sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
				}}>
					<Button
						size="small"
						variant="outlined"
						onClick={() => setStep(0)}
						sx={{ minWidth: '33%' }}>
						Cancelar
					</Button>
					<Button
						size="small"
						variant="contained"
						onClick={handleCancelSubscriptionConfirm}
						sx={{ minWidth: '33%' }}>
						confirmar
					</Button>
				</Box>
			</Box>}
			{step === 2 && <Box sx={{
				display: 'flex',
				flexDirection: 'column',
				padding: 2,
				gap: 2,
			}}>
				<PaymentMethod
					onSelectPaymentMethod={(paymentMethod) => { handleSubscribing(paymentMethod.paymentMethodId); }}
					onCancel={() => setStep(0)}
					setLoading={setLoading}
				/>
			</Box>}
			{step === 3 && pixInfo && <QrCode pixInfo={pixInfo} onCancel={handleClose} />}
		</StandardModal>
	)
}