import { SendRounded } from "@mui/icons-material";
import { Box, IconButton, TextField } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { SendMessageRequest, sendMessage } from "../../services/conversationService";
import { SignalType } from "../../types/SignalType";
import { ConversationModel } from "../../types/ConversationModel";
import { MessageModel } from "../../types/MessageModel";
import React, { useEffect, useContext } from "react";
import { ProfileModel } from "../../types/ProfileModel";
import { UserContext } from "../../App";
import helpers from "../../utils/helpers";

const SendMesssageForm = ({ artist }: { artist?: ProfileModel }) => {
    const { currentUser, setCurrentUser } = useContext(UserContext);
    const queryClient = useQueryClient();
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors }
    } = useForm<SendMessageRequest>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur'
    });

    const handleSendMessage = async (values: SendMessageRequest) => {
        return sendMessageMutation.mutate({ ...values, receivers: [artist?.id ?? ''] });
    }

    const sendMessageMutation = useMutation(
        {
            mutationFn: sendMessage,
            onMutate: async (values: SendMessageRequest) => {
                queryClient.cancelQueries({queryKey: ['messages', artist?.id]});
                queryClient.setQueryData<Array<MessageModel>>(['messages', artist?.id],
                    (oldData) => [...(oldData || []), {
                        conversationId: '',
                        body: values.body,
                        senderId: currentUser.id,
                        type: SignalType.MESSAGE,
                        id: '',
                    }]
                );
                setValue('body', '');
            },
            onSuccess: (conversations) => {
                queryClient.setQueryData<Array<ConversationModel>>(['conversations'], (oldData) => {
                    // add incomming conversations only if it doesn't exist
                    let newData = [...(oldData || [])];
                    conversations.forEach(conversation => {                        
                        const existingConversation = newData.find(c => c.conversationId === conversation.conversationId);
                        console.log('conversation', conversation)
                        console.log('existingConversation', existingConversation)
                        if (existingConversation) {                            
                            if (existingConversation.updatedAt < conversation.updatedAt) {
                                // remove the old conversation
                                newData = newData.filter(c => c.conversationId !== conversation.conversationId);
                                // add the new conversation
                                newData.unshift(conversation);             
                            }           
                        } else {
                            newData.unshift(conversation);
                        }
                    });
                    // sort by updatedAt desc

                    return newData.sort((a, b) => {
                        if (a.updatedAt && b.updatedAt) {
                            return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
                        }
                        return 0;
                    });
                })
            }
        }
    )

    const bodyValue = watch('body');

    useEffect(() => {
        if (!bodyValue) return;
        setValue('body', helpers.moderateContent(bodyValue));
    }, [bodyValue]);

    return (
        <Box component="form"
            sx={{
                display: 'flex',
                position: 'sticky',
                bottom: 0,
                width: '100%',
                backgroundColor: 'background.paper',
                borderTop: 'var(--border)',
                padding: 2
            }}
            onSubmit={!!artist ? handleSubmit(handleSendMessage) : () => console.log('no artist')}>
            <TextField
                sx={{ flex: 1 }}
                variant="outlined"
                label=""
                id="message"
                autoComplete="off"
                error={!!errors.body}
                placeholder="Digite algo..."
                InputProps={{
                    endAdornment:
                        <IconButton
                            type="submit"
                            aria-label="send message"
                            edge="end"
                            {...(() => !!artist ? {} : { disabled: true })()}
                        ><SendRounded color="primary" />
                        </IconButton>
                    ,
                }}
                {...register('body', {
                    required: true,
                    minLength: 1,
                    maxLength: 150,                   
                    
                })}
            />
        </Box>
    )
}

export default SendMesssageForm;