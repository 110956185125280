import React, { useEffect, useState } from "react"
import { Box } from "@mui/material"

const PostMediaImage = ({ imageUrl, onClick } : 
    { 
        imageUrl?: string,
        onClick: React.MouseEventHandler<HTMLImageElement> | undefined
    }) => {

    return (
        <Box sx={{
            display: 'flex', 
            justifyContent: 'center',
            position: 'relative',
            overflow: 'hidden'
            }}>
            <Box sx={{
                backgroundImage: `url(${imageUrl})`,
                backgroundColor: 'transparent',
                display: 'block',
                height: 'auto',
                filter: 'blur(10px)',
                backgroundPosition: '50%',
                backgroundSize: 'cover',
                position: 'absolute',
                left: '-10px',
                right: '-10px',
                top: '-10px',
                bottom: '-10px',
                zIndex: '0'
            }} >                
            </Box>            
            <img style={{
                    maxWidth: '100%',
                    maxHeight: '70vh',
                    objectFit: 'scale-down',
                    position: 'relative',
                    zIndex: '1'
                }}
                 onClick={onClick}
                 src={imageUrl} 
                 onError={(event) => event.currentTarget.style.display='none'} />
        </Box>
    )
}

export default PostMediaImage