import React from 'react';
import { Box, Chip, Link, Tooltip } from '@mui/material';
import twitter from '../../assets/twitter.svg';
import instagram from '../../assets/instagram.svg';
import { Link as RouterLink } from 'react-router-dom';
import { ProfileModel } from '../../types/ProfileModel';

type ArtistExternalLinksProps = {
    artist?: ProfileModel;
}

export default function ArtistExternalLinks({ artist }: ArtistExternalLinksProps) {
    return (
        <Box sx={{ mt: 2, display: 'flex', gap: 1 }}>
            {artist?.twitterId && <Chip sx={{
                '& .MuiChip-label': {
                    pl: 0.5,
                    pr: 2
                }
            }} label={
                <Link
                    target="_blank"
                    href={`https://twitter.com/${artist?.twitterId}`}                    
                    sx={{
                        display: 'flex', gap: 0.5, alignItems: 'center', fontWeight: 'bold',
                        textDecoration: 'none', color: 'inherit',
                        '&:hover': {
                            color: 'primary.main',
                            textDecoration: 'none'
                        }
                    }}>
                    <img 
                    onError={(event) => event.currentTarget.style.display='none'}
                    src={twitter} alt="Twitter" style={{ width: 24, height: 24 }} />
                    {artist?.twitterId}
                </Link>
            } />}
            {artist?.instagramId && <Chip sx={{
                '& .MuiChip-label': {
                    pl: 0.5,
                    pr: 2
                }
            }} label={
                <Link 
                    target="_blank"
                    href={`https://instagram.com/${artist?.instagramId}`}
                    sx={{
                        display: 'flex', gap: 0.5, alignItems: 'center', fontWeight: 'bold',
                        textDecoration: 'none', color: 'inherit',
                        '&:hover': {
                            color: 'primary.main',
                            textDecoration: 'none'
                        }
                    }}>
                    <img 
                    onError={(event) => event.currentTarget.style.display='none'}
                    src={instagram} alt="Instagram" style={{ width: 24, height: 24, color: 'red' }} />
                    {artist?.instagramId}
                </Link>
            } />}
        </Box>
    );
}