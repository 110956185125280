import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useFilePicker } from 'use-file-picker';
import { toast } from 'react-toastify';
import axios from 'axios';
import PostMediaImage from '../PostMediaImage';
import { Gallery, Item } from 'react-photoswipe-gallery';
import { AssetModel } from '../../types/AssetModel';
import { unhandledApi } from '../../services/api';

const VIDEO_MAXIMUM_SIZE = 64 * 1024 * 1024; // 64MB
let abortController = new AbortController();

type AssetUploaderProps = {
    name: string;
    onUploadProgress?: (progress: number) => void;
    onUploadFinished?: () => void;
    onUploadStarted?: () => void;
    onChange?: (media?: AssetModel) => void;
    accept?: 'image' | 'video' | 'both' | 'avatar' | 'banner';
    defaultValue?: AssetModel;
    hidePreview?: boolean;
    hideLoading?: boolean;
    sx?: any;
}

export default function AssetUploader({
    name,
    onUploadProgress,
    onUploadFinished,
    onUploadStarted,
    onChange,
    accept,
    defaultValue,
    hidePreview,
    hideLoading,
    sx,
}: AssetUploaderProps) {
    const [asset, setAsset] = useState<AssetModel | undefined>(defaultValue);
    const [processing, setProcessing] = useState(false);

    const accepts = {
        'video': '.mp4, .mov',
        'image': '.jpg, .jpeg, .png, .jfif, .webp',
        'avatar': '.jpg, .jpeg, .png, .jfif, .webp',
        'banner': '.jpg, .jpeg, .png, .jfif, .webp',
        'both': '.jpg, .jpeg, .png, .jfif, .webp, .mp4, .mov',
    }

    const [filePicker] = useFilePicker({
        accept: accepts[accept || 'both'],
        readAs: 'DataURL',
        multiple: false,
        onFilesRejected: ({ errors }) => {
            console.log('onFilesRejected', errors);
            toast.error(errors.join('\n'))
        },
        onFilesSuccessfulySelected: ({ plainFiles }) => {
            if (plainFiles[0].type === 'video/mp4' || plainFiles[0].type === 'video/quicktime') {

                if (plainFiles[0].size > VIDEO_MAXIMUM_SIZE) {
                    toast.error('O tamanho máximo é 64MB');
                    return;
                }
                setProcessing(true);
                getUploadCredentials(
                    'video',
                    plainFiles[0].name.split('.').pop() || 'mp4'
                ).then(credentials => {
                    uploadFileAsync(credentials.url, plainFiles[0], false).then(() => {
                        unhandledApi.get<AssetModel>(`/collections/user/assets/poster-${credentials.assetId}`).then(res => {
                            setAsset({
                                type: 'video',
                                assetId: `video-${credentials.assetId}`,
                                poster: res.data,
                            });
                            setProcessing(false);
                        }).catch(err => {
                            toast.error('Ops...');
                        });
                    });
                }).catch(err => {
                    console.error(err);
                    setProcessing(false);
                    toast.error('Ops...');
                });

            } else {
                setProcessing(true);
                getUploadCredentials(
                    accept === 'both' ? 'image' : accept || 'image',
                    plainFiles[0].name.split('.').pop() || 'jpg'
                ).then(credentials => {
                    uploadFileAsync(credentials.url, plainFiles[0], false).then(() => {
                        unhandledApi.get<AssetModel>(`/collections/user/assets/${accept || 'image'}-${credentials.assetId}`).then(res => {
                            setAsset(res.data);
                            setProcessing(false);
                        });
                    }).catch(err => {
                        toast.error('Ops...');
                    });
                }).catch(err => {
                    console.log(err);
                    setProcessing(false);
                    toast.error('Ops...');
                });
            }
        },
    });

    const getUploadCredentials = async (type: 'video' | 'image' | 'avatar' | 'banner', extension: string) => {
        return await unhandledApi.get(`/collections/user/upload/${type}/${extension}`).then(res => res.data);
    }

    const uploadFileAsync = async (url: string, file: File, supressProgress: boolean) => {

        if (onUploadStarted && !supressProgress)
            onUploadStarted();

        try {
            await axios.put(url, file, {
                signal: abortController.signal,
                onUploadProgress: progressEvent => {
                    if (onUploadProgress && !supressProgress)
                        onUploadProgress(Math.round((progressEvent.progress || 0) * 100));
                },
                headers: {
                    'Content-Type': file.type,
                }
            });
        } catch (error) {
            toast.error('Erro ao fazer upload do arquivo');
        }

        if (onUploadFinished && !supressProgress) {
            onUploadFinished();
        }

    }

    const selectMedia = () => {
        setAsset(undefined);
        filePicker();
    }

    const removeMedia = () => {
        if (onUploadProgress)
            onUploadProgress(0);
        setAsset(undefined);
        abortController.abort();
        abortController = new AbortController();
    }

    useEffect(() => {
        addEventListener(`${name}_selectMedia`, selectMedia);
        return () => removeEventListener(`${name}_selectMedia`, selectMedia);
    }, []);

    useEffect(() => {
        addEventListener(`${name}_removeMedia`, removeMedia);
        return () => removeEventListener(`${name}_removeMedia`, removeMedia);
    }, []);


    useEffect(() => {
        if (onChange)
            onChange(asset);
    }, [asset]);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            ...sx
        }}>
            <CircularProgress color="primary" sx={{
                display: !hideLoading && processing ? 'block' : 'none',
            }} />
            {!hidePreview && asset && <Gallery
                options={{ showHideAnimationType: 'fade', zoom: false }}>
                <Item
                    original={asset?.type === 'image' ? asset?.original?.path : asset?.poster?.original?.path}
                    thumbnail={asset?.type === 'image' ? asset?.standard?.path : asset?.poster?.standard?.path}
                    width={asset?.type === 'image' ? asset?.original?.width : asset?.poster?.original?.width}
                    height={asset?.type === 'image' ? asset?.original?.height : asset?.poster?.original?.height}
                >
                    {({ ref, open }) => (
                        <PostMediaImage
                            imageUrl={asset?.type === 'image' ? asset?.standard?.path : asset?.poster?.standard?.path}
                            onClick={open} />
                    )}
                </Item>
            </Gallery>}
        </Box>
    );
}