import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, TextField, Typography, Select, MenuItem, IconButton, Tooltip } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getPaymentMethods, deletePaymentMethod, setDefaultPaymentMethod } from '../../services/settingsService';
import { toast } from 'react-toastify';
import AddCardIcon from '@mui/icons-material/AddCard';
import CreditCardOffOutlinedIcon from '@mui/icons-material/CreditCardOffOutlined';
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';
import { PaymentMethodModel } from '../../types/PaymentMethodModel';
import PixIcon from '@mui/icons-material/Pix';
import PixPaymentMethod from './pixPaymentMethod';
import CreditCardPaymentMethod from './creditCardPaymentMethod';

type PaymentMethodProps = {
	onSelectPaymentMethod: (data: PaymentMethodModel) => void;
	onCancel: () => void;
	setLoading: React.Dispatch<React.SetStateAction<number>>;
}

export default function PaymentMethod({
	onSelectPaymentMethod,
	onCancel,
	setLoading
}: PaymentMethodProps) {

	const queryClient = useQueryClient();

	const { data: paymentMethods, isFetching } = useQuery({
		queryKey: ['paymentMethods'],
		queryFn: getPaymentMethods,
	})

	const [currentPaymentMethod, setCurrentPaymentMethod] = useState<any>("x");
	const [step, setStep] = useState(0);

	const deletePaymentMethodMutation = useMutation({
		mutationFn: deletePaymentMethod,
		onSuccess: (data) => {
			toast.success('Cartão excluído com sucesso');
			queryClient.invalidateQueries({ queryKey: ['paymentMethods'] });
		},
	})

	const handleDeletePaymentMethod = () => {
		if (!currentPaymentMethod) return;
		deletePaymentMethodMutation.mutate(currentPaymentMethod.paymentMethodId);
	}

	const setDefaultPaymentMethodMutation = useMutation({
		mutationFn: setDefaultPaymentMethod,
		onSuccess: (data) => {
			toast.success('Cartão definido como padrão');
			queryClient.invalidateQueries({ queryKey: ['paymentMethods'] });
		},
	})

	const handleSetDefaultPaymentMethod = () => {
		if (!currentPaymentMethod) return;
		setDefaultPaymentMethodMutation.mutate(currentPaymentMethod.paymentMethodId);
	}

	useEffect(() => {
		if (isFetching)
			setLoading(prev => prev + 1);
		else
			setLoading(prev => prev - 1);

	}, [isFetching])

	return (
		<Box>
			{step === 0 && <Box sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: 2,
			}}>
				<Typography variant="h5">Selecionar cartão</Typography>
				<Box sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: 2,
				}}>
					<Box>
						<Select fullWidth 
							value={currentPaymentMethod} onChange={(e) => setCurrentPaymentMethod(e.target.value)}>
							<MenuItem key={-1} value="x" ><em>SELECIONE UM CARTÃO</em></MenuItem>
							{paymentMethods?.filter(method => method.paymentMethodId !== 'pix').map((method: any, index: any) => (
								<MenuItem key={index} value={method}>
									{method.card.brand + "·" + method.card.firstDigits + "..." + method.card.lastDigits + ' - ' + method.status}
								</MenuItem>
							))}
						</Select>
					</Box>
					<Box sx={{
						display: 'flex',
						gap: 2,
						justifyContent: 'flex-end'
					}}>
						<IconButton color="primary" onClick={() => setStep(1)}>
							<Tooltip title="Adicionar novo cartão">
								<AddCardIcon />
							</Tooltip>
						</IconButton>
						<IconButton
							disabled={!currentPaymentMethod || currentPaymentMethod?.status === 'default' || currentPaymentMethod === 'x'}
							onClick={handleSetDefaultPaymentMethod}>
							<Tooltip title="Definir cartão padrão">
								<CreditScoreOutlinedIcon />
							</Tooltip>
						</IconButton>
						<IconButton
							disabled={!currentPaymentMethod || currentPaymentMethod?.status === 'default' || currentPaymentMethod === 'x'}
							onClick={handleDeletePaymentMethod}>
							<Tooltip title="Excluir cartão">
								<CreditCardOffOutlinedIcon />
							</Tooltip>
						</IconButton>
					</Box>
				</Box>
				<Box sx={{
					display: 'flex',
					justifyContent: 'space-between',
				}}>
					<Button size="small" autoFocus onClick={() => { onCancel(); }}>
						VOLTAR
					</Button>
					<Box sx={{
						display: 'flex',
						gap: 2,
					}}>
						<Button
							size="small"
							startIcon={<PixIcon />}
							disabled={isFetching}
							onClick={() => setStep(2)}
							color="primary" variant="outlined">
							PAGAR COM PIX
						</Button>
						<Button
							size="small"
							disabled={isFetching || !currentPaymentMethod || currentPaymentMethod === 'x'}
							onClick={() => onSelectPaymentMethod(currentPaymentMethod)}
							color="primary" variant="contained">
							PAGAR
						</Button>
					</Box>
				</Box>
			</Box>}
			{step === 1 && <CreditCardPaymentMethod 
				paymentMethod={currentPaymentMethod}
				onCancel={() => setStep(0)}
				onSuccess={(paymentMethod: PaymentMethodModel) => { setStep(0); onSelectPaymentMethod(paymentMethod) }}
				setLoading={setLoading}
			/>}
			{step === 2 && <PixPaymentMethod 
				paymentMethod={paymentMethods?.find((method: any) => method.paymentMethodId === 'pix')}
				onCancel={() => setStep(0)}
				onSuccess={(pixPaymentMethod: PaymentMethodModel) => { onSelectPaymentMethod(pixPaymentMethod)}}
				setLoading={setLoading}
			/>}
		</Box>
	)
}