import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import constants from '../../../utils/constants';
import { UserContext } from '../../../App';
import { Backdrop, CircularProgress } from '@mui/material';

type OAuthCallbackProps = {
  provider: 'twitter' | 'google';
};

const OAuthCallback = ({ provider }: OAuthCallbackProps) => {
  const location = useLocation();
  const userState = useContext(UserContext);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const code = query.get('code');

    axios.get(`${constants.API_URL}/user/auth/${provider}/callback?code=${code}`).then(res => {
      userState.setCurrentUser(res.data);
    }).catch(err => {
      console.error('Error during authentication', err);
    });

  }, [location]);

  return (
    <Backdrop
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default OAuthCallback;