import React, { useContext } from 'react';
import { Gallery, Item } from 'react-photoswipe-gallery';
import { createGalleryVideo, destroyGalleryVideo } from '../../utils/helpers';
import { PostModel } from '../../types/PostModel';
import { Box, Button } from '@mui/material';
import noPicLight from '../../assets/no-pic.png';
import noPicDark from '../../assets/no-pic-dark.png';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { UserContext } from '../../App';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';

type Props = {
  posts: PostModel[];
  mediaFilter: 'all' | 'image' | 'video';
};

const CustomGallery = ({ posts, mediaFilter }: Props) => {

  const { currentUser, setCurrentUser } = useContext(UserContext);
  const noPic = currentUser.darkMode ? noPicDark : noPicLight;

  return (
    <Gallery onBeforeOpen={(pswpInstance: any) => {
      pswpInstance.on('contentActivate', ({ content }: any) => {
        createGalleryVideo(content.element, posts[content.index])
      });
      pswpInstance.on('contentDeactivate', ({ content }: any) => {
        destroyGalleryVideo(content.element, posts[content.index])
      });
    }}
      options={{ showHideAnimationType: 'fade', zoom: false }}>

      <div className='gallery'>
        {posts.map((post) => (
          post.assets.filter(asset => mediaFilter == 'all' || asset.type === mediaFilter).map((asset) => (
            <Item
              key={asset.assetId}
              original={asset.type === 'image' ? asset.original?.path : ''}
              thumbnail={asset.type === 'image' ? asset.original?.path : ''}

              // check if content is available and sets a default size if not to prevent layout shift
              width={(asset.type === 'image' && !asset.original?.path || asset.type === 'video' && !asset.poster?.original?.path) ?
                '960' : asset.type === 'image' ? asset.original?.width : asset.poster?.original?.width}

              // check if content is available and sets a default size if not to prevent layout shift
              height={(asset.type === 'image' && !asset.original?.path || asset.type === 'video' && !asset.poster?.original?.path) ?
                '540' : asset.type === 'image' ? asset.original?.height : asset.poster?.original?.height}

              content={(asset.type === 'image' && !asset.original?.path || asset.type === 'video' && !asset.poster?.original?.path) ?
                <LockOutlinedIcon sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  color: 'secondary.light',
                  fontSize: '10rem'
                }} /> : undefined
              }
            >
              {({ ref, open }) => {

                const click = (asset.type === 'image' ? !!asset.small?.path : !!asset.poster?.small?.path) ?
                  {
                    onClick: (e: any) => {
                      e.preventDefault();
                      open(e);
                    },
                    href: `/artist/${post.artist?.accountId}`
                  } :
                  {};

                return (<Box component={click.onClick ? 'a' : 'div'} ref={ref} sx={{
                  backgroundImage: `url(${(asset.type === 'image' ? asset.small?.path : asset.poster?.small?.path) || noPic})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  position: 'relative',
                  paddingTop: '100%',
                }}
                  {...click}>
                  {asset.type === 'video' && asset.poster?.small?.path &&
                    <Button sx={{
                      border: 'none',
                      borderRadius: '50%',
                      backgroundColor: 'rgba(115, 133, 159, 0.5)',
                      transition: 'all 0.3s ease 0s',
                      width: '48px',
                      height: '48px',
                      fontSize: '3em',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      padding: '0',
                      color: '#fff',
                      display: 'flex',
                      minWidth: '48px',
                      '&:hover': {
                        backgroundColor: 'primary.main',
                      }
                    }}>
                      <PlayArrowIcon />
                    </Button>
                  }

                  {!asset.small?.path && !asset.poster?.small?.path && <LockOutlinedIcon sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: 'primary.main',
                    opacity: 0.5,
                    fontSize: '3rem'
                  }} />}

                  {asset.type === 'video' ? <Box sx={{
                    position: 'absolute',
                    bottom: 10,
                    left: 10,
                  }}>
                    <VideocamOutlinedIcon sx={{
                      color: 'primary.main',
                      fontSize: '1.2rem',
                      opacity: 0.5
                    }} /></Box> : <Box sx={{
                      position: 'absolute',
                      bottom: 10,
                      left: 10,
                    }}>
                    <ImageOutlinedIcon sx={{
                      color: 'primary.main',
                      fontSize: '1rem',
                      opacity: 0.5
                    }} /></Box>}

                </Box>)
              }}
            </Item>))
        ))}
      </div>
    </Gallery>
  );
};

export default CustomGallery;
