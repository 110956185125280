import { Breadcrumb } from "../../components/Breadcrumb";
import Header from "../../components/Header";
import PrivateTemplate from "../../components/PrivateTemplate";
import React, { useEffect, useState } from "react";
import {
    Box,
    TableRow,
    TableCell,
    Typography,
    Button,
    CardContent,
    Card,
    Tooltip,
    TextField
} from '@mui/material';
import {
    getStatement,
    getEarnings,
    requestPayout,
    verifyRequestPayout,
    editAccount,
    verifyEditAccount,
    EditAccountRequest,
    getEarningsAccount
} from "../../services/settingsService";
import { toast } from 'react-toastify';
import EnhancedTable, { DataRow, HeadCell } from "../../components/EnhancedTable";
import { useMutation, useQuery } from '@tanstack/react-query'
import * as constants from '../../utils/constants'
import { StatementModel } from "../../types/StatementModel";
import helpers from '../../utils/helpers';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import PixIcon from '@mui/icons-material/Pix';
import { useForm } from "react-hook-form";
import OtpModal from "../../components/OtpModal";
import StandardModal from "../../components/StandardModal";

interface Data extends StatementModel, DataRow { }

const headCells: readonly HeadCell<Data>[] = [

    {
        id: 'createdAt',
        numeric: false,
        disablePadding: false,
        label: 'Date',
    },
    {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: 'Description',
    },
    {
        id: 'type',
        numeric: false,
        disablePadding: false,
        label: 'Type',
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
    },
    {
        id: 'amount',
        numeric: true,
        disablePadding: false,
        label: 'Amount',
    },
];

function getTableRow(row: Data,
    isItemSelected: boolean,
    labelId: string,
    handleClick: (event: React.MouseEvent<unknown>, id: string) => void) {
    return (<TableRow
        hover
        onClick={(event) => handleClick(event, row.id)}
        // aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
        // selected={isItemSelected}
        sx={{
            cursor: 'pointer',
            display: {
                xs: 'flex',
                sm: 'table-row',
            },
            flexDirection: {
                xs: 'column',
                sm: 'row',
            },
        }}
    >
        <TableCell><HeadCell>Date</HeadCell>{helpers.timeStampToFormatedDateTime(row.createdAt)}</TableCell>
        <TableCell><HeadCell>Description</HeadCell>{row.description}</TableCell>
        <TableCell><HeadCell>Type</HeadCell>{row.type}</TableCell>
        <TableCell><HeadCell>Status</HeadCell>{row.status}</TableCell>
        <TableCell align="right"><HeadCell>Amount</HeadCell>{helpers.toLocalMoney(row.amount * (row.type === 'debit' ? -1 : 1))}</TableCell>
    </TableRow>
    )
}

const Statement: React.FC = () => {

    const [startDate, setStartDate] = useState<Dayjs | null>(dayjs().subtract(90, 'day'));
    const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());
    const [refetchEarnings, setRefetchEarnings] = useState(0);
    const [refetchStatement, setRefetchStatement] = useState(0);
    const [refetchAccount, setRefetchAccount] = useState(0);

    const { data: earnings } = useQuery({
        queryKey: ['earnings', refetchEarnings],
        queryFn: () => getEarnings(startDate?.format("YYYY-MM-DD"), endDate?.format("YYYY-MM-DD")),
        refetchOnWindowFocus: false,
        enabled: !!startDate && !!endDate
    });

    const { data: earningsAccount } = useQuery({
        queryKey: ['earningsAccount', refetchAccount],
        queryFn: getEarningsAccount,
        refetchOnWindowFocus: false
    });

    const { data: rows } = useQuery({
        queryKey: ['statement', refetchStatement],
        queryFn: async () => {
            const statement = await getStatement(startDate?.format("YYYY-MM-DD"), endDate?.format("YYYY-MM-DD"));
            return statement as Data[];
        },
        refetchOnWindowFocus: false,
        enabled: !!startDate && !!endDate
    });

    const handleRefresh = () => {

        //the end date can't be greater than today
        if (endDate && endDate.isAfter(dayjs())) {
            setEndDate(dayjs());
            toast.error('A data final não pode ser maior que hoje');
            return;
        }

        //the end date can't be less than the start date
        if (endDate && startDate && endDate.isBefore(startDate)) {
            setEndDate(startDate);
            toast.error('A data final não pode ser menor que a data inicial');
            return;
        }

        //the date interval can't be greater than 90 days
        if (endDate && startDate && Math.abs(startDate.diff(endDate, 'day')) > 90) {
            setStartDate(dayjs().subtract(90, 'day'));
            setEndDate(dayjs());
            toast.error('O intervalo não pode ser maior que 90 dias');
            return;
        }
        setRefetchEarnings(refetchEarnings + 1);
        setRefetchStatement(refetchStatement + 1);
        setFilterOpen(false);
    }


    const [filterOpen, setFilterOpen] = React.useState(false);
    const [editAccountOpen, setEditAccountOpen] = React.useState(false);
    const [confirmEditAccountOpen, setConfirmEditAccountOpen] = React.useState(false);
    const [confirmPayoutOpen, setConfirmPayoutOpen] = React.useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm<EditAccountRequest>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });

    const { mutate: handleEditAccount } = useMutation({
        mutationFn: editAccount,
        onSuccess: (data) => {
            setEditAccountOpen(false);
            setConfirmEditAccountOpen(true);
            reset();
        }
    });

    const handleConfirmEditAccount = (code: string) => {
        verifyEditAccount({ code })
            .then(() => {
                toast.success('Informações de saque atualizadas com sucesso');
                setConfirmEditAccountOpen(false);
                setRefetchAccount(refetchAccount + 1);
            }).catch(() => { });
    }

    const handlePayout = async () => {
        try {
            await requestPayout({
                startDate: startDate?.format("YYYY-MM-DD"),
                endDate: endDate?.format("YYYY-MM-DD")
            });
            setConfirmPayoutOpen(true);
        } catch (error) { }
    }

    const handleConfirmPayout = (code: string) => {
        verifyRequestPayout({ code })
            .then(() => {
                toast.success('Saque realizado com sucesso');
                setConfirmPayoutOpen(false);
                setRefetchEarnings(refetchEarnings + 1);
                setRefetchStatement(refetchStatement + 1);
            }).catch(() => { });
    }

    const handleFilterButtonClick = () => {
        setFilterOpen(true);
    }

    const handleCancelEditAccount = () => {
        reset();
        setEditAccountOpen(false);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <PrivateTemplate>
            <StandardModal openState={[filterOpen, setFilterOpen]} onClose={() => setFilterOpen(false)} >
                <Box sx={{ display: 'flex', flexDirection: 'column', p: 2, gap: 2 }} >
                    <Typography component="h1" variant="h5">
                        Filtrar extrato
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                        <DatePicker
                            label="Start date"
                            format="DD/MM/YYYY"
                            value={startDate}
                            onChange={(date) => setStartDate(date)}
                        />
                        <DatePicker
                            label="End date"
                            format="DD/MM/YYYY"
                            value={endDate}
                            onChange={(date) => setEndDate(date)}
                        />
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        gap: 2,
                    }}>
                        <Button
                            size="small"
                            variant="outlined"
                            onClick={() => setFilterOpen(false)}
                            sx={{ minWidth: '33%' }}>
                            Cancelar
                        </Button>
                        <Button
                            size="small"
                            variant="contained"
                            type='button'
                            onClick={handleRefresh}
                            sx={{ minWidth: '33%' }}>
                            Salvar
                        </Button>
                    </Box>
                </Box>
            </StandardModal>
            <OtpModal
                openState={[confirmPayoutOpen, setConfirmPayoutOpen]}
                onConfirm={handleConfirmPayout}
            />
            <OtpModal
                openState={[confirmEditAccountOpen, setConfirmEditAccountOpen]}
                onConfirm={handleConfirmEditAccount}
            />
            <StandardModal openState={[editAccountOpen, setEditAccountOpen]} onClose={() => setEditAccountOpen(false)} >
                <Box sx={{ display: 'flex', flexDirection: 'column', p: 2, gap: 2 }} >
                    <Typography component="h1" variant="h5">
                        Informe sua chave PIX
                    </Typography>
                    <Typography variant="body2">
                        1. A chave PIX deve pertencer ao titular da conta.<br />
                        2. Chaves aleatórias não são aceitas.
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2
                        }}
                        component="form" noValidate onSubmit={handleSubmit(values => handleEditAccount(values))}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            variant="outlined"
                            label="Chave PIX"
                            autoComplete="off"
                            placeholder="Ex. 12345678901"
                            autoFocus
                            {...register('pixKey', {
                                required: 'Digite sua chave PIX',
                                maxLength: {
                                    value: 60,
                                    message: 'Chave PIX deve ter no máximo 60 caracteres',
                                },
                            })}
                        />
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}>
                            <Button
                                size="small"
                                variant="outlined"
                                onClick={handleCancelEditAccount}
                                sx={{ minWidth: '33%' }}>
                                Cancelar
                            </Button>
                            <Button
                                size="small"
                                variant="contained"
                                type='submit'
                                sx={{ minWidth: '33%' }}>
                                Salvar
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </StandardModal>
            <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, borderRight: 'var(--border)' }}>
                <Header sx={{ maxWidth: '100%' }}>
                    <Breadcrumb title="EXTRATO" url='/settings/earnings' />
                </Header>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', px: 4, py: 4, gap: 2 }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            gap: 2
                        }}>
                            <Card variant="outlined" sx={{ flexGrow: 2 }}>
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Valor total
                                    </Typography>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        flexDirection: {
                                            xs: 'column',
                                            sm: 'column',
                                            md: 'row',

                                        }
                                    }}>
                                        <Typography variant="h5" component="div">
                                            Pendente
                                        </Typography>
                                        <Typography variant="h5">
                                            {helpers.toLocalMoney(earnings?.pending)}
                                        </Typography>
                                    </Box>
                                    <Typography variant="body2" sx={{ mt: 2, maxWidth: '300px' }}>
                                        Os valores pendentes ficam disponíveis 7 dias após a data de lançamento
                                    </Typography>
                                </CardContent>
                            </Card>
                            <Card variant="outlined" sx={{ flexGrow: 2 }}>
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Valor total
                                    </Typography>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        flexDirection: {
                                            xs: 'column',
                                            sm: 'column',
                                            md: 'row',

                                        }
                                    }}>
                                        <Typography component="h1" variant="h5">
                                            Disponível
                                        </Typography>
                                        <Typography variant="h5">
                                            {helpers.toLocalMoney(earnings?.available)}
                                        </Typography>
                                    </Box>
                                    <Typography variant="body2" sx={{ mt: 2, maxWidth: '300px' }}>
                                        Valor disponível referente ao período selecionado, para exibir outros períodos clique no ícone de filtro acima da tabela
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', mt: 2 }}>
                                        <Tooltip title="Sacar valor disponível">
                                            <Button
                                                variant="contained"
                                                size="small"
                                                onClick={handlePayout}
                                                disabled={earnings?.available === 0 || !earningsAccount} >
                                                Sacar
                                            </Button>
                                        </Tooltip>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            gap: 2
                        }}>
                            <Card variant="outlined" sx={{ flexGrow: 1 }}>
                                <CardContent>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        flexDirection: 'row'
                                    }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                                            {earningsAccount?.pixKey ? <PixIcon sx={{ fontSize: 72, color: "text.secondary" }} /> : <AccountBalanceRoundedIcon sx={{ fontSize: 72, color: "text.secondary" }} />}
                                            <Box>
                                                <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
                                                    Informações de recebimento de saque
                                                </Typography>
                                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                    {earningsAccount?.pixKey || "Sua conta para saque ainda não foi configurada"}
                                                </Typography>
                                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                    {earningsAccount?.pixKeyType || "Clique em Editar para configurar"}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                            <Tooltip title="Editar conta para saque">
                                                <Button
                                                    size="small"
                                                    onClick={() => setEditAccountOpen(true)} >
                                                    Editar
                                                </Button>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box>
                    </Box>
                    {rows && <EnhancedTable
                        headCells={headCells}
                        getTableRow={getTableRow}
                        rows={rows}
                        title="Extrato"
                        dense
                        onFilterButtonClick={handleFilterButtonClick}
                    />}
                </Box>
                <Box sx={{
                    height: '100px' // botton navigator spacing           
                }} ></Box>
            </Box>
        </PrivateTemplate>
    );
}

export default Statement;

