import { AddressModel } from "../types/AddressModel";
import { PaymentMethodModel } from "../types/PaymentMethodModel";
import { SubscriptionModel } from "../types/SubscriptionModel";
import { api } from "./api";

export const setupSubscriptionProduct = (data: any): Promise<any> => {
    return api.post<any>(`settings/subscription/product`, data).then((res) => res.data);
}

export const getSubscriptionProduct = (): Promise<any> => {
    return api.get<any>(`settings/subscription/product`).then((res) => res.data);
}

export const getSubscribers = (): Promise<SubscriptionModel[]> => {
    return api.get<SubscriptionModel[]>(`settings/subscribers`).then((res) => res.data);
}

export const getSubscriptions = (): Promise<SubscriptionModel[]> => {
    return api.get<SubscriptionModel[]>(`settings/subscriptions`).then((res) => res.data);
}

export const cancelSubscription = (data: { artistId?: string}): Promise<any> => {
    return api.post<any>(`settings/subscription/cancel`, data).then((res) => res.data);
}

export const getEarnings = (startDate?: string, endDate?: string): Promise<any> => {
    return api.get<any>(`settings/earnings/${startDate}/${endDate}`).then((res) => res.data);
}

export const getStatement = (startDate?: string, endDate?: string): Promise<any> => {
    return api.get<any>(`settings/earnings/statement/${startDate}/${endDate}`).then((res) => res.data);
}

export const requestPayout = (data: any): Promise<any> => {
    return api.post<any>(`settings/earnings/request-payout`, data).then((res) => res.data);
}

export const verifyRequestPayout = (data: any): Promise<any> => {
    return api.post<any>(`settings/earnings/verify-request-payout`, data).then((res) => res.data);
}

export type EditAccountRequest = {
    pixKey: string;
}

export const editAccount = (data: EditAccountRequest): Promise<any> => {
    return api.post<any>(`settings/earnings/edit-account`, data).then((res) => res.data);
}

export const verifyEditAccount = (data: any): Promise<any> => {
    return api.post<any>(`settings/earnings/verify-edit-account`, data).then((res) => res.data);
}

export const getEarningsAccount = (): Promise<any> => {
    return api.get<any>(`settings/earnings/account`).then((res) => res.data);
}


export type CardForm = {
    number: string;
    cvv: string;
    expMonth: string;
    expYear: string;
    holderName: string;
    holderDocument: string;  
  }
  
  export type CardRequest = {
    hash: string;
    cvv: string;  
    holderName: string;
    holderDocument: string; 
  }
  
  export type PixPaymentRequest = {
    paymentMethodId: string;
    fullName: string;
    countryCode: string;
    phone: string;    
    document: string;
  }

  export type PaymentRequest = {
    fullName: string;
    countryCode: string;
    phone: string;
    card: CardRequest | CardForm;
    billingAddress: AddressModel;
  }

export const getPaymentMethods = (): Promise<Array<PaymentMethodModel>> => {
    return api.get(`settings/user/payment-methods`).then((res) => res.data);
}

export const addPaymentMethod = (data: PaymentRequest | PixPaymentRequest): Promise<any> => {
    return api.post(`settings/user/payment-methods`, data).then((res) => res.data);
}

export const deletePaymentMethod = (paymentMethodId?: string): Promise<any> => {
    return api.delete(`settings/user/payment-methods/${paymentMethodId}`).then((res) => res.data);
}

export const setDefaultPaymentMethod = (paymentMethodId?: string): Promise<any> => {
    return api.put(`settings/user/payment-methods/${paymentMethodId}/default`).then((res) => res.data);
}

export type SubscribeRequest = {
    artistId: string;
    paymentMethodId: string;
  }
  
  export const subscribe = (data: SubscribeRequest): Promise<any> => {
    return api.post<any>(`settings/artist/subscribe`, data).then((res) => res.data);
  }