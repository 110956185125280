import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

type CountrySelectProps = {
	defaultValue?: string;
	onChange?: (event: React.ChangeEvent<{}>, newValue: CountryType | null) => void;
}

export default function CountrySelect({ defaultValue, onChange }: CountrySelectProps): JSX.Element {

	return (
		<Autocomplete
			disableClearable
			fullWidth
			options={countries}
			autoHighlight
			defaultValue={ defaultValue ? countries.find((country) => country.value === defaultValue) : undefined }
			getOptionLabel={(option: CountryType) => `${option.label} +${option.code}`}
			renderOption={(props, option: CountryType) => (
				<Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
					<img
						loading="lazy"
						width="20"
						srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`}
						src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`}
						alt=""
					/>
					{option.label} +{option.code}
				</Box>
			)}
			renderInput={(params) => (
				<TextField
					{...params}
					label="País"
					inputProps={{
						...params.inputProps,
						autoComplete: 'new-password', // disable autocomplete and autofill
					}}
				/>
			)}
			{...{onChange}}
		/>
	);
}

interface CountryType {
	value: string;
	iso: string;
	label: string;
	code: string;
	suggested?: boolean;
}

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
const countries: readonly CountryType[] = [
	{value:"AW",iso:"🇦🇼",label:"Aruba",code:"297"},
	{value:"AF",iso:"🇦🇫",label:"Afghanistan",code:"93"},
	{value:"AO",iso:"🇦🇴",label:"Angola",code:"244"},
	{value:"AI",iso:"🇦🇮",label:"Anguilla",code:"1"},
	{value:"AX",iso:"🇦🇽",label:"Åland Islands",code:"358"},
	{value:"AL",iso:"🇦🇱",label:"Albania",code:"355"},
	{value:"AD",iso:"🇦🇩",label:"Andorra",code:"376"},
	{value:"AE",iso:"🇦🇪",label:"United Arab Emirates",code:"971"},
	{value:"AR",iso:"🇦🇷",label:"Argentina",code:"54"},
	{value:"AM",iso:"🇦🇲",label:"Armenia",code:"374"},
	{value:"AS",iso:"🇦🇸",label:"American Samoa",code:"1"},
	{value:"AQ",iso:"🇦🇶",label:"Antarctica",code:"672"},
	{value:"TF",iso:"🇹🇫",label:"French Southern and Antarctic Lands",code:"262"},
	{value:"AG",iso:"🇦🇬",label:"Antigua and Barbuda",code:"1"},
	{value:"AU",iso:"🇦🇺",label:"Australia",code:"61"},
	{value:"AT",iso:"🇦🇹",label:"Austria",code:"43"},
	{value:"AZ",iso:"🇦🇿",label:"Azerbaijan",code:"994"},
	{value:"BI",iso:"🇧🇮",label:"Burundi",code:"257"},
	{value:"BE",iso:"🇧🇪",label:"Belgium",code:"32"},
	{value:"BJ",iso:"🇧🇯",label:"Benin",code:"229"},
	{value:"BF",iso:"🇧🇫",label:"Burkina Faso",code:"226"},
	{value:"BD",iso:"🇧🇩",label:"Bangladesh",code:"880"},
	{value:"BG",iso:"🇧🇬",label:"Bulgaria",code:"359"},
	{value:"BH",iso:"🇧🇭",label:"Bahrain",code:"973"},
	{value:"BS",iso:"🇧🇸",label:"Bahamas",code:"1"},
	{value:"BA",iso:"🇧🇦",label:"Bosnia and Herzegovina",code:"387"},
	{value:"BL",iso:"🇧🇱",label:"Saint Barthélemy",code:"590"},
	{value:"SH",iso:"🇸🇭",label:"Saint Helena, Ascension and Tristan da Cunha",code:"290"},
	{value:"BY",iso:"🇧🇾",label:"Belarus",code:"375"},
	{value:"BZ",iso:"🇧🇿",label:"Belize",code:"501"},
	{value:"BM",iso:"🇧🇲",label:"Bermuda",code:"1"},
	{value:"BO",iso:"🇧🇴",label:"Bolivia",code:"591"},
	{value:"BR",iso:"🇧🇷",label:"Brazil",code:"55"},
	{value:"BB",iso:"🇧🇧",label:"Barbados",code:"1"},
	{value:"BN",iso:"🇧🇳",label:"Brunei",code:"673"},
	{value:"BT",iso:"🇧🇹",label:"Bhutan",code:"975"},
	{value:"BV",iso:"🇧🇻",label:"Bouvet Island",code:""},
	{value:"BW",iso:"🇧🇼",label:"Botswana",code:"267"},
	{value:"CF",iso:"🇨🇫",label:"Central African Republic",code:"236"},
	{value:"CA",iso:"🇨🇦",label:"Canada",code:"1"},
	{value:"CC",iso:"🇨🇨",label:"Cocos (Keeling) Islands",code:"61"},
	{value:"CH",iso:"🇨🇭",label:"Switzerland",code:"41"},
	{value:"CL",iso:"🇨🇱",label:"Chile",code:"56"},
	{value:"CN",iso:"🇨🇳",label:"China",code:"86"},
	{value:"CI",iso:"🇨🇮",label:"Ivory Coast",code:"225"},
	{value:"CM",iso:"🇨🇲",label:"Cameroon",code:"237"},
	{value:"CD",iso:"🇨🇩",label:"DR Congo",code:"243"},
	{value:"CG",iso:"🇨🇬",label:"Republic of the Congo",code:"242"},
	{value:"CK",iso:"🇨🇰",label:"Cook Islands",code:"682"},
	{value:"CO",iso:"🇨🇴",label:"Colombia",code:"57"},
	{value:"KM",iso:"🇰🇲",label:"Comoros",code:"269"},
	{value:"CV",iso:"🇨🇻",label:"Cape Verde",code:"238"},
	{value:"CR",iso:"🇨🇷",label:"Costa Rica",code:"506"},
	{value:"CU",iso:"🇨🇺",label:"Cuba",code:"53"},
	{value:"CW",iso:"🇨🇼",label:"Curaçao",code:"599"},
	{value:"CX",iso:"🇨🇽",label:"Christmas Island",code:"61"},
	{value:"KY",iso:"🇰🇾",label:"Cayman Islands",code:"1"},
	{value:"CY",iso:"🇨🇾",label:"Cyprus",code:"357"},
	{value:"CZ",iso:"🇨🇿",label:"Czechia",code:"420"},
	{value:"DE",iso:"🇩🇪",label:"Germany",code:"49"},
	{value:"DJ",iso:"🇩🇯",label:"Djibouti",code:"253"},
	{value:"DM",iso:"🇩🇲",label:"Dominica",code:"1"},
	{value:"DK",iso:"🇩🇰",label:"Denmark",code:"45"},
	{value:"DO",iso:"🇩🇴",label:"Dominican Republic",code:"1"},
	{value:"DZ",iso:"🇩🇿",label:"Algeria",code:"213"},
	{value:"EC",iso:"🇪🇨",label:"Ecuador",code:"593"},
	{value:"EG",iso:"🇪🇬",label:"Egypt",code:"20"},
	{value:"ER",iso:"🇪🇷",label:"Eritrea",code:"291"},
	{value:"EH",iso:"🇪🇭",label:"Western Sahara",code:"212"},
	{value:"ES",iso:"🇪🇸",label:"Spain",code:"34"},
	{value:"EE",iso:"🇪🇪",label:"Estonia",code:"372"},
	{value:"ET",iso:"🇪🇹",label:"Ethiopia",code:"251"},
	{value:"FI",iso:"🇫🇮",label:"Finland",code:"358"},
	{value:"FJ",iso:"🇫🇯",label:"Fiji",code:"679"},
	{value:"FK",iso:"🇫🇰",label:"Falkland Islands",code:"500"},
	{value:"FR",iso:"🇫🇷",label:"France",code:"33"},
	{value:"FO",iso:"🇫🇴",label:"Faroe Islands",code:"298"},
	{value:"FM",iso:"🇫🇲",label:"Micronesia",code:"691"},
	{value:"GA",iso:"🇬🇦",label:"Gabon",code:"241"},
	{value:"GB",iso:"🇬🇧",label:"United Kingdom",code:"44"},
	{value:"GE",iso:"🇬🇪",label:"Georgia",code:"995"},
	{value:"GG",iso:"🇬🇬",label:"Guernsey",code:"44"},
	{value:"GH",iso:"🇬🇭",label:"Ghana",code:"233"},
	{value:"GI",iso:"🇬🇮",label:"Gibraltar",code:"350"},
	{value:"GN",iso:"🇬🇳",label:"Guinea",code:"224"},
	{value:"GP",iso:"🇬🇵",label:"Guadeloupe",code:"590"},
	{value:"GM",iso:"🇬🇲",label:"Gambia",code:"220"},
	{value:"GW",iso:"🇬🇼",label:"Guinea-Bissau",code:"245"},
	{value:"GQ",iso:"🇬🇶",label:"Equatorial Guinea",code:"240"},
	{value:"GR",iso:"🇬🇷",label:"Greece",code:"30"},
	{value:"GD",iso:"🇬🇩",label:"Grenada",code:"1"},
	{value:"GL",iso:"🇬🇱",label:"Greenland",code:"299"},
	{value:"GT",iso:"🇬🇹",label:"Guatemala",code:"502"},
	{value:"GF",iso:"🇬🇫",label:"French Guiana",code:"594"},
	{value:"GU",iso:"🇬🇺",label:"Guam",code:"1"},
	{value:"GY",iso:"🇬🇾",label:"Guyana",code:"592"},
	{value:"HK",iso:"🇭🇰",label:"Hong Kong",code:"852"},
	{value:"HM",iso:"🇭🇲",label:"Heard Island and McDonald Islands",code:"672"},
	{value:"HN",iso:"🇭🇳",label:"Honduras",code:"504"},
	{value:"HR",iso:"🇭🇷",label:"Croatia",code:"385"},
	{value:"HT",iso:"🇭🇹",label:"Haiti",code:"509"},
	{value:"HU",iso:"🇭🇺",label:"Hungary",code:"36"},
	{value:"ID",iso:"🇮🇩",label:"Indonesia",code:"62"},
	{value:"IM",iso:"🇮🇲",label:"Isle of Man",code:"44"},
	{value:"IN",iso:"🇮🇳",label:"India",code:"91"},
	{value:"IO",iso:"🇮🇴",label:"British Indian Ocean Territory",code:"246"},
	{value:"IE",iso:"🇮🇪",label:"Ireland",code:"353"},
	{value:"IR",iso:"🇮🇷",label:"Iran",code:"98"},
	{value:"IQ",iso:"🇮🇶",label:"Iraq",code:"964"},
	{value:"IS",iso:"🇮🇸",label:"Iceland",code:"354"},
	{value:"IL",iso:"🇮🇱",label:"Israel",code:"972"},
	{value:"IT",iso:"🇮🇹",label:"Italy",code:"39"},
	{value:"JM",iso:"🇯🇲",label:"Jamaica",code:"1"},
	{value:"JE",iso:"🇯🇪",label:"Jersey",code:"44"},
	{value:"JO",iso:"🇯🇴",label:"Jordan",code:"962"},
	{value:"JP",iso:"🇯🇵",label:"Japan",code:"81"},
	{value:"KZ",iso:"🇰🇿",label:"Kazakhstan",code:"7"},
	{value:"KE",iso:"🇰🇪",label:"Kenya",code:"254"},
	{value:"KG",iso:"🇰🇬",label:"Kyrgyzstan",code:"996"},
	{value:"KH",iso:"🇰🇭",label:"Cambodia",code:"855"},
	{value:"KI",iso:"🇰🇮",label:"Kiribati",code:"686"},
	{value:"KN",iso:"🇰🇳",label:"Saint Kitts and Nevis",code:"1"},
	{value:"KR",iso:"🇰🇷",label:"South Korea",code:"82"},
	{value:"XK",iso:"🇽🇰",label:"Kosovo",code:"383"},
	{value:"KW",iso:"🇰🇼",label:"Kuwait",code:"965"},
	{value:"LA",iso:"🇱🇦",label:"Laos",code:"856"},
	{value:"LB",iso:"🇱🇧",label:"Lebanon",code:"961"},
	{value:"LR",iso:"🇱🇷",label:"Liberia",code:"231"},
	{value:"LY",iso:"🇱🇾",label:"Libya",code:"218"},
	{value:"LC",iso:"🇱🇨",label:"Saint Lucia",code:"1"},
	{value:"LI",iso:"🇱🇮",label:"Liechtenstein",code:"423"},
	{value:"LK",iso:"🇱🇰",label:"Sri Lanka",code:"94"},
	{value:"LS",iso:"🇱🇸",label:"Lesotho",code:"266"},
	{value:"LT",iso:"🇱🇹",label:"Lithuania",code:"370"},
	{value:"LU",iso:"🇱🇺",label:"Luxembourg",code:"352"},
	{value:"LV",iso:"🇱🇻",label:"Latvia",code:"371"},
	{value:"MO",iso:"🇲🇴",label:"Macau",code:"853"},
	{value:"MF",iso:"🇲🇫",label:"Saint Martin",code:"590"},
	{value:"MA",iso:"🇲🇦",label:"Morocco",code:"212"},
	{value:"MC",iso:"🇲🇨",label:"Monaco",code:"377"},
	{value:"MD",iso:"🇲🇩",label:"Moldova",code:"373"},
	{value:"MG",iso:"🇲🇬",label:"Madagascar",code:"261"},
	{value:"MV",iso:"🇲🇻",label:"Maldives",code:"960"},
	{value:"MX",iso:"🇲🇽",label:"Mexico",code:"52"},
	{value:"MH",iso:"🇲🇭",label:"Marshall Islands",code:"692"},
	{value:"MK",iso:"🇲🇰",label:"North Macedonia",code:"389"},
	{value:"ML",iso:"🇲🇱",label:"Mali",code:"223"},
	{value:"MT",iso:"🇲🇹",label:"Malta",code:"356"},
	{value:"MM",iso:"🇲🇲",label:"Myanmar",code:"95"},
	{value:"ME",iso:"🇲🇪",label:"Montenegro",code:"382"},
	{value:"MN",iso:"🇲🇳",label:"Mongolia",code:"976"},
	{value:"MP",iso:"🇲🇵",label:"Northern Mariana Islands",code:"1"},
	{value:"MZ",iso:"🇲🇿",label:"Mozambique",code:"258"},
	{value:"MR",iso:"🇲🇷",label:"Mauritania",code:"222"},
	{value:"MS",iso:"🇲🇸",label:"Montserrat",code:"1"},
	{value:"MQ",iso:"🇲🇶",label:"Martinique",code:"596"},
	{value:"MU",iso:"🇲🇺",label:"Mauritius",code:"230"},
	{value:"MW",iso:"🇲🇼",label:"Malawi",code:"265"},
	{value:"MY",iso:"🇲🇾",label:"Malaysia",code:"60"},
	{value:"YT",iso:"🇾🇹",label:"Mayotte",code:"262"},
	{value:"NA",iso:"🇳🇦",label:"Namibia",code:"264"},
	{value:"NC",iso:"🇳🇨",label:"New Caledonia",code:"687"},
	{value:"NE",iso:"🇳🇪",label:"Niger",code:"227"},
	{value:"NF",iso:"🇳🇫",label:"Norfolk Island",code:"672"},
	{value:"NG",iso:"🇳🇬",label:"Nigeria",code:"234"},
	{value:"NI",iso:"🇳🇮",label:"Nicaragua",code:"505"},
	{value:"NU",iso:"🇳🇺",label:"Niue",code:"683"},
	{value:"NL",iso:"🇳🇱",label:"Netherlands",code:"31"},
	{value:"NO",iso:"🇳🇴",label:"Norway",code:"47"},
	{value:"NP",iso:"🇳🇵",label:"Nepal",code:"977"},
	{value:"NR",iso:"🇳🇷",label:"Nauru",code:"674"},
	{value:"NZ",iso:"🇳🇿",label:"New Zealand",code:"64"},
	{value:"OM",iso:"🇴🇲",label:"Oman",code:"968"},
	{value:"PK",iso:"🇵🇰",label:"Pakistan",code:"92"},
	{value:"PA",iso:"🇵🇦",label:"Panama",code:"507"},
	{value:"PN",iso:"🇵🇳",label:"Pitcairn Islands",code:"64"},
	{value:"PE",iso:"🇵🇪",label:"Peru",code:"51"},
	{value:"PH",iso:"🇵🇭",label:"Philippines",code:"63"},
	{value:"PW",iso:"🇵🇼",label:"Palau",code:"680"},
	{value:"PG",iso:"🇵🇬",label:"Papua New Guinea",code:"675"},
	{value:"PL",iso:"🇵🇱",label:"Poland",code:"48"},
	{value:"PR",iso:"🇵🇷",label:"Puerto Rico",code:"1"},
	{value:"KP",iso:"🇰🇵",label:"North Korea",code:"850"},
	{value:"PT",iso:"🇵🇹",label:"Portugal",code:"351"},
	{value:"PY",iso:"🇵🇾",label:"Paraguay",code:"595"},
	{value:"PS",iso:"🇵🇸",label:"Palestine",code:"970"},
	{value:"PF",iso:"🇵🇫",label:"French Polynesia",code:"689"},
	{value:"QA",iso:"🇶🇦",label:"Qatar",code:"974"},
	{value:"RE",iso:"🇷🇪",label:"Réunion",code:"262"},
	{value:"RO",iso:"🇷🇴",label:"Romania",code:"40"},
	{value:"RU",iso:"🇷🇺",label:"Russia",code:"7"},
	{value:"RW",iso:"🇷🇼",label:"Rwanda",code:"250"},
	{value:"SA",iso:"🇸🇦",label:"Saudi Arabia",code:"966"},
	{value:"SD",iso:"🇸🇩",label:"Sudan",code:"249"},
	{value:"SN",iso:"🇸🇳",label:"Senegal",code:"221"},
	{value:"SG",iso:"🇸🇬",label:"Singapore",code:"65"},
	{value:"GS",iso:"🇬🇸",label:"South Georgia",code:"500"},
	{value:"SJ",iso:"🇸🇯",label:"Svalbard and Jan Mayen",code:"47"},
	{value:"SB",iso:"🇸🇧",label:"Solomon Islands",code:"677"},
	{value:"SL",iso:"🇸🇱",label:"Sierra Leone",code:"232"},
	{value:"SV",iso:"🇸🇻",label:"El Salvador",code:"503"},
	{value:"SM",iso:"🇸🇲",label:"San Marino",code:"378"},
	{value:"SO",iso:"🇸🇴",label:"Somalia",code:"252"},
	{value:"PM",iso:"🇵🇲",label:"Saint Pierre and Miquelon",code:"508"},
	{value:"RS",iso:"🇷🇸",label:"Serbia",code:"381"},
	{value:"SS",iso:"🇸🇸",label:"South Sudan",code:"211"},
	{value:"ST",iso:"🇸🇹",label:"São Tomé and Príncipe",code:"239"},
	{value:"SR",iso:"🇸🇷",label:"Suriname",code:"597"},
	{value:"SK",iso:"🇸🇰",label:"Slovakia",code:"421"},
	{value:"SI",iso:"🇸🇮",label:"Slovenia",code:"386"},
	{value:"SE",iso:"🇸🇪",label:"Sweden",code:"46"},
	{value:"SZ",iso:"🇸🇿",label:"Eswatini",code:"268"},
	{value:"SX",iso:"🇸🇽",label:"Sint Maarten",code:"1"},
	{value:"SC",iso:"🇸🇨",label:"Seychelles",code:"248"},
	{value:"SY",iso:"🇸🇾",label:"Syria",code:"963"},
	{value:"TC",iso:"🇹🇨",label:"Turks and Caicos Islands",code:"1"},
	{value:"TD",iso:"🇹🇩",label:"Chad",code:"235"},
	{value:"TG",iso:"🇹🇬",label:"Togo",code:"228"},
	{value:"TH",iso:"🇹🇭",label:"Thailand",code:"66"},
	{value:"TJ",iso:"🇹🇯",label:"Tajikistan",code:"992"},
	{value:"TK",iso:"🇹🇰",label:"Tokelau",code:"690"},
	{value:"TM",iso:"🇹🇲",label:"Turkmenistan",code:"993"},
	{value:"TL",iso:"🇹🇱",label:"East Timor",code:"670"},
	{value:"TO",iso:"🇹🇴",label:"Tonga",code:"676"},
	{value:"TT",iso:"🇹🇹",label:"Trinidad and Tobago",code:"1"},
	{value:"TN",iso:"🇹🇳",label:"Tunisia",code:"216"},
	{value:"TR",iso:"🇹🇷",label:"Turkey",code:"90"},
	{value:"TV",iso:"🇹🇻",label:"Tuvalu",code:"688"},
	{value:"TW",iso:"🇹🇼",label:"Taiwan",code:"886"},
	{value:"TZ",iso:"🇹🇿",label:"Tanzania",code:"255"},
	{value:"UG",iso:"🇺🇬",label:"Uganda",code:"256"},
	{value:"UA",iso:"🇺🇦",label:"Ukraine",code:"380"},
	{value:"UY",iso:"🇺🇾",label:"Uruguay",code:"598"},
	{value:"US",iso:"🇺🇸",label:"United States",code:"1"},
	{value:"UZ",iso:"🇺🇿",label:"Uzbekistan",code:"998"},
	{value:"VA",iso:"🇻🇦",label:"Vatican City",code:"39"},
	{value:"VC",iso:"🇻🇨",label:"Saint Vincent and the Grenadines",code:"1"},
	{value:"VE",iso:"🇻🇪",label:"Venezuela",code:"58"},
	{value:"VG",iso:"🇻🇬",label:"British Virgin Islands",code:"1"},
	{value:"VI",iso:"🇻🇮",label:"United States Virgin Islands",code:"1"},
	{value:"VN",iso:"🇻🇳",label:"Vietnam",code:"84"},
	{value:"VU",iso:"🇻🇺",label:"Vanuatu",code:"678"},
	{value:"WF",iso:"🇼🇫",label:"Wallis and Futuna",code:"681"},
	{value:"WS",iso:"🇼🇸",label:"Samoa",code:"685"},
	{value:"YE",iso:"🇾🇪",label:"Yemen",code:"967"},
	{value:"ZA",iso:"🇿🇦",label:"South Africa",code:"27"},
	{value:"ZM",iso:"🇿🇲",label:"Zambia",code:"260"},
	{value:"ZW",iso:"🇿🇼",label:"Zimbabwe",code:"263"}
	]
	;
