import { Avatar, Box, IconButton } from "@mui/material";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { UserModel } from "../../types/UserModel";
import React from "react";
import * as constants from '../../utils/constants'
import { Gallery, Item } from "react-photoswipe-gallery";
import { ArtistAvatar } from "../ArtistAvatar";


type ArtistProfileTopProps = {
  artist?: UserModel;
  onAvatarButtonClick?: () => void;
  onBannerButtonClick?: () => void;
}

const ArtistProfileTop = ({ artist,
  onAvatarButtonClick,
  onBannerButtonClick }: ArtistProfileTopProps) => {

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'center',
        width: '100%',
        position: 'relative'
      }}
    >
      <Box sx={{
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '200px',
        backgroundColor: 'secondary.main',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        overflow: 'hidden',
        backgroundImage: `url(${`${constants.BASE_IMAGE_URL}/${artist?.banner?.standard?.path}`})`
      }}>
        {onBannerButtonClick && <IconButton
          style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
          onClick={onBannerButtonClick}
          color='primary'>
          <CameraAltOutlinedIcon />
        </IconButton>}
      </Box>
      <Box sx={{
        mt: 19,
        ml: 2,
        position: 'relative',
      }}>
        <Gallery options={{ showHideAnimationType: 'fade', zoom: false }}>
          <Item
            original={`${constants.BASE_IMAGE_URL}/${artist?.avatar?.original?.path}`}
            thumbnail={`${constants.BASE_IMAGE_URL}/${artist?.avatar?.standard?.path}`}
            width={artist?.avatar?.original?.width}
            height={artist?.avatar?.original?.height}>
            {({ ref, open }) => (
              <ArtistAvatar user={artist} sx={{
                width: '100px',
                height: '100px',
                boxShadow: "0 0 0 2px #fff",
              }} onClick={open} />
            )}
          </Item>
        </Gallery>
        {onAvatarButtonClick && <IconButton
          style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
          onClick={onAvatarButtonClick}
          color='primary'>
          <CameraAltOutlinedIcon />
        </IconButton>}
      </Box>
    </Box>
  )
}

export default ArtistProfileTop;