import videojs from "video.js";
require('@silvermine/videojs-quality-selector')(videojs);
import { PostModel } from "../types/PostModel";

export const moderateContent = (content: string) => {
  return content;
}

export const convertToMegaBytes = (bytes: number) => {
  return `${(bytes < 1024 ? 0.01 : bytes / 1000000).toFixed(2)} MB`
}

export const optmizeImage = (base64: string, maxSize: number): Promise<any> => {
  return new Promise<any>((resolve) => {
    const image: HTMLImageElement = new Image();
    image.onload = () => {
      const canvas: HTMLCanvasElement = document.createElement('canvas');
      let width: number = image.width;
      let height: number = image.height;
      if (width > height) {
        if (width > maxSize) {
          height *= maxSize / width;
          width = maxSize;
        }
      } else {
        if (height > maxSize) {
          width *= maxSize / height;
          height = maxSize;
        }
      }

      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d')?.drawImage(image, 0, 0, width, height);

      resolve({
        dataUrl: canvas.toDataURL('image/jpeg'),
        height,
        width,
        type: 'image/jpeg',
      })
    }
    image.src = base64;
  })
}

export const optmizeImageSquare = (base64: string, maxSize: number): Promise<any> => {
  return new Promise<any>((resolve) => {
    const image: HTMLImageElement = new Image();
    image.onload = () => {
      const canvas: HTMLCanvasElement = document.createElement('canvas');
      let width: number = image.width;
      let height: number = image.height;
      let x = 0;
      let y = 0;

      // Calculate dimensions and position to fit the image within the square
      if (width > height) {
        height = maxSize;
        width = (maxSize * image.width) / image.height;
        x = -(width - maxSize) / 2;
      } else {
        width = maxSize;
        height = (maxSize * image.height) / image.width;
        y = -(height - maxSize) / 2;
      }

      canvas.width = maxSize;
      canvas.height = maxSize;
      const context = canvas.getContext('2d')

      if (context) {
        context.fillStyle = 'white';
        context.fillRect(0, 0, maxSize, maxSize)
        context.drawImage(image, x, y, width, height)
        resolve({
          dataUrl: canvas.toDataURL('image/jpeg'),
          height: width,
          width,
          type: 'image/jpeg',
        }) // width == height
      }
    }
    image.src = base64;
  })
}

const takeShotFromVideo = (video: HTMLVideoElement): Promise<string> => {
  return new Promise((resolve) => {
    const canvas = document.createElement('canvas');
    video.onplaying = () => {
      const maxSize = 960;
      let width: number = video.videoWidth;
      let height: number = video.videoHeight;
      if (width > height) {
        if (width > maxSize) {
          height *= maxSize / width;
          width = maxSize;
        }
      } else {
        if (height > maxSize) {
          width *= maxSize / height;
          height = maxSize;
        }
      }

      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d')?.drawImage(video, 0, 0, width, height);
      const dataUrl = canvas.toDataURL('image/jpeg');
      video.pause();
      canvas.remove();
      resolve(dataUrl);
    };
    video.play();
  });
}

export const getVideoShot = (base64: string, time: number): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    const video = document.createElement('video');

    video.onloadeddata = async () => {
      video.currentTime = time;
      const shot = await takeShotFromVideo(video);
      video.remove();
      resolve(shot);
    }
    video.src = base64;
  });
}

export const createGalleryVideo = (element: HTMLElement, post: PostModel) => {
  if (post?.assets.at(0)?.type !== 'video')
    return
  setTimeout(() => {
    let wrapper = element.parentElement?.parentElement;

    if (wrapper && !wrapper.querySelector('video-js')) {
      const videoElement = document.createElement("video-js");
      const options = {
        controlBar: {
          children: [
            'playToggle',
            'progressControl',
            'volumePanel',
            'qualitySelector',
            'fullscreenToggle',
          ],
        },
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        preload: 'none',
        poster: post?.assets.at(0)?.poster?.original?.path,
        sources: [{
          src: post?.assets.at(0)?.original?.path,
          type: 'video/mp4',
          label: 'original',
          res: 1080
        },
        {
          src: post?.assets.at(0)?.standard?.path,
          type: 'video/mp4',
          label: '720p',
          res: 720,
          selected: true
        },
        {
          src: post?.assets.at(0)?.small?.path,
          type: 'video/mp4',
          label: '240p',
          res: 240
        }
        ],
        html5: {
          vhs: {
            withCredentials: true
          }
        }
      }
      const player = videojs(videoElement, options as any);
      player.autoplay(options.autoplay);
      player.src(options.sources);
      videoElement.classList.add('vjs-gallery')
      wrapper.appendChild(videoElement)
    }

  }, 500)
}

export const destroyGalleryVideo = (element: HTMLElement, post: PostModel) => {
  if (post?.assets.at(0)?.type !== 'video')
    return
  setTimeout(() => {
    let wrapper = element.parentElement?.parentElement
    wrapper?.querySelector('video-js')?.remove()
  }, 500)
}

const addLeadingZero = (number: any) => {
  return ("0" + number).slice(-2);
}

export const timeStampToFormatedDateTime = (timeStamp?: number): string => {

  if (!timeStamp) return '';

  const date = new Date(timeStamp * 1000)
  // return dd/MM/yyyy HH:mm
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  const hours = date.getHours()
  const minutes = date.getMinutes()
  return `${addLeadingZero(day)}/${addLeadingZero(month)}/${year} ${addLeadingZero(hours)}:${addLeadingZero(minutes)}`
}

export const timeStampToFormatedDate = (timeStamp?: number): string => {

  if (!timeStamp) return '';

  const date = new Date(timeStamp * 1000)
  // return dd/MM/yyyy HH:mm
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  return `${addLeadingZero(day)}/${addLeadingZero(month)}/${year}`
}

export const toLocalMoney = (value?: number): string => {
  if (value === null || value === undefined) return '';

  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value);
}

const verifierDigitCNPJ = (digits: string): number => {
  let index: number = 2;
  const reverse: Array<any> = digits
    .split('')
    .reduce((buffer: any, number: any) => {
      return [parseInt(number, 10)].concat(buffer);
    }, []);

  const sum: number = reverse.reduce((buffer: any, number) => {
    let newBuffer = buffer;
    newBuffer += number * index;
    index = index === 9 ? 2 : index + 1;
    return newBuffer;
  }, 0);

  const mod: number = sum % 11;
  return mod < 2 ? 0 : 11 - mod;
};

const stripCNPJ = (number: string, strict?: boolean): string => {
  const STRICT_STRIP_REGEX: RegExp = /[-\\/.]/g;
  const LOOSE_STRIP_REGEX: RegExp = /[^\d]/g;
  const regex: RegExp = strict ? STRICT_STRIP_REGEX : LOOSE_STRIP_REGEX;
  return (number || '').replace(regex, '');
};

export const isValidCnpj = (number: string, strict?: boolean): boolean => {
  // const new number.replace(/[-_./]/g, '');

  const BLACKLIST: Array<string> = [
    '00000000000000',
    '11111111111111',
    '22222222222222',
    '33333333333333',
    '44444444444444',
    '55555555555555',
    '66666666666666',
    '77777777777777',
    '88888888888888',
    '99999999999999',
  ];
  const stripped: string = stripCNPJ(number, strict);

  // CNPJ must be defined
  if (!stripped) {
    return false;
  }

  // CNPJ must have 14 chars
  if (stripped.length !== 14) {
    return false;
  }

  // CNPJ can't be blacklisted
  if (BLACKLIST.includes(stripped)) {
    return false;
  }

  let numbers: string = stripped.substr(0, 12);
  numbers += verifierDigitCNPJ(numbers);
  numbers += verifierDigitCNPJ(numbers);

  return numbers.substr(-2) === stripped.substr(-2);
};

const verifierDigitCPF = (digits: string): number => {
  const numbers: Array<number> = digits.split('').map(number => {
    return parseInt(number, 10);
  });

  const modulus: number = numbers.length + 1;
  const multiplied: Array<number> = numbers.map(
    (number, index) => number * (modulus - index),
  );
  const mod: number =
    multiplied.reduce((buffer, number) => buffer + number) % 11;

  return mod < 2 ? 0 : 11 - mod;
};

const stripCPF = (numberStrip: string, strictStrip?: boolean): string => {
  const STRICT_STRIP_REGEX: RegExp = /[.-]/g;
  const LOOSE_STRIP_REGEX: RegExp = /[^\d]/g;
  const regex: RegExp = strictStrip ? STRICT_STRIP_REGEX : LOOSE_STRIP_REGEX;
  return (numberStrip || '').replace(regex, '');
};

export const isValidCpf = (number: string, strict?: boolean): boolean => {
  number.replaceAll('_', '');
  const BLACKLIST: Array<string> = [
    '00000000000',
    '11111111111',
    '22222222222',
    '33333333333',
    '44444444444',
    '55555555555',
    '66666666666',
    '77777777777',
    '88888888888',
    '99999999999',
    '12345678909',
  ];
  const stripped: string = stripCPF(number, strict);

  // CPF must be defined
  if (!stripped) {
    return false;
  }

  // CPF must have 11 chars
  if (stripped.length !== 11) {
    return false;
  }

  // CPF can't be blacklisted
  if (BLACKLIST.includes(stripped)) {
    return false;
  }

  let numbers: string = stripped.substr(0, 9);
  numbers += verifierDigitCPF(numbers);
  numbers += verifierDigitCPF(numbers);

  return numbers.substr(-2) === stripped.substr(-2);
};

export const isValidPhone = (text: string) => {
  if (text) {
    const regex = /^\([0-9]{2}\) [0-9]{5}-[0-9]{4}$/;
    return regex.test(text);
  }
  return null;
};

export const isValidEmail = (text: string) => {
  if (text) {
    const regex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    return regex.test(text);
  }
  return null;
};

export const isValidMoney = (text: string) => {
  if (text) {
    const regex = /^\$?[\d,]+(\.\d*)?$/;
    return regex.test(text);
  }
  return null;
};

export const masks = {
  zipCode: (value: string) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{3})\d+?$/, '$1');
  },
  addressNumber: (value: string) => {
    return value.replace(/\D/g, '')
      .replace(/(\d{5})\d?$/, '$1');
  },

  cardNumber: (value: string) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{4})(\d)/, '$1 $2')
      .replace(/(\d{4})(\d)/, '$1 $2')
      .replace(/(\d{4})(\d)/, '$1 $2')
      .replace(/(\d{4})\d+?$/, '$1');
  },
  cvv: (value: string) => {
    return value.replace(/\D/g, '').replace(/(\d{4})\d+?$/, '$1');
  },
  phone: (value: string) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1');
  },
  cpf: (value: string) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  },
  cnpj: (value: string) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  },
  expMonth: (value: string) => {
    return value.replace(/\D/g, '').replace(/(\d{2})\d?$/, '$1');
  },
  expYear: (value: string) => {
    return value.replace(/\D/g, '').replace(/(\d{4})\d?$/, '$1');
  },
  number: (value: string) => {
    return value.replace(/\D/g, '');
  },
  otp: (value: string) => {
    return value.replace(/\D/g, '');
  },
  accountId: (value: string) => {
    return value.replace(/^[0-9]/, '')
      .replace(/[^a-zA-Z0-9]/g, '')
      .toLocaleLowerCase()
      .substring(0, 21);
  },
  moneyMask: (input: string) => {
    const value = input.replace('.', '').replace(',', '').replace(/\D/g, '');

    const options = { minimumFractionDigits: 2 };
    const result = new Intl.NumberFormat('pt-BR', options).format(
      parseFloat(value) / 100,
    );

    return `R$ ${result}`;
  }
};

const validateCard = (card: any) => {

  const BLACKLIST = [
      '0000000000000000',
      '1111111111111111',
      '2222222222222222',
      '3333333333333333',
      '4444444444444444',
      '5555555555555555',
      '6666666666666666',
      '7777777777777777',
      '8888888888888888',
      '9999999999999999',
  ];
  
  const CARD_TYPES = [
      {
          name: 'VISA',
          detector: /^4/,
          cardLength: 16,
          cvcLength: 3,
          order: 99,
      },
      {
          name: 'MASTERCARD',
          detector: /^(5[1-5]|2(2(2[1-9]|[3-9])|[3-6]|7([0-1]|20)))/,
          cardLength: 16,
          cvcLength: 3,
          order: 99,
      },
      {
          name: 'AMEX',
          detector: /^3[47]/,
          cardLength: 15,
          cvcLength: 4,
          order: 99,
      },
      {
          name: 'ELO',
          detector: /^(4011(78|79)|43(1274|8935)|45(1416|7393|763(1|2))|50(4175|6699|67([0-6][0-9]|7[0-8])|9\d{3})|627780|63(6297|6368)|650(03([^4])|04([0-9])|05(0|1)|4(0[5-9]|(1|2|3)[0-9]|8[5-9]|9[0-9])|5((3|9)[0-8]|4[1-9]|([0-2]|[5-8])\d)|7(0\d|1[0-8]|2[0-7])|9(0[1-9]|[1-6][0-9]|7[0-8]))|6516(5[2-9]|[6-7]\d)|6550(2[1-9]|5[0-8]|(0|1|3|4)\d))\d*/,
          cardLength: 16,
          cvcLength: 3,
          order: 1,
      },
  ];

  const getCardBrand = (value: any) => {
      let card = {
          name: '',
          detector: / /g,
          cardLength: 0,
          cvcLength: 0,
          order: 0,
      };
      CARD_TYPES.forEach(item => {
          if (item.detector.test(value)) {
              card = item;
          }
      });
      return card.name;
  };

  const isCardValid = (card: any): boolean => !BLACKLIST.includes(card.replace(/\s/g, '')) && !!getCardBrand(card);

  return isCardValid(card);
};

const validateExpireDate = (expirationMonth: any, expirationYear: any) => {
  const today = new Date();
  const year = today.getFullYear();
  const convertedexpirationMonth = Number(expirationMonth);
  const convertedexpirationYear = Number(expirationYear);
  const maxYear = year + 8;

  const isValidYear =
    convertedexpirationYear >= year && convertedexpirationYear <= maxYear;

  const isValidMonth =
    convertedexpirationMonth > 0 && convertedexpirationMonth <= 12;

  if (isValidYear && isValidMonth) {
    return true;
  }
  return false;
};


export default {
  convertToMegaBytes,
  optmizeImage,
  optmizeImageSquare,
  getVideoShot,
  createGalleryVideo,
  destroyGalleryVideo,
  timeStampToFormatedDateTime,
  timeStampToFormatedDate,
  toLocalMoney,
  isValidCnpj,
  isValidCpf,
  isValidPhone,
  isValidEmail,
  isValidMoney,
  masks,
  moderateContent,
  validateCard,
  validateExpireDate
}