import React, { useContext } from 'react';
import Post from '../../components/Post';
import { getLastPosts } from '../../services/collectionsService';
import { useQuery } from '@tanstack/react-query';
import { Box, List, Typography, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function LastPosts() {

    const { t } = useTranslation();
    const { data: lastPosts } = useQuery({
        queryKey: ['lastPosts'],
        queryFn: getLastPosts,
    })

    const postStyles = {
        backgroundColor: 'white',
        boxShadow: '0 3px 6px rgba(0, 0, 0, .16)',
        borderRadius: '6px',
        overflow: 'hidden',
        pb: 0,
    }

    return (<Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fafafa',
        py: 4,
        px: 2,
        width: '100%',
        flex: 1,
        borderTop: '1px solid #e0e0e0',
        position: 'relative',
    }}>
        { !!lastPosts?.length ? <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: {
                xs: '100%',
                sm: '100%',
                md: '80%',
                lg: '80%',
            },
            maxWidth: '1080px',
        }}>
            <Typography component="h1" variant="h4" sx={{
                textWeight: '300',
                textAlign: 'left',
                width: '100%',
            }}>{t('auth.lastHighlights')}</Typography>
            <List sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: 2,
                mt: 2,
            }}>
                {lastPosts?.map((post: any) => (
                    <Post key={post.createdAt} post={post} sx={postStyles} hideMenu hideCommands />
                ))}
            </List>
        </Box> : <CircularProgress color="primary" />}
    </Box>
    )
}