import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { signup, SignupRequest } from '../../../services/userService';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useContext } from 'react';
import { UserContext } from '../../../App';
import { Link } from '@mui/material';
import { useTranslation } from 'react-i18next';

type SignUpProps = {
    onCancel: () => void;
}

export default function SignUp({ onCancel }: SignUpProps) {

    const { currentUser, setCurrentUser } = useContext(UserContext);
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch
    } = useForm<SignupRequest>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });

    const { mutate: signupHandler } = useMutation({
        mutationFn: signup,
        onSuccess: (data) => {
            setCurrentUser(data);
        }
    })

    return (
        <Box>
            <Typography component="h1" variant="h5">
                {t('auth.login.signUp.title')}
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit(values => signupHandler(values))} sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            autoComplete="off"
                            required
                            fullWidth
                            id="fullName"
                            label={t('auth.login.signUp.fullName')}
                            autoFocus
                            error={!!errors.fullName}
                            helperText={errors.fullName?.message}
                            placeholder={t('auth.login.signUp.fullNamePlaceholder')}
                            {...register("fullName", {
                                required: true,
                            })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            id="email"
                            label={t('auth.login.email')}
                            autoComplete="off"
                            error={!!errors.email}
                            helperText={errors.email?.message}
                            placeholder={t('auth.login.emailPlaceholder')}
                            {...register('email', {
                                required: true,
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: t('auth.login.emailInvalid'),
                                },
                            })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            label={t('auth.login.password')}
                            type="password"
                            id="password"
                            autoComplete="new-password"
                            error={!!errors.password}
                            helperText={errors.password?.message}
                            {...register('password', {
                                required: true,
                                minLength: {
                                    value: 8,
                                    message: t('auth.login.signUp.passwordLength'),
                                },
                                pattern: {
                                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\S]{8,}$/,
                                    message: t('auth.login.signUp.passwordComplexity'),
                                },
                            })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component="span" sx={{ fontSize: "0.7rem" }}>{t('auth.login.youAgree')}
                            <Link href="/terms" sx={{ fontSize: "0.7rem" }}>{t('auth.login.terms')}</Link>{t('common.and')}
                            <Link href="/privacy" sx={{ fontSize: "0.7rem" }}>{t('auth.login.policyAndPrivacy')}</Link>{t('common.and')}
                            {t('auth.login.confirmYouAreOlder')}</Typography>
                    </Grid>
                </Grid>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    {t('auth.login.signUp.signUpButton')}
                </Button>
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 1,
                }}>
                    <Typography variant='body1'>{t('auth.login.signUp.alreadyHaveAccount')}</Typography>
                    <Link href="/login" onClick={(e) => { e.preventDefault(); onCancel() }}>
                        {t('auth.login.loginButton')}
                    </Link>
                </Box>
            </Box>
        </Box>
    );
}
