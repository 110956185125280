import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React from "react";

export type ConfirmDialogProps = {
    title: string;
    text: string;
    confirm: () => void;
    cancel: () => void;
}

const ConfirmDialog = ({ config } : { config?: ConfirmDialogProps }) => {

return (
    <Dialog
        open={!!config}
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {config?.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {config?.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={config?.cancel}>
            Cancel
          </Button>
          <Button onClick={config?.confirm}>OK</Button>
        </DialogActions>
    </Dialog>
)}

export default ConfirmDialog;