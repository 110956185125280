import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, IconButton, Tooltip, useMediaQuery } from '@mui/material';
import Post from '../../components/Post';
import { Breadcrumb } from '../../components/Breadcrumb';
import Header from '../../components/Header';
import PrivateTemplate from '../../components/PrivateTemplate';
import Suggestions from '../../components/Suggestions';
import SyncIcon from '@mui/icons-material/Sync';
import { getPost } from '../../services/collectionsService';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import ShareIcon from '@mui/icons-material/Share';

const ArtistPost: React.FC = () => {
  const { accountId, id } = useParams();
  const navigate = useNavigate();

  const { data: posts, isError } = useQuery({
    queryKey: ['post', accountId, id],
    queryFn: () => getPost(accountId, id),
    retry: false
  });

  useEffect(() => {
    if (isError) {
      navigate('/404');
    }
  }, [isError]);

  const upMd = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  return (
    <PrivateTemplate>
      <Box sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          md: 'row'
        },
        flex: 1
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1 0 640px',
          maxWidth: '640px',
          position: 'relative',
          borderRight: 'var(--border)',
        }} >
          <SyncIcon sx={{
            position: 'absolute',
            top: 'calc(100vh / 2)',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'primary.main',
            fontSize: '6rem',
            animation: 'spin 1s linear infinite',
            display: !posts ? 'block' : 'none'
          }} />
          <Box sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            flex: '1 0 640px',
            maxWidth: '640px',
            visibility: !posts ? 'hidden' : 'visible'
          }}>
            <Header>
              <Breadcrumb title={"POST"} url='/'>
                <Tooltip title="Copiar link do post">
                  <IconButton sx={{ border: 'solid 1px', display: 'flex' }}
                    onClick={() => { navigator.clipboard.writeText(window.location.href); toast.success('Link copiado!') }}
                    color='primary'><ShareIcon />
                  </IconButton>
                </Tooltip>
              </Breadcrumb>
            </Header>
            <Box className='border-bottom-bold' >
              <Box sx={{ borderBottom: 'var(--border)' }}>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'start',
                  justifyContent: 'center',
                  flexDirection: 'column'
                }}>
                  {!!posts?.length && <Post post={posts[0]} queryKey={['post', accountId, id]} />}
                </Box>
              </Box>
              <Box sx={{
                height: '100px' // botton navigator spacing           
              }} ></Box>
            </Box>
          </Box>
        </Box>
        {upMd && <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1 0 320px',
        }}>
          <Box sx={{
            top: 0,
            position: 'sticky',
          }}>
            <Suggestions size={5} />
          </Box>
        </Box>}
      </Box>
    </PrivateTemplate>
  );
};

export default ArtistPost;