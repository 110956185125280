import React, { useContext } from 'react';
import { Route, Routes as ReactRoutes, Navigate} from 'react-router-dom';
import Artist from '../pages/Artist';
import Home from '../pages/Home';
import Login from '../pages/Auth/Login/index';
import NotFound from '../pages/404';
import Profile from '../pages/Profile';
import EditProfile from '../pages/Profile/edit';
import Earnings from '../pages/Earnings';
import Conversations from '../pages/Chats';
import Create from '../pages/Create';
import Collections from '../pages/Collections';
import ResetPassword from '../pages/Auth/ResetPassword';
import ResetChangePassword from '../pages/Auth/ResetChangePassword';
import Subscriptions from '../pages/Subscriptions';
import { UserContext } from '../App';
import Statement from '../pages/Earnings/statement';
import Verify from '../pages/Verify';
import ArtistPost from '../pages/Artist/post';
import OAuthCallback from '../pages/Auth/OAuthCallback';
import ContentModeration from '../pages/Admin/ContentModeration';
import ReportingCenter from '../pages/Admin/ReportingCenter';
import UserVerification from '../pages/Admin/UserVerification';
import Dashboard from '../pages/Admin/Dashboard';

type Props = {
  pass: boolean;
  failout?: string;
  children: React.ReactElement;
};

const Private = ({ 
  pass, 
  failout,
  children 
}: Props) => {
  if (!pass) {
    return <Navigate to={failout || '/login'} />;
  }
  return children;
};

const Public = ({ pass, children }: Props) => {
  if (!pass) {
    return <Navigate to="/" />;
  }
  return children;
};

const Routes = () =>  {

  const { currentUser, setCurrentUser } = useContext(UserContext);
  const isAuthenticated = !!currentUser?.token;
  const isVerified = currentUser?.verified === 'approved';

  return (
    <ReactRoutes>
      <Route path="/" element={<Private pass={isAuthenticated}><Home/></Private>} />
      <Route path="/artist/:id" element={<Private pass={isAuthenticated}><Artist/></Private>} />
      <Route path="/artist/:accountId/posts/:id" element={<Private pass={isAuthenticated}><ArtistPost/></Private>} />
      <Route path="/create" element={<Private pass={isAuthenticated}><Create/></Private>}/>
      <Route path="/verify" element={<Private pass={isAuthenticated}>
        <Private pass={!isVerified} failout='/create'><Verify/></Private>
      </Private>}/>
      <Route path="/profile" element={<Private pass={isAuthenticated}><Profile/></Private>}/>
      <Route path="/profile/edit" element={<Private pass={isAuthenticated}><EditProfile/></Private>}/>
      <Route path="/chats" element={<Private pass={isAuthenticated}><Conversations/></Private>} />
      <Route path="/chats/:id" element={<Private pass={isAuthenticated}><Conversations/></Private>} />
      <Route path="/collections" element={<Private pass={isAuthenticated}><Collections/></Private>} />
      <Route path="/collections/:collection" element={<Private pass={isAuthenticated}><Collections/></Private>} />
      <Route path="/collections/:collection/:filter" element={<Private pass={isAuthenticated}><Collections/></Private>} />
      <Route path="/collections/:collection/:list/:listType/:filter" element={<Private pass={isAuthenticated}><Collections/></Private>} />
      <Route path="/settings/earnings" element={<Private pass={isAuthenticated}><Earnings/></Private>}/>
      <Route path="/settings/earnings/statement" element={<Private pass={isAuthenticated}><Statement/></Private>}/>
      <Route path="/settings/subscriptions" element={<Private pass={isAuthenticated}><Subscriptions/></Private>} />
      <Route path="/login" element={<Public pass={!isAuthenticated}><Login/></Public>} />
      <Route path="/reset-password" element={<Public pass={!isAuthenticated}><ResetPassword/></Public>} />
      <Route path="/reset-password/:code" element={<Public pass={!isAuthenticated}><ResetChangePassword/></Public>} />
      <Route path="/auth/twitter/callback" element={<Public pass={!isAuthenticated}><OAuthCallback provider={"twitter"} /></Public>} />
      <Route path="/auth/google/callback" element={<Public pass={!isAuthenticated}><OAuthCallback provider={"google"} /></Public>} />

      <Route path="/main" element={<Private pass={isAuthenticated}><Dashboard /></Private>} />
      <Route path="/main/moderation" element={<Private pass={isAuthenticated}><ContentModeration /></Private>} />
      <Route path="/main/reporting" element={<Private pass={isAuthenticated}><ReportingCenter /></Private>} />
      <Route path="/main/verification" element={<Private pass={isAuthenticated}><UserVerification /></Private>} />

      <Route path="*" element={<NotFound/>} />
    </ReactRoutes>
  )
}

export default Routes;
