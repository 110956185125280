import React from 'react';
import 'video.js/dist/video-js.css';
import videojs from 'video.js';
require('@silvermine/videojs-quality-selector')(videojs);

type VideoJSProps = {
  options: any;
  onReady?: any;
  style?: React.CSSProperties | undefined;
  className: string | undefined;
}

export const VideoJS = (props: VideoJSProps) => {
  const videoWrapperRef = React.useRef<HTMLDivElement>(null);
  const playerRef = React.useRef<any>(null);
  const {options, onReady} = props;

  React.useEffect(() => {

    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode. 
      const videoElement = document.createElement("video-js");
      videoWrapperRef.current?.appendChild(videoElement);
      const player = playerRef.current = videojs(videoElement, options);
      player.autoplay(options.autoplay);
      player.src(options.sources);
      if(options.poster){
        const bg = document.createElement("div")
        bg.className = "video-media-bg"
        bg.style.backgroundImage = `url(${options.poster})`
        videoElement.prepend(bg)
      }
    }

  }, [options, videoWrapperRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;
    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-videojs-wrapper ref={videoWrapperRef} style={{width: '100%', ...props.style}} className={props.className}></div>
  );
}

export default VideoJS;