import React from 'react';
import { useState } from 'react'
import { Avatar, Box, Button, IconButton, Link, TableCell, TableRow, Typography } from '@mui/material'
import AdminTemplate from '../components/AdminTemplate'
import EnhancedTable, { DataRow, HeadCell } from '../../../components/EnhancedTable';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import helpers from '../../../utils/helpers';
import { BASE_IMAGE_URL } from '../../../utils/constants';
import { ReportingModel } from '../types/ReportingModel';
import StandardModal from '../../../components/StandardModal';
import { toast } from 'react-toastify';
import { api } from '../../../services/api';

interface Data extends ReportingModel, DataRow { }

export default function ReportingCenter() {

    const [detailsOpen, setDetailsOpen] = useState(false);
    const [selected, setSelected] = useState<ReportingModel | undefined>();

    const { data: rows } = useQuery({
        queryKey: ['reportings'],
        queryFn: async () => {
            const { data } = await api.get<ReportingModel[]>(`/main/reportings`);
            return data as Data[];
        },
    });

    const headCells: readonly HeadCell<Data>[] = [
        {
            id: 'reporterId',
            numeric: false,
            disablePadding: false,
            label: 'Reporter',
        },
        {
            id: 'reporter',
            numeric: false,
            disablePadding: false,
            label: 'Reporter',
        },
        {
            id: 'reportedId',
            numeric: false,
            disablePadding: false,
            label: 'Reported',
        },
        {
            id: 'reported',
            numeric: false,
            disablePadding: false,
            label: 'Reported',
        },
        {
            id: 'reason',
            numeric: false,
            disablePadding: false,
            label: 'Reason',
        },
        {
            id: 'reportedPostId',
            numeric: false,
            disablePadding: false,
            label: 'Content',
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: 'Status',
        },
        {
            id: 'createdAt',
            numeric: false,
            disablePadding: false,
            label: 'Reported at',
        },
        {
            id: 'id',
            numeric: true,
            disablePadding: false,
            label: '',
        },
    ];

    function getTableRow(row: Data,
        isItemSelected: boolean,
        labelId: string,
        handleClick: (event: React.MouseEvent<unknown>, id: string) => void) {
        return (<TableRow
            hover
            onClick={(event) => handleClick(event, row.id)}
            // aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            // selected={isItemSelected}
            sx={{ cursor: 'pointer' }}
        >
            <TableCell>
                <Avatar
                    alt={row.reporter?.accountId}
                    src={`${BASE_IMAGE_URL}/${row.reporter?.avatar?.small?.path}`}
                    sx={{ width: 40, height: 40 }}
                />
            </TableCell>
            <TableCell>{row.reporter?.displayName}</TableCell>
            <TableCell>
                <Avatar src={`${BASE_IMAGE_URL}/${row.reported?.avatar?.small?.path}`} sx={{ width: 40, height: 40 }} />
            </TableCell>
            <TableCell>{row.reported?.displayName}</TableCell>
            <TableCell sx={{ width: '60px' }}>{row.reason}</TableCell>
            <TableCell sx={{ width: '200px' }}>{row.reportedPost?.content}</TableCell>
            <TableCell>{row.status}</TableCell>
            <TableCell>{helpers.timeStampToFormatedDate(row.createdAt)}</TableCell>
            <TableCell align="right" >
                <IconButton
                    aria-label="delete"
                    onClick={() => {
                        setSelected(row);
                        setDetailsOpen(true);
                    }}
                >
                    <BorderColorRoundedIcon />
                </IconButton>
            </TableCell>
        </TableRow>
        )
    }

    const veredict = (params: { id?: string, approved: boolean }) => {
        return api.put(`/main/reportings/${params.id}`, { status: params.approved ? 'approved' : 'rejected' })
    }

    const queryClient = useQueryClient();

    const veredictMutation = useMutation({
        mutationFn: veredict,
        onMutate: async (variables: { id?: string; approved: boolean; }) => {
            const { id, approved } = variables;
            await queryClient.cancelQueries({ queryKey: ['reportings'] });
            const previousData = queryClient.getQueryData<Data[]>(['reportings']);
            queryClient.setQueryData<Data[]>(['reportings'], (old) => {
                return old?.filter(row => row.id != id);
            });

            return { previousData }
        },
        onError: (error, variables, context) => {
            queryClient.setQueryData<Data[]>(['reportings'], context?.previousData);
        },
        onSuccess: (data, variables, context) => {
            toast.success('Veredict saved');
        },
    });

    return (
        <AdminTemplate>
            <StandardModal openState={[detailsOpen, setDetailsOpen]} onClose={() => setDetailsOpen(false)}
                sx={{
                    maxWidth: 1200,
                    p: 2,
                }} >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        flex: '1 0 50%',
                    }}>
                        <Avatar src={`${BASE_IMAGE_URL}/${selected?.reported?.avatar?.small?.path}`} sx={{ width: 80, height: 80 }} />
                        <Typography variant="h5">{selected?.reported?.displayName} · {selected?.reported?.accountId}</Typography>
                        <Typography variant="body1">{selected?.reporter?.id}</Typography>
                        <Typography variant="body1">{selected?.reported?.email}</Typography>
                        <Typography variant="body1">Created at {helpers.timeStampToFormatedDate(selected?.reported?.createdAt)} ·
                            Verified {selected?.reported?.verified}</Typography>
                        <Typography variant="body2">{selected?.reported?.description}</Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        flex: '1 0 50%',
                    }}>
                        <Typography variant="h5">Deleted {selected?.reportedPost?.deleted ? 'S' : 'N'} ·
                            Private {selected?.reportedPost?.isPrivate ? 'S' : 'N'} ·
                            Status {selected?.reportedPost?.status} ·
                            Created at {helpers.timeStampToFormatedDate(selected?.reportedPost?.createdAt)}</Typography>
                        <Typography variant="h6">{selected?.reportedPost?.id}</Typography>
                        <Typography variant="body1">{selected?.reportedPost?.content}</Typography>
                        <img src={selected?.reportedPost?.assets?.[0]?.type === 'image' ?
                            selected?.reportedPost?.assets?.[0]?.original?.path :
                            selected?.reportedPost?.assets?.[0]?.poster?.original?.path}
                            alt={selected?.reportedPost?.content} style={{ maxWidth: 320, maxHeight: 480 }} />
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            gap: 2,
                        }}>
                            <Button variant="outlined" color="primary" onClick={
                                () => {
                                    veredictMutation.mutate({ id: selected?.id, approved: false });
                                    setDetailsOpen(false);
                                }}>Reject</Button>
                            <Button variant="outlined" color="primary" onClick={
                                () => {
                                    veredictMutation.mutate({ id: selected?.id, approved: true });
                                    setDetailsOpen(false);
                                }}>Approve</Button>
                        </Box>
                    </Box>
                </Box>
            </StandardModal>
            <Box sx={{
                p: 2,
            }}>
                <Typography variant="h1">Reporting Center</Typography>
                <Box sx={{ mt: 2 }}>
                    {rows && <EnhancedTable
                        headCells={headCells}
                        getTableRow={getTableRow}
                        rows={rows}
                        title=""
                        dense
                    />}
                </Box>
            </Box>
        </AdminTemplate>
    )
}