import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import ArtistProfileRef from '../ArtistProfileRef';
import { UserContext } from '../../App';
import { useQuery } from '@tanstack/react-query';
import { getSuggestions } from '../../services/userService';
import { ProfileModel } from '../../types/ProfileModel';
import { useState, useEffect, useContext } from 'react';
import { IconButton } from '@mui/material';
import * as constants from '../../utils/constants'

type SuggestionsProps = {
  size: number;
}

export default function Suggestions({ size }: SuggestionsProps) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const [chunks, setChunks] = useState<Array<Array<ProfileModel>>>([]);

  const { data: suggestions } = useQuery({
    queryKey: ['suggestions'],
    queryFn: () => getSuggestions(),
    refetchOnWindowFocus: false
  })

  useEffect(() => {

    if (suggestions) {
      const chunkSize = size;
      const chunked = suggestions.reduce((resultArray: any[][], item: any, index: number) => {
        const chunkIndex = Math.floor(index / chunkSize)

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []
        }

        resultArray[chunkIndex].push(item)

        return resultArray
      }, [])

      setChunks(chunked)
    }

  }, [suggestions])

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{p: 2}}>
      {/* <Typography variant="h5" color="secondary.light">SUGESTÕES</Typography> */}
      <MobileStepper
        steps={chunks.length}
        position="static"
        activeStep={activeStep}
        nextButton={
          <IconButton
            size="small"
            onClick={handleNext}
            disabled={activeStep === chunks.length - 1}
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </IconButton>
        }
        backButton={
          <IconButton size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </IconButton>
        }
      />
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
      >
        {chunks.map((step, index) => (
          <Box key={index} sx={{
            display: 'flex', 
            flexDirection: 'column',
            flex: '1',
            gap: 1
            }}>
            {step.map((artist, index) => (
                <ArtistProfileRef key={index} artist={artist} sx={{
                  height: '96px',
                }}/>
              ))}
          </Box>
        ))}
      </SwipeableViews>      
    </Box>
  );
}
