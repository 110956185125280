import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { UserContext } from '../../App';
import { Button, Box, List, Tabs, Tab, Chip, IconButton, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { Breadcrumb } from '../../components/Breadcrumb';
import { getUserStats } from '../../services/userService';
import { getUserPosts, deletePost, changePrivacy } from '../../services/collectionsService';
import { useMutation, useQuery, useQueryClient, useInfiniteQuery } from '@tanstack/react-query';
import ArtistProfileTop from '../../components/ArtistProfileTop';
import { toast } from 'react-toastify';
import Post from '../../components/Post';
import ConfirmDialog, { ConfirmDialogProps } from '../../components/ConfirmDialog';
import Header from '../../components/Header';
import TabPanel from '../../components/TabPanel';
import CustomGallery from '../../components/CustomGallery';
import PrivateTemplate from '../../components/PrivateTemplate';
import { PostModel } from '../../types/PostModel';
import EmptyFeed from '../../components/EmptyFeed';
import ShareIcon from '@mui/icons-material/Share';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import VerifiedBadge from '../../components/VerifiedBadge';
import ArtistStats from '../../components/ArtistStats';
import { Link as RouterLink } from 'react-router-dom';
import ArtistExternalLinks from '../../components/ArtistExternalLinks';
import Suggestions from '../../components/Suggestions';
import { PaginatedList } from '../../types/PaginatedList';
import InfiniteScrollObserver from '../../components/InfiniteScrollObserver';
import { useTranslation } from 'react-i18next';

const Profile: React.FC = () => {

    const { t } = useTranslation();
    const { currentUser, setCurrentUser } = useContext(UserContext);
    const queryClient = useQueryClient()
    const location = useLocation()

    const userStats = useQuery({
        queryKey: ['userStats'],
        queryFn: () => getUserStats(),
    });

    useEffect(() => {
        return () => {
            queryClient.setQueryData(['userPosts'], (data: any) => ({
                pages: data?.pages?.slice(0, 1) ?? [],
                pageParams: data?.pageParams.slice(0, 1) ?? [],
            }))
        }
    }, [])

    const posts = useInfiniteQuery<PaginatedList<PostModel>, Error>({
        queryKey: ['userPosts'],
        queryFn: (query) => getUserPosts(query.pageParam),
        getNextPageParam: (lastPage) => lastPage?.lastEvalKey,
        defaultPageParam: 0,
    })

    const mutateDeletePost = useMutation({
        mutationFn: deletePost,
        onMutate: async (createdAt?: number) => {
            await queryClient.cancelQueries({ queryKey: ['userPosts'] })
            const previousPosts = queryClient.getQueryData<any>(['userPosts'])
            queryClient.setQueryData<any>(['userPosts'], (oldData: any) => {
                return {
                    ...oldData,
                    pages: oldData?.pages?.map((page: any) => {
                        return {
                            ...page,
                            items: page.items.filter((item: any) => item.createdAt !== createdAt)
                        }
                    })
                }
            })
            return { previousPosts }
        },
        onSuccess: () => {
            toast.success('Post removido com sucesso!');
            userStats.refetch();
        },
        onError: (err, variables, context) => {
            console.log(err)
            queryClient.setQueryData<any>(['userPosts'], context?.previousPosts)
        }
    })

    const [confirmDialogConfig, setConfirmDialogConfig] = React.useState<ConfirmDialogProps>();

    const handleDeletePost = (createdAt?: number) => {
        setConfirmDialogConfig(
            {
                title: 'Excluir Post',
                text: 'Tem certeza que deseja remover esse post?',
                confirm: () => {
                    mutateDeletePost.mutate(createdAt);
                    setConfirmDialogConfig(undefined);
                },
                cancel: () => {
                    setConfirmDialogConfig(undefined);
                }
            }
        )
    }

    useEffect(() => {
        if (location.state?.hash) {
            console.log(location.state?.hash)
        } else {
            window.scrollTo(0, 0);
        }
    }, [location]);

    const [currentTab, setCurrentTab] = useState<number>(0)
    const [mediaFilter, setMediaFilter] = useState<'all' | 'image' | 'video'>('all')
    const getChipStyle = (type: 'all' | 'image' | 'video'): any => {
        if (mediaFilter == type) {
            return {
                color: 'secondary',
                disabled: true
            }
        }
        return {}
    }

    const [topHeader, setTopHeader] = useState<boolean>(true);

    window.addEventListener('scroll', () => {
        if (window.scrollY > 200)
            setTopHeader(false)
        else if (window.scrollY <= 200)
            setTopHeader(true)
    });

    const mutatePostPrivacy = useMutation({
        mutationFn: changePrivacy,
        onMutate: async (createdAt?: number) => {
            await queryClient.cancelQueries({ queryKey: ['userPosts'] })
            const previousPosts = queryClient.getQueryData<any>(['userPosts'])
            queryClient.setQueryData<any>(['userPosts'], (oldData: any) => {
                return {
                    ...oldData,
                    pages: oldData.pages.map((page: any) => {
                        return {
                            ...page,
                            items: page.items.map((item: any) => {
                                if (item.createdAt === createdAt && !item.status) {
                                    return {
                                        ...item,
                                        status: 'pending'
                                    }
                                }
                                return item;
                            })
                        }
                    })
                }
            })
            return { previousPosts }
        },
        onError: (err, variables, context) => {
            console.log(err)
            queryClient.setQueryData<any>(['userPosts'], context?.previousPosts)
        }
    });   

    const upMd = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

    return (
        <PrivateTemplate>
            <Box sx={{
                display: 'flex',
                flexDirection: {
                    xs: 'column',
                    md: 'row'
                },
                flex: '1',
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '1 0 640px',
                    maxWidth: '640px',
                    borderRight: 'var(--border)'
                }}>
                    <Header sx={(() => {
                        return topHeader ? {
                            top: '-56px',
                            marginBottom: '-56px',
                            color: 'white',
                            background: 'transparent',
                            borderBottom: 'solid 1px transparent'
                        } : {
                            top: 0,
                            transition: 'top .2s'
                        }
                    })()}>
                        <Breadcrumb title={
                            <Box>
                                <VerifiedBadge
                                    sx={{ m: 0, lineHeight: '19px' }}
                                    user={currentUser}
                                    hideAccountId />
                                <ArtistStats user={userStats.data} />
                            </Box>
                        } url='/' />
                    </Header>
                    <ArtistProfileTop artist={currentUser} />
                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', mt: -5, pr: 2, gap: 1 }}>
                        <Button component={RouterLink} to="/profile/edit"
                            variant="outlined"
                            sx={{
                                borderRadius: '20px',
                            }}
                            onClick={() => { }}
                            color='primary'
                            startIcon={<BorderColorIcon />}
                        >
                            Editar Perfil
                        </Button>
                        <Tooltip title="Copiar link do perfil">
                            <IconButton sx={{ border: 'solid 1px', display: 'flex' }}
                                onClick={() => { navigator.clipboard.writeText(`${new URL(window.location.href).origin}/artist/${currentUser.accountId}`); toast.success('Link copiado!') }}
                                color='primary'><ShareIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box className='border-bottom-bold'
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Box sx={{ padding: 2 }}>
                            <VerifiedBadge user={currentUser}
                                sx={{ fontWeight: '700', fontSize: '19px' }}
                                accountIdSx={{
                                    fontSize: '16px',
                                    fontWeight: 'normal',
                                    color: 'secondary.light',
                                }}
                            />
                            <Typography variant="body1" sx={{ mt: 1 }}>{currentUser.description}</Typography>
                            <ArtistExternalLinks artist={currentUser} />
                        </Box>
                    </Box>
                    <Tabs
                        value={currentTab}
                        onChange={(_, newTab: number) => {
                            setCurrentTab(newTab);
                        }}
                        indicatorColor="primary"
                        textColor="inherit"
                        variant="fullWidth"
                        sx={{ borderBottom: 'var(--border)' }}>
                        <Tab label={
                            <Typography component="h1" variant="h6">PUBLICAÇÕES</Typography>
                        } id='full-width-tab-0' aria-controls='full-width-tabpanel-0' />
                        <Tab label={
                            <Typography component="h1" variant="h6">MÍDIAS</Typography>
                        } id='full-width-tab-1' aria-controls='full-width-tabpanel-1' />
                    </Tabs>
                    <TabPanel value={currentTab} index={0}>
                        <Box sx={{ display: 'flex', flex: 'row', p: 2, gap: 2 }}>
                            <Chip label={`Todos ${(userStats.data?.imagesCount ?? 0) + (userStats.data?.videosCount ?? 0)}`}
                                onClick={() => setMediaFilter('all')} {...getChipStyle('all')} />
                        </Box>
                        {!!posts?.data?.pages?.some(p => p.items?.length) ? <List sx={{
                            width: '100%',
                        }}>
                            {posts?.data?.pages?.map(page => page.items?.map((post: PostModel) => (
                                <Post key={post.createdAt} post={post}
                                    queryKey={['userPosts']}
                                    onDelete={() => handleDeletePost(post.createdAt)}
                                    onPrivacyChange={(post) => mutatePostPrivacy.mutate(post.createdAt)} />
                            )))}
                        </List> : <EmptyFeed />}
                    </TabPanel>
                    <TabPanel value={currentTab} index={1}>
                        <Box sx={{ display: 'flex', flex: 'row', p: 2, gap: 2 }}>
                            <Chip label={`Todos ${(userStats.data?.imagesCount ?? 0) + (userStats.data?.videosCount ?? 0)}`}
                                onClick={() => setMediaFilter('all')} {...getChipStyle('all')} />
                            <Chip label={`Fotos ${(userStats.data?.imagesCount ?? 0)}`}
                                onClick={() => setMediaFilter('image')} {...getChipStyle('image')} />
                            <Chip label={`Videos ${(userStats.data?.videosCount ?? 0)}`}
                                onClick={() => setMediaFilter('video')} {...getChipStyle('video')} />
                        </Box>
                        {!!posts?.data?.pages?.some(p => p.items?.length) ?
                            <CustomGallery posts={posts?.data?.pages?.flatMap(page => page.items || [])} mediaFilter={mediaFilter} /> :
                            <EmptyFeed />}
                    </TabPanel>
                    {posts && <InfiniteScrollObserver fetchNextPage={posts.fetchNextPage} />}
                    <ConfirmDialog config={confirmDialogConfig} />
                </Box>
                {upMd && <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '1 1 320px',
                }}>
                    <Box sx={{
                        top: 0,
                        position: 'sticky',
                    }}>
                        <Suggestions size={5} />
                    </Box>
                </Box>}
            </Box>
        </PrivateTemplate>
    );
};

export default Profile;
