import React from 'react';
import axios from 'axios';
import constants from '../../utils/constants';
import { Button } from '@mui/material';

type OAuthButtonProps = {
  children: React.ReactNode;
  provider: 'twitter' | 'google';
  sx?: any;
  startIcon?: React.ReactNode;
};

const OAuthButton = ({ children, provider, sx, startIcon }: OAuthButtonProps) => {

  const handleLogin = () => {
      axios.get(`${constants.API_URL}/user/auth/${provider}`).then((response) => {
        window.location.href = response.data.authorization_url;
      }).catch((error) => {
        console.error('Error during authentication', error);
      })    
  };

  return (
    <Button
      startIcon={startIcon}
      sx={{
        width: '100%',
        ...sx
      }}
      onClick={handleLogin}>{children}
    </Button>
  );
};

export default OAuthButton;
