import { Box, Button, Chip } from '@mui/material';
import { useMutation, useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { listUsers, block } from '../../services/collectionsService';
import EmptyFeed from "../../components/EmptyFeed";
import React, { useState, useEffect } from 'react';
import { ProfileModel } from '../../types/ProfileModel';
import ArtistProfileCard from '../../components/ArtistProfileCard';
import SubscriptionInfoModal from "../../components/SubscriptionInfoModal";
import * as helpers from '../../utils/helpers';
import { PaginatedList } from '../../types/PaginatedList';
import InfiniteScrollObserver from '../../components/InfiniteScrollObserver';

type ListUsersProps = {
    list: string
}

export default function ListUsers({ list }: ListUsersProps) {

    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const getChipStyle = (type: string): any => {
        return {
            color: 'secondary',
            disabled: true
        }
    }

    useEffect(() => {
        return () => {
            queryClient.setQueryData(['listUsers', list], (data: any) => ({
                pages: data?.pages?.slice(0, 1) ?? [],
                pageParams: data?.pageParams.slice(0, 1) ?? [],
            }))
        }
    }, [])

    const users = useInfiniteQuery<PaginatedList<ProfileModel>, Error>({
        queryKey: ['listUsers', list],
        queryFn: (query) => listUsers(list, query.pageParam),
        getNextPageParam: (lastPage) => lastPage?.lastEvalKey,
        refetchOnWindowFocus: false,
        defaultPageParam: 0,
    })

    const unblockMutation = useMutation({
        mutationFn: block,
        onError: (err, variables, context) => {
            queryClient.invalidateQueries({ queryKey: ['userLists'] })
            queryClient.invalidateQueries({ queryKey: ['listUsers', list] })

        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['userLists'] })
            queryClient.invalidateQueries({ queryKey: ['listUsers', list] })
        }
    })

    const [subscriptionInfoStateModalOpen, setSubscriptionInfoModalOpen] = useState<boolean>(false)
    const [currentArtist, setCurrentArtist] = useState<ProfileModel | undefined>()

    const handleSubscriptionInfo = (user: ProfileModel) => {
        setCurrentArtist(user)
        setSubscriptionInfoModalOpen(true)
    }

    const handleConfirmSubscribe = () => {
        queryClient.invalidateQueries({ queryKey: ['listUsers', list] })
    }

    const handleConfirmCancelSubscription = () => {
        queryClient.invalidateQueries({ queryKey: ['listUsers', list] })
    }

    return (
        <Box>
            {currentArtist && <SubscriptionInfoModal
                artist={currentArtist}
                openState={[subscriptionInfoStateModalOpen, setSubscriptionInfoModalOpen]}
                onConfirmCancel={handleConfirmCancelSubscription}
                onConfirmSubscribe={handleConfirmSubscribe}
            />
            }
            <Box sx={{ display: 'flex', flex: 'row', p: 2, gap: 2 }}>
                <Chip label={`Todos ${users?.data?.pages?.reduce((total, p) => total + p.items.length, 0) || 0}`}
                    onClick={() => navigate(`/collections/user-lists/${list}/users/all`)} {...getChipStyle('all')} />
            </Box>
            {!!users?.data?.pages?.some(p => p.items.length) ? <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 2,
                px: 2,
                flexDirection: {
                    xs: 'column',
                    sm: 'row'
                },
            }}>
                {users?.data?.pages?.map(p => p.items.map((user: ProfileModel) => (
                    <ArtistProfileCard
                        sx={{
                            flex: '1 0 calc(50% - 8px)',
                            maxWidth: {
                                xs: '100%',
                                sm: 'calc(50% - 8px)',
                            },
                        }}
                        key={user.id}
                        artist={user}
                        moreMenu={
                            list === "blocked" ? [
                                {
                                    name: "unblock",
                                    label: 'Desbloquear',
                                    onClick: () => unblockMutation.mutate(user.id)
                                }
                            ] : []
                        }>
                        {!user.subscription && <Button size="large"
                            variant="outlined"
                            disabled
                            sx={{
                                borderRadius: '45px',
                                width: '100%',
                            }}>Indisponível</Button>}
                        {user.subscription && <Button size="large"
                            variant="outlined"
                            sx={{
                                borderRadius: '45px',
                                width: '100%',
                            }}
                            onClick={() => handleSubscriptionInfo(user)}>
                            {user.subscription?.type === 'subscription' ?
                                `Assinar por ${helpers.toLocalMoney(user.subscription?.price)}` :
                                'Já sou assinante'
                                }
                        </Button>}
                    </ArtistProfileCard>
                )))}
            </Box> : <EmptyFeed />}
            {users && <InfiniteScrollObserver fetchNextPage={users.fetchNextPage} />}
        </Box>
    );
}