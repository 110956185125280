import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import Post from '../../components/Post';
import { Box, List, TextField, useMediaQuery } from '@mui/material';
import { Breadcrumb } from '../../components/Breadcrumb';
import { getPosts } from '../../services/collectionsService';
import Header from '../../components/Header';
import PrivateTemplate from '../../components/PrivateTemplate';
import Suggestions from '../../components/Suggestions';
import constants from '../../utils/constants';
import { PaginatedList } from '../../types/PaginatedList';
import { PostModel } from '../../types/PostModel';
import InfiniteScrollObserver from '../../components/InfiniteScrollObserver';
import { useTranslation } from 'react-i18next';

const Home: React.FC = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    return () => {
      queryClient.setQueryData(['feed'], (data: any) => ({
        pages: data?.pages?.slice(0, 1) ?? [],
        pageParams: data?.pageParams.slice(0, 1) ?? [],
      }))
    }
  }, [])

  const feed = useInfiniteQuery<PaginatedList<PostModel>, Error>({
    queryKey: ['feed'],
    queryFn: (query) => getPosts(query.pageParam),
    getNextPageParam: (lastPage) => lastPage?.lastEvalKey,
    refetchOnWindowFocus: false,
    defaultPageParam: 0,
    staleTime: Infinity,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const upMd = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  return (
    <PrivateTemplate>
      <Box sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          md: 'row'
        },
        flex: '1',
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1 0 640px',
          maxWidth: '640px',
          borderRight: 'var(--border)'
        }}>
          <Header>
            <Breadcrumb title={t('home.title')} />
          </Header>
          <Box sx={{
            display: 'flex',
            flex: 'column'
          }} className='border-bottom-bold'>
            <TextField id="new-post" multiline placeholder='Criar nova publicação...'
              variant="standard"
              InputProps={{
                disableUnderline: true
              }}
              onFocus={() => navigate('/create')}
              sx={{ p: 2, flex: '1', borderBottom: 'var(--border)' }} />
          </Box>          
          <List sx={{
            width: '100%',
            flex: '1',
          }}>
            {!upMd && <Suggestions size={2}/>}
            {!!feed?.data?.pages?.some(p => p.items?.length) && feed?.data?.pages?.map(page => page.items?.map((post: PostModel) => (
              <Post key={post.createdAt} post={post} queryKey={['feed']} />
            )))}
            <InfiniteScrollObserver fetchNextPage={feed.fetchNextPage} />
          </List>
        </Box>
        {upMd && <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 320px',
        }}>
          <Box sx={{
            top: 0,
            position: 'sticky',
          }}>
            <Suggestions size={5} />
          </Box>
        </Box>}
      </Box>
    </PrivateTemplate>
  );
};

export default Home;
