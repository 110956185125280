import { Box, Chip, List } from '@mui/material';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { listPosts } from '../../services/collectionsService';
import Post from '../../components/Post';
import EmptyFeed from "../../components/EmptyFeed";
import React, { useEffect } from 'react';
import { PostModel } from '../../types/PostModel';
import { PaginatedList } from '../../types/PaginatedList';
import InfiniteScrollObserver from '../../components/InfiniteScrollObserver';

type ListPostsProps = {
    list: string
}

export default function ListPosts({ list }: ListPostsProps) {

    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const getChipStyle = (type: string): any => {
		return {
            color: 'secondary',
            disabled: true
        }
	}

    useEffect(() => {
        return () => {
            queryClient.setQueryData(['listPosts', list], (data: any) => ({
            pages: data?.pages?.slice(0, 1) ?? [],
            pageParams: data?.pageParams.slice(0, 1) ?? [],
            }))
        }
    }, [])    

    const posts = useInfiniteQuery<PaginatedList<PostModel>, Error>({
		queryKey: ['listPosts', list],
		queryFn: (query) => listPosts(list, query.pageParam),
		getNextPageParam: (lastPage) => lastPage?.lastEvalKey,
		refetchOnWindowFocus: false,
		defaultPageParam: 0,
	})
    
    return (
        <Box>
            <Box sx={{ display: 'flex', flex: 'row', p: 2, gap: 2 }}>
                <Chip label={`Todos`}
                    onClick={() => navigate(`/collections/user-lists/${list}/posts/all`)} {...getChipStyle('all')} />
                {/* <Chip label={`Fotos`} onClick={() => navigate(`/collections/user-lists/${list}/posts/photo`)} {...getChipStyle('photo')} />
                    <Chip label={`Videos`} onClick={() => navigate(`/collections/user-lists/${list}/posts/video`)} {...getChipStyle('video')} /> 
                */}
            </Box>
            {!!posts?.data?.pages?.some(p => p.items?.length) ? <List sx={{
                width: '100%',
            }}>
                {posts?.data?.pages?.map(page => page.items?.map((post: PostModel) => (
                    <Post key={post.createdAt} post={post} queryKey={['listPosts', list]} />
                )))}
            </List> : <EmptyFeed />}
            {posts && <InfiniteScrollObserver fetchNextPage={posts.fetchNextPage} />}
        </Box>
    );
}