import { Box, Typography, Link, Button } from '@mui/material';
import React from 'react';
import CloudOffIcon from '@mui/icons-material/CloudOff';

const Error404: React.FC = () => {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      flexGrow: '0 0 100%',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      px: '2em',
      mt: '4em',
      gap: '1em',
    }}>
      <Typography variant="h4">A página que você está procurando não existe.</Typography>
      <CloudOffIcon sx={{ fontSize: '200px', color: 'primary.main', opacity: 0.5 }} />
      <Typography variant="body1">
        O link que você seguiu pode estar corrompido ou a página pode ter sido removida.
      </Typography>
      <Button component={Link} variant="contained" href="/">Voltar para a página inicial</Button>
    </Box>
  );
};

export default Error404;
