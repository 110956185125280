import { Avatar, Badge } from "@mui/material";
import React from "react";
import { ProfileModel } from "../../types/ProfileModel";
import { BASE_IMAGE_URL } from "../../utils/constants";


export type ArtistAvatarProps = {
  sx?: any;
  user?: ProfileModel;
  onClick?: (e: any) => void;
}

export const ArtistAvatar = ({
  sx,
  user,
  onClick
}: ArtistAvatarProps) => {

  const lastSeen = user?.lastSeen ? user?.lastSeen * 1000 : 0;
  const fiveMinutesInMilliseconds = 300000;
  const fifteenMinutesInMilliseconds = 900000;

  return (
    <Badge
      sx={{
        '& .MuiBadge-badge': {
          backgroundColor: lastSeen > Date.now() - fiveMinutesInMilliseconds ? '#44b700' : 'orange',
          border: `${parseInt(sx?.width?.split('px')[0]) > 64 ? 2 : 1}px solid #fff`,
          width: `${parseInt(sx?.width?.split('px')[0]) > 64 ? 14 : 10}px`,
          height: `${parseInt(sx?.width?.split('px')[0]) > 64 ? 14 : 10}px`,
          borderRadius: '50%',
          visibility: lastSeen > Date.now() - fifteenMinutesInMilliseconds ? 'visible' : 'hidden',
        },
      }}
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      variant="dot"
    >
      <Avatar
        alt={user?.displayName}
        src={`${BASE_IMAGE_URL}/${parseInt(sx?.width?.split('px')[0] || '64') > 64 ? user?.avatar?.standard?.path : user?.avatar?.small?.path}`}
        sx={{
          ...sx
        }}
        onClick={onClick}
      />
    </Badge>
  );
}
