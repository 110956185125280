import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../App";
import Settings from '../Settings';
import { Box } from '@mui/material';
import SideMenu from '../SideMenu';
import BottomNav from '../BottomNav';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getUnreads } from "../../services/conversationService";
import { getNotifications, markNotificationAsRead } from "../../services/userService";
import { SignalType } from "../../types/SignalType";
import { syncUserState } from "../../services/userService";

export default function PrivateTemplate({ children }: { children: React.ReactNode }) {

    const [showSettings, setShowSettings] = useState<boolean>(false);
    const showSettingsHandler = (show: boolean) => setShowSettings(show)
    const { currentUser, setCurrentUser } = useContext(UserContext);
    const queryClient = useQueryClient();

    const { data: unreads } = useQuery({
        queryKey: ['unreads'],
        queryFn: () => getUnreads(),
    })

    const { data: notifications } = useQuery({
        queryKey: ['notifications'],
        queryFn: () => getNotifications(),
    })


    useEffect(() => {
        if(!unreads)
            return;
        setCurrentUser({ ...currentUser, unreads: unreads});
    }, [unreads]);

    const markNotificationAsReadMutation = useMutation({
        mutationFn: markNotificationAsRead,
        onMutate: async (id: string) => {
            await queryClient.cancelQueries({ queryKey: ['notifications'] });
            const previousNotifications = queryClient.getQueryData(['notifications'])
            queryClient.setQueryData(['notifications'], (old: any) => {
                return old.filter((n: any) => n.createdAt !== id)
            })
            return { previousNotifications }
        },
        onError: (err, variables, context: any) => {
            if (context?.previousNotifications) {
                queryClient.setQueryData(['notifications'], context.previousNotifications)
            }
        },
    })

    useEffect(() => {
        if (notifications) {
            notifications.forEach((n: any) => {
                if (n.type == SignalType.SYNC_USER) {
                    syncUserState(setCurrentUser);
                }
                markNotificationAsReadMutation.mutate(n.createdAt);
            })
        }
    }, [notifications]);

    return (
        <Box id="main"
            sx={{
                display: 'flex',
                flexDirection: 'row',
                flexGrow: 1,
                maxWidth: '1300px',
                minHeight: '100vh',
                width: '100%',
                justifyContent: 'center'
            }}>
            <Settings showSettings={showSettings} showSettingsHandler={showSettingsHandler} />
            <SideMenu showSettingsHandler={showSettingsHandler} />
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                maxWidth: '1000px',
                minHeight: '100vh',
            }}>
                {children}
                <BottomNav showSettingsHandler={showSettingsHandler} />
            </Box>
        </Box>
    );
};