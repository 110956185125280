import React from 'react';
import { Box, Typography } from '@mui/material'
import AdminTemplate from '../components/AdminTemplate'
import { api } from '../../../services/api';
import { useQuery } from '@tanstack/react-query';

export default function Dashboard() {

  const { data } = useQuery({
    queryKey: ['dashboard'],
    queryFn: async () => {
      const { data } = await api.get(`/main/dashboard`);
      return data;
    },
  });

  return (
    <AdminTemplate>
      <Box sx={{
        p: 2,
      }}>
        <Typography variant="h1">Dashboard</Typography>
        <Typography variant="body1">Users: {data?.users}</Typography>
        <Typography variant="body1">Posts: {data?.posts}</Typography>
        <Typography variant="body1">Reportings: {data?.reportings}</Typography>
        <Typography variant="body1">Subscriptions: {data?.subscriptions}</Typography>
        <Typography variant="body1">Products: {data?.products}</Typography>
      </Box>
    </AdminTemplate>
  )
}