import React, { ReactElement } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Box } from '@mui/material';

interface LoadingProps {
  isLoading: boolean;
}
const Loading = ({ isLoading }: LoadingProps): ReactElement => {

  return (
    <>
    { isLoading && <motion.div>
      <AnimatePresence>
        <Box sx={{
            height: '100%',
            width: '100%',
            position: 'fixed',
            backdropFilter: 'blur(4px)',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 999,
            transition: 'all 0.3s ease',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
        </Box>
      </AnimatePresence>
    </motion.div>
    }
    </>
  );
}

export default Loading;